<div [@dialog] class="dialog">
  <div class="modal-header justify-content-between">
    <span class="modal-title"[innerHTML]="Data.chartName"></span>
    <span class="close-btn" (click)="close()">
      <i class="fa fa-window-close" title="Close"></i>
    </span>
  </div>
  <div class="modal-body information-popup">
    <div class="row">
      <div [ngClass]="Data.embedChart?'col-lg-6':'col-lg-12'">
        <div class="col-lg-12">
          <span class="desc">
            <span class="title">Description:</span>
            <p [innerHTML]="Data.informationData.Explanation"></p>
          </span>
        </div>
      </div>
      <div class="col-lg-6" *ngIf="Data.embedChart">
        <div class="info-chart" style="position: relative"></div>
      </div>
    </div>
  </div>
  <div class="information-popup-tabs tab-content" style="overflow-y: auto;">
    <tabset *ngIf="Data.informationData.Sources.length > 0 || Data.reportCitationSections.length > 0">
      <tab *ngIf="Data.informationData.Sources.length > 0">
        <ng-template tabHeading>
          <i class="fa fa-book" aria-hidden="true"></i> Documented
        </ng-template>
        <div class="col-lg-12">
          <span style="font-weight: bold;">Documented:</span>

          <div *ngFor="let a of Data.informationData.Sources" [innerHTML]="a.DocumentedUIString"></div>

          <div style="margin-top: 1em;">
            <span style="font-weight: bold;">Loaded From:</span>
            <div *ngFor="let a of Data.informationData.Sources" [innerHTML]="a.LoadedFromUIString"></div>
          </div>
        </div>
      </tab>
      <tab *ngIf="Data.reportCitationSections.length > 0">
        <div *ngFor="let citationSection of Data.reportCitationSections; index as i">
          <ng-template tabHeading>
            <i class="fa {{citationSection.CitationSectionIcon}}" aria-hidden="true"></i> {{citationSection.CitationSectionName}}
          </ng-template>
          <p [innerHTML]="citationSection.Description"></p>
        </div>
      </tab>
    </tabset>
  </div>
</div>

<div class="full-height" *ngIf="(data.cardType === 'bar' || data.cardType === 'pie' || data.cardType === 'line' || data.cardType === 'horizontal-bar'||data.cardType === 'sunburst' || data.cardType === 'gauge')">
  <rtms-chart [chartConfig]="data.chartConfig" [seriesData]="data.seriesData" [allowOnClick]="data.allowOnClick && data.hasData"
  [autoHeight]="data.autoHeight" [rowSpan]="data.rowSpan"
    (onChartColumnClick)="onChartClick.emit($event)">
  </rtms-chart>
</div>
<div class="full-height" *ngIf="(data.cardType === 'map')">
  <rtms-map-chart [chartConfig]="data.chartConfig" [seriesData]="data.seriesData" [allowOnClick]="data.allowOnClick && data.hasData"
    (onChartColumnClick)="onChartClick.emit($event)" [autoHeight]="data.autoHeight" [rowSpan]="data.rowSpan">
  </rtms-map-chart>
</div>
<div *ngIf="data.cardType === 'gaugeCollection'" class="full-height">
  <span *ngIf="data.seriesData && data.seriesData.length > 0 ">
    <rtms-gauge-collection [chartName]="data.chartName" [reportId]="data.reportId"
      [drillsIntoReportId]="data.drillsIntoReportId" [seriesData]="data.seriesData"
      (gaugeClick)="onChartClick.emit($event)" [creditText]="data.creditText" [templateId]="data.templateId"
      [facilityId]="data.facilityId" [allowOnClick]="data.allowOnClick" [chartConfig]="data.chartConfig"
      [singleColumn]="data.singleColumn" [isMarketingMode]="data.isMarketingMode">
    </rtms-gauge-collection>
  </span>
</div>
<div *ngIf="data.cardType === 'grid'" style="display:flex" class="full-height">
  <div class="position-absolute" style="right: 5px">{{data.disclaimerText}}</div>
  <rtms-grid style="min-width: 100%;" [options]="data.options" [rowData]="data.seriesData"
    [reportId]="data.reportId" [showSearch]="data.showSearch"
    [allowScrollSelectedRow]="data.allowScrollSelectedRow" [hideFooter]="data.hideFooter"  [noDataMessage]="data.noDataMessage" (rowClick)="gridRowClick.emit($event)"
    (gridCellDrillDown)="gridCellClick.emit($event)">
  </rtms-grid>
</div>
<div *ngIf="data.cardType === 'gridCard'" class="full-height">
  <rtms-grid-card [chartConfig]="data.chartConfig" [seriesData]="data.seriesData" [gridOptions]="data.gridOptions" [drillsIntoReportId]="data.drillsIntoReportId"
    [reportId]="data.reportId"  [drilldownDestination]="data.drilldownDestination">
  </rtms-grid-card>
</div>
<div *ngIf="data.cardType === 'residentDetail'" class="full-height">
  <rtms-resident-detail-card [seriesData]="data.seriesData"></rtms-resident-detail-card>
</div>
<div *ngIf="data.cardType === 'residentSocialServices'" class="full-height">
  <rtms-resident-social-services [seriesData]="data.seriesData"></rtms-resident-social-services>
</div>
<div *ngIf="data.cardType === 'unorderedList'">
    <rtms-unordered-list [chartConfig]="data.chartConfig" [seriesData]="data.seriesData">
    </rtms-unordered-list>
  </div>
<div *ngIf="data.cardType === 'numberWidget'" class="full-height">
  <rtms-number-widget *ngIf="data.tableData && data.tableData.length > 0" [ngClass]="(data.allowOnClick) ? 'table-actions' : ''"
    [data]="data.tableData" [options]="data.numberWidgetConfiguration"
    [drilldownDestination]="data.drilldownDestination" [drilldownReport]="data.drilldownReport"
    [allowOnClick]="data.allowOnClick" [templateId]="data.templateId" [reportId]="data.reportId" [filterType]="data.filterType"
    [drillsIntoReportId]="data.detailDashboardId" (onNumberWidgetClick)="onChartClick.emit($event)">
  </rtms-number-widget>
</div>
<div *ngIf="data.cardType === 'quilt'" class="full-height">
  <rtms-quilt *ngIf="data.tableData && data.tableData.length > 0" [ngClass]="(data.allowOnClick) ? 'table-actions' : ''"
    [data]="data.tableData" [options]="data.numberWidgetConfiguration" [dashboard]="dashboard"
    [drilldownDestination]="data.drilldownDestination" [drilldownReport]="data.drilldownReport"
    [allowOnClick]="data.allowOnClick" [templateId]="data.templateId" [reportId]="data.reportId" [filterType]="data.filterType"
    [drillsIntoReportId]="data.detailDashboardId" (onNumberWidgetClick)="onChartClick.emit($event)">
  </rtms-quilt>
</div>
<div *ngIf="data.cardType === 'mdsDashDetail'" class="full-height">
    <rtms-mds-dash-detail [data]="data"></rtms-mds-dash-detail>
</div>
<div *ngIf="data.cardType === 'pdpmWorksheet'" class="full-height">
  <rtms-pdpm-worksheet [data]="data"></rtms-pdpm-worksheet>
</div>
<div *ngIf="data.cardType === 'ipaAlert'" class="full-height">
  <rtms-ipa-alert [data]="data"></rtms-ipa-alert>
</div>
<div class="citation" *ngIf="hasCitation">
  <rtms-information [ngStyle]="{
    position: data.cardType === 'grid' ? 'absolute' : 'unset',
    bottom: data.cardType === 'grid' ? '18px' : 'unset',
    right: data.cardType === 'grid' ? '0' : 'unset'
  }" [chartName]="data.chartName" [reportId]="data.reportId" [embedChart]="!data.hasData ? false : data.embedChart">
  </rtms-information>
</div>

<form>
  <div class="mb-3">
    <br />
    <label><b>Please note passwords expire every 180 days and you will be unable to reuse passwords.</b></label>
    <br />
    <label> The submit button will become enabled when the following criteria is met:</label>
    <ul>
      <li>Password contains at least 8 characters <img
          [src]="getImageUrl(meetsMinimumCharacter)" width="15" height="15" />
      </li>
      <li>at least one lowercase character <img
          [src]="getImageUrl(hasOneLowerCharacter)" width="15" height="15" />
      </li>
      <li>at least one uppercase character <img
          [src]="getImageUrl(hasOneUpperCharacter)" width="15" height="15" />
      </li>
      <li>one or more digits <img [src]="getImageUrl(hasOneNumber)" width="15"
          height="15" /></li>
      <li>at least one special character(for e.g.@, #, etc.) <img
          [src]="getImageUrl(hasOneSpecialCharacter)" width="15"
          height="15" /></li>
      <li>and both password fields be equal <img
          [src]="getImageUrl(passwordsMatch)" width="15" height="15" /></li>
    </ul>
  </div>
  <div class="mb-3" *ngIf="requireCurrentPassword">
    <label class="text-center" for="password">Current Password</label>
    <div class="input-icon">
      <i class="fa fa-key"></i><input type="password" class="form-control" id="passwordold" name="passwordOld" placeholder="Password"
        [(ngModel)]="existCurrentPassword" (ngModelChange)="setCurrentPassword()" />
    </div>
  </div>
  <div class="mb-3">
    <label class="text-center" for="password">{{passwordLabelText}}</label>
    <div class="input-icon">
      <i class="fa fa-key"></i><input type="password" class="form-control" id="passwordnew" name="passwordNew" placeholder="Password"
        [(ngModel)]="password" (ngModelChange)="validatePassword()">
    </div>
  </div>
  <div class="mb-3">
    <label for="password-confirmation">{{confirmPasswordLabelText}}</label>
    <div class="input-icon">
      <i class="fa fa-key"></i><input type="password" class="form-control" id="password-confirmation" name="passwordConfirmation"
        placeholder="Password" [(ngModel)]="passwordConfirmation" (ngModelChange)="validatePasswordConfirmation()">
    </div>
  </div>
</form>

<ng-template #template>
    <div class="modal-header bg-blue">
        <span class="chart-value titleContent subject-format" [attr.ngbAutofocus]="autoFocus"> <b>{{subjectText ? (subjectText | striphtml) : 'New Message' }} </b></span>
        <span><i class="fa fa-close cursor fa-3x close-btn mts" (click)= "closeModal()" title="Close"></i></span>
    </div>
    <div class="modal-body">
        <table class="table">
            <tr ng-show="_errorMessages.length > 0">
                <td colspan="100%" class="validation-error">
                    <ul>
                        <li *ngFor="let errorMessage of _errorMessages">
                            {{errorMessage.ErrorMessages}}
                        </li>
                    </ul>
                </td>
            </tr>
            <tr *ngIf="isCareCoordinator">
                <td class="input-label">
                    <label class="mrm"><b>Organization</b> :</label>
                </td>
                <td>
                    <span class="multiselect-wrapper change-facility ngSelect">
                        <rtms-facility-selector />
                    </span>
                </td>
            </tr>
            <tr>
                <td class="div label-font" [ngClass]="[label == '' ? 'div-hide' : '', users.length > 0 ? 'label-div' : '' ]" onClick="$('.input').focus()" >{{label}}</td>
                <td class="multiselect-parent" [ngClass]="label == '' ? 'div-show' : '' ">
                    <div class="input-field">
                        <mat-form-field [classList]="'resident-selector-container'" floatLabel="never" appearance="outline">
                            <mat-chip-list #chipList>
                                <mat-chip class="pWSideBarHeight" *ngFor="let user of users" [selectable]="true" [removable]="true"
                                    (removed)="remove(user)">
                                    {{user.UserName}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
            
                                <span><input type="text" matInput [matAutocomplete]="userAutoCompletePanel" name="user" [value]="selectedUser ? selectedUser : ''"
                                        class="input padding-top-sm label-font" spellcheck="false" #searchInput="ngModel" [(ngModel)]="selectedUser"
                                        (ngModelChange)="filterUser(selectedUser)" autofocus [autofocusSelectValue]="!autoFocus"
                                        [matChipInputFor]="chipList" (focus)="setToLabel()" *ngIf="users.length < 25" 
                                        />
                                    <span [hidden]="!showSpinner" title="Loading..." class="icon padding-top-sm"><i
                                            class="fa fa-circle-notch fa-spin loader"></i>
                                    </span>
                                </span>
                            </mat-chip-list>
                        </mat-form-field>
                        <mat-autocomplete #userAutoCompletePanel="matAutocomplete" [displayWith]="displayUser"
                            (optionSelected)="selected($event.option.value)">
                            <mat-option *ngFor="let user of searchedUsers" [value]="user">
                                {{user.UserName}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>                 
                </td>
            </tr>
            <span class="limit-warning" *ngIf="users.length >= 25">
                Maximum recipients
            </span>
            <hr>
            <tr>
                <input type="text" class="input-subject mtm label-font label-bold" name="subject" maxlength="500" placeholder="Subject"
                 [ngModel]="subjectText | striphtml" (ngModelChange)="subjectText=$event" (focus)="setRecipientLabel()">
            </tr>
            <hr>
            <tr>
                <div class="editable compose-msg" contenteditable="true" data-max-length="500" name="compose" (focus)="setRecipientLabel()"
                    [innerHTML]="composeMessageText" (blur)="composeMessageText=$event.target.innerHTML" ></div>
            </tr>
        </table>
    </div>
    <div>
        Sending messages using Real Time User Messaging is the responsibility of the sender.
    </div>
    <div class="modal-footer no-border">
        <button type="button" class="btn btn-format slimScrollDiv" (click)="sendMessage()" title="Send" (focus)="setRecipientLabel()">
            <i class="fa fa-paper-plane icon-btn"></i> Send
        </button>
        <button type="button" class="btn btn-format" (click)="closeModal()" title="Cancel">
            <i class="fa fa-close icon-btn" title="Cancel"></i> Cancel
        </button>
    </div>
</ng-template>

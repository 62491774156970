import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { User } from 'src/app/shared/models/user';
import { UserMessageModel } from 'src/app/shared/models/user-message.model';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { ComposeMessageComponent } from '../compose-message/compose-message.component';
import { InboxService } from '../inbox.service';
import { MessageService } from '../message.service';
import * as _ from 'lodash';

@Component({
  selector: 'rtms-message-detail',
  templateUrl: './message-detail.component.html',
  styleUrls: ['./message-detail.component.scss']
})
export class MessageDetailComponent implements OnInit {

  constructor(
    private _modalService: NgbModal,
    private _toastrService: ToastrService,
    private _inboxService: InboxService,
    private _userStateService: UserStateService,
    private _messageService: MessageService
  ) { }

  @ViewChild('template') public template: TemplateRef<any>;
  @ViewChild('composeMessageComponent') public composeMessageComponent: ComposeMessageComponent;
  @ViewChild('confirmDelete') private confirmDelete: ConfirmComponent;
  @Output() reloadMessage = new EventEmitter<any>();

  @Output() reloadSentMessage = new EventEmitter<any>();
  private modalRef: NgbModalRef;
  showReplyAll = false;
  IsReplyEnabled = false;
  IsReplyVisible = true;
  userMessages: Array<UserMessageModel> = [];
  userId = this._userStateService.getLoggedInUser().userId;
  users: Array<User> = [];
  confirmDeleteBody = '';
  messageRecipientId: number;

  messageQueueId: number;
  hasReportDownload = false;
  isInboxTab: boolean;

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this._modalService.hasOpenModals()) {
        this._modalService.dismissAll();
      }
      this.clear();
    }
  }

  ngOnInit(): void {
  }
  public showModal(userMessages: UserMessageModel[], messageRecipientId: number, messageQueueId: number, isInboxTab: boolean) {
    this.userMessages = userMessages;
    this.messageRecipientId = messageRecipientId;
    this.messageQueueId = messageQueueId;
    this.isInboxTab = isInboxTab;
    this.modalRef = this._modalService.open(this.template, { backdrop: 'static', keyboard: false, windowClass: 'message-popup-width' });
    this.showReplyAll = this.userMessages[0].MessageRecipients.length > 1 ? true : false;
    this.IsReplyEnabled = this.userMessages[0].IsReplyEnabled;
    this.IsReplyVisible = this.userId != this.userMessages[0].FromUserId;
    this.hasReportDownload = this.userMessages.some(x => this._messageService.hasReportDownload(x) == true);
  }
  public closeModal() {
    if (this._modalService.hasOpenModals()) {
      this._modalService.dismissAll();
    }
    this.clear();
  }
  deleteUserMessages() {
    this.showConfirmation();
  }
  delete() {
    let messageRecipientIds = [];
    messageRecipientIds.push(this.messageRecipientId);
    return this._inboxService.deleteMessages(messageRecipientIds)?.then((res) => {
      this._toastrService.success('Message deleted successfully');
      this.closeModal();
      this.reloadMessage.emit(this.userMessages[0]);
    });
  }
  deleteSent() {
    let messageQueueIds = [];
    messageQueueIds.push(this.messageQueueId);
    return this._inboxService.deleteSentMessages(messageQueueIds).then((res) => {
      this._toastrService.success('Message deleted successfully');
      this.closeModal();
      this.reloadSentMessage.emit(this.userMessages[0]);
    });
  }
  replyMessage() {
    this.users = [];
    this.users.push(this.userMessages[0].FromUser);
        this.openReplyMessage(this.users);
  }
  openReplyMessage(users: User[]) {
    this.modalRef.close();
    this.composeMessageComponent.showReplyMessage(this.userMessages, users);
  }
  openReplyToAllMessage() {
    this.users = [];
    this.users.push(this.userMessages[0].FromUser);
    this.userMessages[0].MessageRecipients.forEach(x =>
      this.users.push(x.ToUser)
    );
    this.users = this.users.filter(x => x.UserId != this.userId);
    this.openReplyMessage(this.users);
  }
  clear() {
    this.users = [];
    this.userMessages = [];
    this.messageRecipientId = null;
  }
  private showConfirmation(): void {
    this.confirmDeleteBody = 'Are you sure you want to delete the selected message?';
    this.confirmDelete.Show();
  }
  public onConfirmDelete(isConfirmed: boolean): void {
    if(isConfirmed) {
      if(this.isInboxTab) {
        this.delete();
      }
      else {
        this.deleteSent();
      }
    }
  }

  public getUser(userMessage: UserMessageModel): boolean {
    return userMessage.MessageRecipients.some(x => x.ToUser.UserId === this.userId);
  }
  private getTooltip(userMessage: UserMessageModel) {
    const data: string[] = [];
    userMessage.MessageRecipients.forEach(element => {
      data.push(element.ToUser.UserId === this.userId ? 'You' : element.ToUser.FullUserName);
    });
    return data.sort().map(x=>x).join("; ").toString();
  }
  public getCount(userMessage: UserMessageModel) {
    let count = userMessage.MessageRecipients.length - 1;
    return count > 0 ? '+' + count : '';
  }
  getSubjectTooltip(userMessage: UserMessageModel) {
    return userMessage.Subject;
  }
  getReport(): void {
    this.userMessages.forEach(userMessage => {
      this._messageService.downloadReport(userMessage);
    });
  }
}

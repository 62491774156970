import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { EnvService } from '../../environment/env.service';
import { DashboardFilterOption } from '../../models/dashboard-filter-options';
import { OrganizationModel } from '../../models/models-index';
import { DataService } from '../data.service';

@Injectable()
export class LookUpDataService {

  constructor(
    private http: HttpClient,
     private envService: EnvService,
     private dataService: DataService
  ) {
    // Using dataService here results in circular dependency.
    // Can be fixed once we get rid of filterStateService.ts

  }

  public getCategories(): Observable<any> {
    const urlString = this.envService.api + 'lookupdata/categories-by-organization';
    return this.http.post(urlString, null)
      .pipe(
        map((response: any) => response)
      );
  }

  public getUnitsByOrganizations(organizations: OrganizationModel[]): Observable<any> {
    const urlString = this.envService.api + 'lookupdata/units-by-organizationIds';
    const baseRequest = {
      Organizations: this.dataService.getOrganizationsWithIdAndType(organizations)
    };
    return this.dataService.postForItems(urlString, baseRequest);
  }

  public getPayers(organizations: OrganizationModel[]): Observable<any> {
    const urlString = this.envService.api + 'lookupdata/payers-by-organizationIds';
    const baseRequest = {
      Organizations: this.dataService.getOrganizationsWithIdAndType(organizations)
    };
    return this.dataService.postForItems(urlString, baseRequest);
  }

  public getMedClasses(): Observable<any>{
    const urlString = this.envService.api + 'lookupdata/medication-classes';
    return this.http.post(urlString, null);
  }

  public getDiagnosisTypes(): Observable<any>{
    const urlString = this.envService.api + 'lookupdata/diagnosis-filter-types';
    return this.http.post(urlString, null);
  }

  public getVaccineTypes(): Observable<any>{
    const urlString = this.envService.api + 'lookupdata/vaccine-types-filter';
    return this.http.post(urlString, null);
  }

  public validateDate(useSingleDate: boolean, startDate: Date, endDate: Date, reportId: number): Observable<any> {
    const urlString = this.envService.api + 'validation/validate-filter';
    const baseRequest = {
      UseSingleDate: useSingleDate,
      StartDate: startDate,
      EndDate: endDate,
      ReportId: reportId
    };
    return this.http.post(urlString, baseRequest)
      .pipe(
        map((response: any) => response.Item)
      );
  }
  public getActiveOrganizations(searchValue: string): Observable<any> {
    const urlString = this.envService.api + 'lookupdata/organizations';
    const stringRequest = {
      strRequest: searchValue
    };
    return this.http.post(urlString, stringRequest)
      .pipe(
        map((response: any) => response)
      );
  }

  public getFilterOptions(dashboardId: number): Observable<DashboardFilterOption[]> {
    const urlString = this.envService.api + 'lookupdata/filter-options';
    const integerRequest = {
      intRequest: dashboardId
    };
    return this.http.post(urlString, integerRequest)
    .pipe(
      map((response: any) => response.Items)
    );
  }

}

<ng-template #template>
    <div class="modal-header">
      <span class="modal-title">
          Vitals: {{filterSettingsValue.FilterValue}}
      </span>
      <button class="btn-close" style="align-self: start" (click)='this.closeModals()'></button>
    </div>
    <div class="modal-body modal-body-vitals">
        <div style="padding-top: 50px; text-align: center;" *ngIf="showSpinner">
            <span title="Retrieving details...">
                <i class="fa fa-spinner fa-spin fa-2x"></i>
            </span>
        </div>
        <div class="row" [hidden]="showSpinner">
            <div class="col-md-12 grid-container residentVitals" *ngIf="residentVitals && residentVitals.length > 0">
                <table id="tblGridHeader" style="width: 100%;" class="table table-striped" >
                    <thead>
                        <tr>
                            <th>
                                Date
                            </th>
                            <th class="align-right">
                                Value
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of residentVitals">
                            <td>{{data.EventDt | date:'M/dd/yyyy h:mm:ss a'}}</td>
                            <td class="align-right">{{data.DataValue}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <div class="col-md-12" *ngIf="residentVitals && residentVitals.length === 0">
                <table style="width: 100%;">
                    <tr>
                        <td style="text-align: center; padding-top: 50px;">No {{filterSettingsValue.FilterValue}} data found in last {{numberOfDays}} days.</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</ng-template>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../services/services-index';

@Injectable()
export class TagResidentService {

  private baseUrl: string;

  constructor(
    private http: HttpClient,
    private envService: EnvService
  ) {
    this.baseUrl = `${envService.api}tag-resident`;
  }

  public getResident(req: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/residents`, req);
  }

  public getTaggedResident(req: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/tagged-residents`, req);
  }

  public saveTaggedResidents(req: any): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}`, req);
  }

  public getResidentTaggingStatus(userId: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/resident-tagging-status?userId=${userId}`);
  }

  public getTaggedUsers(userId: any): Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/tagged-users?userId=${userId}`);
  }

  public saveTaggingStatus(req: any) : Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/save-tagging-status`, req);
  }

  public getUsers() : Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/users`);
  }

  public getAdmittedResidents() : Observable<any> {
    return this.http.get<any>(`${this.baseUrl}/by-admission-date`);
  }

  public updateUserTaggedResident(req: any) : Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/update-user-tagged-resident`, req);
  }
}

import { Injectable } from '@angular/core';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { FilterStateService } from '../services/filter-state.service';
import { UserActivityService } from './user-activity.service';


@Injectable()
export class UtilizationMetricsService {
    constructor(
        private userActivityService: UserActivityService,
        private filtersService: FiltersService,
        private filterStateService: FilterStateService
    ) {
    }

    recordChartDrilldown(reportId, reportName, seriesName, xAxisValue, yAxisValue, drillsIntoReportId?): void {
        this.userActivityService.eventTrack(
            'Drilldown',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName,
                    Datapoint: {
                        series: seriesName,
                        Value: {
                            x: xAxisValue,
                            y: yAxisValue
                        }
                    },
                    DrillsIntoReportId: drillsIntoReportId
                })
            },
        );
    }

    recordGridDrilldown(reportId, reportName, filter, drillsIntoReportId): void {
        this.userActivityService.eventTrack(
            'Drilldown',
            {
                category: reportId,
                label: JSON.stringify(
                    {
                        ReportId: reportId,
                        ReportName: reportName,
                        Filter: filter,
                        DrillsIntoReportId: drillsIntoReportId
                    })
            }
        );
    }

    recordTableSorting(reportId, reportName, gridColumnId, headerName, sort): void {
        this.userActivityService.eventTrack(
            'TableSorting',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName,
                    GridColumnId: gridColumnId,
                    Header: headerName,
                    Sorting: sort
                })
            }
        );
    }
    recordFilter(): void {       
        let filterSettings = this.filterStateService.getFilter();
        filterSettings.OrganizationId = this.filterStateService.getSelectedOrganizationId();
        this.userActivityService.eventTrack(
            'Filter',
            {
                label: JSON.stringify({
                    Filter: filterSettings
                })
            }
        );
    }

    recordCardFilter(reportId, filterValue): void {
        this.userActivityService.eventTrack(
            'CardFilter',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    Filter: filterValue
                })
            }
        );
    }

    recordResidentIndicatorClick(reportId, reportName, resMrn): void {
        this.userActivityService.eventTrack(
            'ResidentIndicatorClick',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName,
                    ResMrn: resMrn
                })
            }
        );
    }

    recordInboxActivity(reportId, reportScheduleHistoryId): void {
        this.userActivityService.eventTrack(
            'Inbox-activity',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportScheduleHistoryId: reportScheduleHistoryId
                })
            }
        );
    }

    recordExports(reportId, reportName, exportType, filter): void {
        this.userActivityService.eventTrack(
            'Exports',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName,
                    Format: exportType,
                    Filter: filter
                })
            }
        );
    }

    recordLandingReportClick(reportId, reportName): void {
        this.userActivityService.eventTrack(
            'LandingReportClick',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName
                })
            }
        );
    }

    recordFullScreen(reportId, reportName): void {
        this.userActivityService.eventTrack(
            'FullScreen',
            {
                category: reportId,
                label: JSON.stringify({
                    ReportId: reportId,
                    ReportName: reportName
                })
            }
        );
    }

    recordInActiveUserLogout(): void {
        this.userActivityService.eventTrack(
            'InActiveUserLogout',
            {
                label: 'User Logged out for inactivity.'
            }
        );
    }

    recordUserCardFlip(reportId): void {
        this.userActivityService.eventTrack(
            'UserCardFlip',
            {
                category: reportId,
                label: 'User Flipped Card in HS Dashboard Details.'
            }
        );
    }

    recordResidentChange(resMRN): void {
        this.userActivityService.eventTrack(
            'Resident Changed',
            {
                label: JSON.stringify({
                    Resident: resMRN
                })
            }
        );
    }

    recordSetIPASnoozeDate(reportId, data): void {
        this.userActivityService.eventTrack(
            data.IsSnoozed? 'Snooze IPA Alert' : 'End Snooze IPA Alert',
            {
                category: reportId,
                label: JSON.stringify({
                    MDSId: data.MDSId,
                    DateFound: data.DateFound,
                    SnoozeDate: data.SnoozeDate
                })
            }
        );
    }
}

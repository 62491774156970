<div id="wrapper">
  <!--BEGIN PAGE WRAPPER-->
  <div id="page-wrapper" class="page-wrapper alt-background-color">
      <!--BEGIN TITLE & BREADCRUMB PAGE-->
      <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
          <div class="page-header pull-left">
              <div class="page-title">Reset Password</div>
          </div>
          <div class="clearfix"></div>
      </div>
      <!--END TITLE & BREADCRUMB PAGE--><!--BEGIN CONTENT-->
      <div class="page-content">
          <div class="row">
              <div class="col-lg-3">&nbsp;</div>
              <div class="col-lg-6">
                  <form>
                      <rtms-password-change [passwordChangeIsValid]="passwordChangeIsValid"
                      (changed)="passwordValueChanged($event)" #passwordChangeComponent></rtms-password-change>
                      <div class="mb-3 text-center">
                          <br />
                          <button type="submit" class="btn btn-info" (click)="submit()" [disabled]="!passwordChangeIsValid" >Reset Password &nbsp;<i class="fa fa-chevron-circle-right"></i></button>
                          <button type="button" class="btn btn-dark mlx" (click)="cancel()">Cancel &nbsp;<i class="fa fa-times"></i></button>
                      </div>
                  </form>
              </div>
          </div>
      </div>
      <!--END CONTENT-->
      <!--END PAGE WRAPPER-->
  </div>
</div>

import {Component, OnInit, SecurityContext} from "@angular/core";
import { FeedbackService, FeedbackStep } from './feedback.service';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom, Observable } from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'rtms-feedback',
    templateUrl: './feedback.component.tpl.html'
})
export class FeedbackComponent implements OnInit {

    message = '';
    feedbackStep$: Observable<FeedbackStep>;

    constructor(
      private feedbackService: FeedbackService,
      private toastrService: ToastrService,
      private sanitizer: DomSanitizer) { }

    ngOnInit() {
      this.feedbackStep$ = this.feedbackService.feedbackStep$;
    }

    toggleStep() {
      this.feedbackService.toggleStep();
    }

  async sendEmail() {
    if (this.message === '') { return; }
    if (this.sanitizer.sanitize(SecurityContext.HTML, this.message) === '') {
      this.toastrService.error("Unsafe Feedback", "There is a potential for this to be malicious code");
      return
    }
    try {
      await firstValueFrom(this.feedbackService.sendEmail(
        this.sanitizer.sanitize(SecurityContext.HTML, this.message)
      ));
      this.feedbackService.submitStep();
      this.message = '';
    } catch (error) {
      this.toastrService.error(error.statusText, 'Failed to send feedback');
    }
  }
}

<div id="wrapper">
    <!-- BEGIN PAGE WRAPPER -->
    <div id="page-wrapper" class="page-wrapper message-container">
        <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
            <div class="page-header pull-left">
                <div class="page-title">
                  {{title}}
                </div>
            </div>
            <div class="clearfix"></div>
        </div>
        <div class="page-content alt-background-color message-content">
          <rtms-scheduler-message *ngIf="!isMessageFeatureAccess" [searchText]="_searchText"></rtms-scheduler-message>
          <rtms-message *ngIf="isMessageFeatureAccess" [(searchText)]="_searchText"></rtms-message>
        </div>
        <rtms-feedback></rtms-feedback>
    </div>
</div>

import { Component } from '@angular/core';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { UserInterfaceStateService } from 'src/app/userInterface/store/services/userInterface-state.service';
import { UserModel } from 'src/app/shared/models/models-index';
import { StateService } from '@uirouter/core';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { NavigationService } from 'src/app/shared/services/portal/navigation.service';
import { BehaviorSubject, Observable, pairwise, startWith } from 'rxjs';
import { map } from 'rxjs/operators';
import { FeedbackService } from "../../../pages/feedback/feedback.service";

@Component({
  selector: 'rtms-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent {
   _userAdminEnabled$ = this._filtersService.organizations
     .getOrganizationFeatureStream()
     .pipe(map(orgList => {
       if (orgList && orgList.length > 0) {
         const facility = orgList[0];
         return this._featureService.isFeatureEnabledForFacility(facility, 'PortalUserAdmin');
       }

       return false;
     }));
   _loggedInUser$: Observable<UserModel> = this._userStateService.getLoggedInUserStream();
   _isUserLoggedIn$ = this._loggedInUser$.pipe(map(user => user != null));
   _showTopBar$ = this._userInterfaceStateService.getShowTopBarStream().pipe(startWith(false));
  constructor(
    private _userStateService: UserStateService,
    private _userInterfaceStateService: UserInterfaceStateService,
    public _stateService: StateService,
    private _filtersService: FiltersService,
    private _featureService: FeatureService,
    public _navigationService: NavigationService,
    public _feedbackService: FeedbackService
  ) { }

  openHelp() {
    this._feedbackService.openStep();
  }
}

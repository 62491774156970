import { OpioidScore } from "../components/opioid-score/opioid-score.component";

export class ResidentModel{
    public ResMRN: string;
    public ResNameL: string;
    public ResNameF: string;
    public Resident: string;
    public RowId: number;
    public RequiresACP: boolean;
    public OpioidScore: OpioidScore;

    constructor() {
    }
}

import { Injectable } from '@angular/core';
import { firstValueFrom, Observable, of } from 'rxjs';
import { DataService } from './../data.service';
import { RtmsConstantService } from '../rtms-constant.service';
import { UserStateService } from '../../../user/store/services/user-state.service';
import { catchError, map } from 'rxjs/operators';
import { EnvService } from '../../services/services-index';
import { ParsedUserSetting, UserSetting, UserSettings, UserSettingValue } from '../../models/user-setting';

@Injectable()
export class UserSettingService {

  constructor(
    private dataService: DataService,
    private rtmsConstantService: RtmsConstantService,
    private userStateService: UserStateService,
    private envService: EnvService
  ) {}

  public getUserSettings(): Observable<any> {
    const urlString = this.envService.api + 'user/settings';
    return this.dataService.getForItems(urlString)
      .pipe(
        map((response: UserSetting[]) => {
          return this.parseSettings(response);
        }));
  }

  public getUserSettingByName(name: string): Observable<any> {
    const urlString = this.envService.api + 'user/settings/' + name;
    return this.dataService.getForItem(urlString);
  }

  public setUserSetting(name: string, value: UserSettingValue) : Observable<boolean> {
    const userSettings = this.userStateService.getSettings();
    const setting = userSettings[name];
    if (setting.settingObj.SettingValue !== null && setting.value === value) {
      return of(true);
    }
    setting.value = value;
    setting.settingObj.SettingValue = value.toString();
    return this.saveUserSetting(setting.settingObj);
  }

  public saveUserSetting(userSetting: UserSetting): Observable<boolean> {
    const urlString = this.envService.api + 'user/settings';
    return this.dataService
      .postForItem<boolean>(urlString, userSetting)
      .pipe(catchError(_ => of(false)));
  }

  private parseSettings(responseItems: UserSetting[]): Record<string, ParsedUserSetting> {
    const settings : Record<string, ParsedUserSetting> = {};

    responseItems.forEach((setting) => {
      settings[setting.SettingName] = {
        value: this.extractSettingValue(setting),
        settingObj: setting
      };
    });

    return settings;
  }

  private extractSettingValue(setting: UserSetting) : string | number | boolean {
    const settingTypes = this.rtmsConstantService.settingTypes;

    switch (setting.SettingType) {
      case settingTypes.Boolean:
        return (setting.SettingValue == null
          || setting.SettingValue === undefined) ? false : (setting.SettingValue.toLowerCase() === 'true');
      case settingTypes.Numeric:
        return (setting.SettingValue == null || setting.SettingValue === undefined) ? 0 : parseFloat(setting.SettingValue);
      default:
        return setting.SettingValue;
    }
  }

}

import { Component} from '@angular/core';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { BaseComponent } from 'src/app/shared/components';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'rtms-inbox',
  templateUrl: './inbox.component.tpl.html',
  styleUrls: ['./inbox.component.scss']
})
export class InboxComponent extends BaseComponent{

  isMessageFeatureAccess = false;
  _searchText='';
  title='';

  constructor(
    private _featureService: FeatureService,
    private _filtersService: FiltersService,
  ) {super();}

  async ngOnInit() {
    const organizations = await firstValueFrom(this._filtersService.organizations.getOrganizationFeatureStream());
    if (organizations && organizations.length > 0) {
      const facility = organizations[0];
      this.isMessageFeatureAccess = this._featureService.isFeatureEnabledForFacility(facility, "Messages");
      this.isMessageFeatureAccess ? this.title = "Message Center" : this.title = "Inbox";
    }
  }
}

export enum Filters {
    Units = 'units',
    Payers = 'payers',
    Types = 'types',
    Categories = 'categories',
    QmTypes = 'qmTypes',
    OnlyDischargedResidents = 'onlyDischargedResidents',
    IncludeDischargedResidents = 'includeDischargedResidents',
    DateRange = 'dateRange',
    MedClasses = 'medClasses',
    DiagnosisTypes = 'diagnosisTypes',
    VaccineTypes = 'vaccineTypes'
}

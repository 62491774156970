import { Component, Input, OnChanges, SimpleChanges, ElementRef, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import * as $ from 'jquery';

@Component({
  selector: 'rtms-illogical-adl-score',
  templateUrl: './illogical-adl-score.component.html'
})
export class IllogicalAdlScoreComponent implements OnChanges {

  @Input() data: any;
  @Input() field: string;
  @Input() isShowTooltip; boolean;
  childRows = [];

  illogicalData: string;
  illogicalStateClass: string;
  illogicalTooltip: string;
  illogicalBgColor: string;
  illogicalAdlData: string;

  @ViewChild('illogicalPopover')
  private openPopover: NgbPopover;

  constructor(private elementRef: ElementRef) { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes['field'] || changes['data']) {
      switch (this.field) {
        case 'AdlMobility':
          this.illogicalData = this.data.IllogicalAdlMobilityReason;
          this.illogicalStateClass = this.data.AdlMobilityStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.MobilityInitsExist, this.data.MobilityInits, this.data.AdlMobilityState);
          this.illogicalBgColor = this.data.AdlMobilityStateBgColor;
          this.illogicalAdlData = this.data.AdlMobility;
          break;
        case 'AdlMobilitySup' :
          this.illogicalData = this.data.IllogicalAdlMobilityReason;
          this.illogicalStateClass = this.data.AdlMobilitySupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.MobilitySupInitsExist, this.data.MobilitySupInits, this.data.AdlMobilitySupState);
          this.illogicalBgColor = this.data.AdlMobilitySupStateBgColor;
          this.illogicalAdlData = this.data.AdlMobilitySup;
          break;
        case 'AdlTransferring' :
          this.illogicalData = this.data.IllogicalAdlTransferringReason;
          this.illogicalStateClass = this.data.AdlTransferringStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.TransferringInitsExist, this.data.TransferringInits, this.data.AdlTransferringState);
          this.illogicalBgColor = this.data.AdlTransferringStateBgColor;
          this.illogicalAdlData = this.data.AdlTransferring;
          break;
        case 'AdlTransferringSup' :
          this.illogicalData = this.data.IllogicalAdlTransferringReason;
          this.illogicalStateClass = this.data.AdlTransferringSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.TransferringSupInitsExist, this.data.TransferringSupInits, this.data.AdlTransferringSupState);
          this.illogicalBgColor = this.data.AdlTransferringSupStateBgColor;
          this.illogicalAdlData = this.data.AdlTransferringSup;
          break;
        case 'AdlEating' :
          this.illogicalData = this.data.IllogicalAdlEatingReason;
          this.illogicalStateClass = this.data.AdlEatingStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.EatingInitsExist, this.data.EatingInits, this.data.AdlEatingState);
          this.illogicalBgColor = this.data.AdlEatingStateBgColor;
          this.illogicalAdlData = this.data.AdlEating;
          break;
       case 'AdlEatingSup' :
          this.illogicalData = this.data.IllogicalAdlEatingReason;
          this.illogicalStateClass = this.data.AdlEatingSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.EatingSupInitsExist, this.data.EatingSupInits, this.data.AdlEatingSupState);
          this.illogicalBgColor = this.data.AdlEatingSupStateBgColor;
          this.illogicalAdlData = this.data.AdlEatingSup;
          break;
        case 'AdlToileting' :
          this.illogicalData = this.data.IllogicalAdlToiletingReason;
          this.illogicalStateClass = this.data.AdlToiletingStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.ToiletingInitsExist, this.data.ToiletingInits, this.data.AdlToiletingState);
          this.illogicalBgColor = this.data.AdlToiletingStateBgColor;
          this.illogicalAdlData = this.data.AdlToileting;
          break;
        case 'AdlToiletingSup' :
          this.illogicalData = this.data.IllogicalAdlToiletingReason;
          this.illogicalStateClass = this.data.AdlToiletingSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.ToiletingSupInitsExist, this.data.ToiletingSupInits, this.data.AdlToiletingSupState);
          this.illogicalBgColor = this.data.AdlToiletingSupStateBgColor;
          this.illogicalAdlData = this.data.AdlToiletingSup;
          break;
        case 'AdlMidLevelLocomotionOnUnit':
          this.illogicalData = this.data.IllogicalAdlLocomotionOnUnitReason;
          this.illogicalStateClass = this.data.AdlMidLevelLocomotionOnUnitStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.LocomotionOnUnitExist, this.data.LocomotionOnUnitInits, this.data.AdlMidLevelLocomotionOnUnitState);
          this.illogicalBgColor = this.data.AdlMidLevelLocomotionOnUnitStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelLocomotionOnUnit;
          break;
        case 'AdlMidLevelLocomotionOnUnitSup':
          this.illogicalData = this.data.IllogicalAdlLocomotionOnUnitReason;
          this.illogicalStateClass = this.data.AdlMidLevelLocomotionOnUnitSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.LocomotionOnUnitSupExist, this.data.LocomotionOnUnitSupInits, this.data.AdlMidLevelLocomotionOnUnitSupState);
          this.illogicalBgColor = this.data.AdlMidLevelLocomotionOnUnitSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelLocomotionOnUnitSup;
          break;

        case 'AdlMidLevelLocomotionOffUnit':
          this.illogicalData = this.data.IllogicalAdlLocomotionOffUnitReason;
          this.illogicalStateClass = this.data.AdlMidLevelLocomotionOffUnitStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.LocomotionOffUnitExist, this.data.LocomotionOffUnitInits, this.data.AdlMidLevelLocomotionOffUnitState);
          this.illogicalBgColor = this.data.AdlMidLevelLocomotionOffUnitStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelLocomotionOffUnit;
          break;
        case 'AdlMidLevelLocomotionOffUnitSup':
          this.illogicalData = this.data.IllogicalAdlLocomotionOffUnitReason;
          this.illogicalStateClass = this.data.AdlMidLevelLocomotionOffUnitSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.LocomotionOffUnitSupExist, this.data.LocomotionOffUnitSupInits, this.data.AdlMidLevelLocomotionOffUnitSupState);
          this.illogicalBgColor = this.data.AdlMidLevelLocomotionOffUnitSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelLocomotionOffUnitSup;
          break;

        case 'AdlMidLevelWalkInRoom':
          this.illogicalData = this.data.IllogicalAdlWalkInRoomReason;
          this.illogicalStateClass = this.data.AdlMidLevelWalkInRoomStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.WalkInRoomExist, this.data.WalkInRoomInits, this.data.AdlMidLevelWalkInRoomState);
          this.illogicalBgColor = this.data.AdlMidLevelWalkInRoomStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelWalkInRoom;
          break;
        case 'AdlMidLevelWalkInRoomSup':
          this.illogicalData = this.data.IllogicalAdlWalkInRoomReason;
          this.illogicalStateClass = this.data.AdlMidLevelWalkInRoomSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.WalkInRoomSupExist, this.data.WalkInRoomSupInits, this.data.AdlMidLevelWalkInRoomSupState);
          this.illogicalBgColor = this.data.AdlMidLevelWalkInRoomSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelWalkInRoomSup;
          break;

        case 'AdlMidLevelWalkInCorridor':
          this.illogicalData = this.data.IllogicalAdlWalkInCorridorReason;
          this.illogicalStateClass = this.data.AdlMidLevelWalkInCorridorStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.WalkInCorridorExist, this.data.WalkInCorridorInits, this.data.AdlMidLevelWalkInCorridorState);
          this.illogicalBgColor = this.data.AdlMidLevelWalkInCorridorStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelWalkInCorridor;
          break;
        case 'AdlMidLevelWalkInCorridorSup':
          this.illogicalData = this.data.IllogicalAdlWalkInCorridorReason;
          this.illogicalStateClass = this.data.AdlMidLevelWalkInCorridorSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.WalkInCorridorSupExist, this.data.WalkInCorridorSupInits, this.data.AdlMidLevelWalkInCorridorSupState);
          this.illogicalBgColor = this.data.AdlMidLevelWalkInCorridorSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelWalkInCorridorSup;
          break;

        case 'AdlMidLevelDressing':
          this.illogicalData = this.data.IllogicalAdlDressingReason;
          this.illogicalStateClass = this.data.AdlMidLevelDressingStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.DressingExist, this.data.DressingInits, this.data.AdlMidLevelDressingState);
          this.illogicalBgColor = this.data.AdlMidLevelDressingStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelDressing;
          break;
        case 'AdlMidLevelDressingSup':
          this.illogicalData = this.data.IllogicalAdlDressingReason;
          this.illogicalStateClass = this.data.AdlMidLevelDressingSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.DressingSupExist, this.data.DressingSupInits, this.data.AdlMidLevelDressingSupState);
          this.illogicalBgColor = this.data.AdlMidLevelDressingSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelDressingSup;
          break;
        case 'AdlMidLevelPersonalHygiene':
          this.illogicalData = this.data.IllogicalAdlPersonalHygieneReason;
          this.illogicalStateClass = this.data.AdlPersonalHygieneStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.PersonalHygieneExist, this.data.PersonalHygieneInits, this.data.AdlMidLevelPersonalHygieneState);
          this.illogicalBgColor = this.data.AdlMidLevelPersonalHygieneStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelPersonalHygiene;
          break;
        case 'AdlMidLevelPersonalHygieneSup':
          this.illogicalData = this.data.IllogicalAdlPersonalHygieneReason;
          this.illogicalStateClass = this.data.AdlMidLevelPersonalHygieneSupStateClass;
          this.illogicalTooltip = this.getToolTip(this.data.PersonalHygieneSupExist, this.data.PersonalHygieneSupInits, this.data.AdlMidLevelPersonalHygieneSupState);
          this.illogicalBgColor = this.data.AdlMidLevelPersonalHygieneSupStateBgColor;
          this.illogicalAdlData = this.data.AdlMidLevelPersonalHygieneSup;
          break;
      }
    }
  }

  getToolTip(condition1, condition1Value, condition2): string {
    let returnValue = '';

    if (this.isShowTooltip && condition1) {
      returnValue = condition1Value;
    } else if (condition2 !== 'None') {
      returnValue = condition2 + ' since last MDS';
    }
    return returnValue;
  }

  private onScroll = () => {
    if (this.openPopover && this.openPopover.isOpen()) {
      this.openPopover.close();
    }
  }
  shownPopover(): void {
    const tableContentElement = $(this.elementRef.nativeElement).closest('.fh-report-table-content');
    $(tableContentElement).on('scroll', this.onScroll);
  }

  hiddenPopover(): void {
    const tableContentElement = $(this.elementRef.nativeElement).closest('.fh-report-table-content');
    $(tableContentElement).unbind('scroll', this.onScroll);
  }
}

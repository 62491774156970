import { Component, EventEmitter, IterableDiffers, Input, Output } from '@angular/core';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'rtms-dual-list',
  templateUrl: './rtms-dual-list.component.html',
  styleUrls: ['./rtms-dual-list.component.scss']
})
export class RtmsDualListComponent extends DualListComponent {

  @Input() sourceName = '';
  @Input() targetName = '';
  @Input() disabled = false;
  @Input() keyword = '';
  @Input() sourceLoading = false;
  @Input() destinationLoading = false;

  @Output() selectChange = new EventEmitter();

  constructor(differs: IterableDiffers) {
    super(differs);
  }

  moveAll() {
    this.selectAll(this.available);
    this.moveItem(this.available, this.confirmed);
  }

  removeAll() {
    this.selectAll(this.confirmed);
    this.moveItem(this.confirmed, this.available);
  }

  // Override function in DualListComponent to add custom selectChange event.
  selectItem(list: Array<any>, item: any) {
    const pk = list.filter((e: any) => {
      return Object.is(e, item);
    });
    if (pk.length > 0) {
      // Already in list, so deselect.
      for (let i = 0, len = pk.length; i < len; i += 1) {
        const idx = list.indexOf(pk[i]);
        if (idx !== -1) {
          list.splice(idx, 1);
          this.selectChange.emit({ key: item._id, selected: false });
        }
      }
    } else {
      list.push(item);
      this.selectChange.emit({ key: item._id, selected: true });
    }
  }
  getTooltip(item) {
    const detail = this.source.filter(x => x.Key === item._id);
    if (detail.length > 0) {
      return 'Resident : ' + detail[0].Resident
      + '\n' + 'DOB : ' + detail[0].ResDOBFormatted
      + '\n' + 'Facility :  ' + detail[0].OrganizationName
      + '\n' + 'Unit :  ' + detail[0].UnitName
      + '\n' + 'Room # : ' +  detail[0].RoomBed
      + '\n' + 'Admission Date : ' + detail[0].AdmitDateFormatted
      + '\n' + 'Physician : ' + detail[0].PhysicianName
    } else {
      return '';
    }
  }
  getValue(item, property): string {
    const detail = this.source.filter(x => x.Key === item._id);
    return detail.length > 0 ? detail[0][property] : '';
  }
}

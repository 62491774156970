<div>
    <div class="feedbackbtn" *ngFor="let feedbackStep of [this.feedbackStep$ | async]">
        <h3 (click)="toggleStep()">
            <span><i class="fa fa-envelope"></i> Help &amp; Feedback</span>
            <a class="clickable pull-right" *ngIf="feedbackStep !== 'opened'"><i class="fa fa-minus"></i></a>
        </h3>
        <div>
            <div class="feedback-form">
                <div class="welcome-msg" *ngIf="feedbackStep === 'submitted'">
                    <h4>Thanks for your message!</h4>
                </div>
                <div *ngIf="feedbackStep === 'opened'">
                    <div class="mb-3">
                        <div id="instructions" style="padding-left:5px;">Customer Support: (888) 546-9786. Or, you can submit your help request here:</div>
                        <textarea class="form-control" rows="10"
                                  [ngModel]="message | striphtml" (ngModelChange)="message=$event"
                                  placeholder="Your message"></textarea>
                    </div>
                    <div class="">
                        <button type="button" class="btn btn-sm btn-info" (click)="sendEmail()">Send</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

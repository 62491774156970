import { Injectable, Input } from '@angular/core';
import { mapChart } from 'highcharts';
import { ChartWidgetConfig } from '../models/chart-widget-config';
import { LabelFormatService } from './label-format.service';
import { SelectedChartStateService } from './selected-chart-state.service';

@Injectable()
export class ChartHelperService {
  constructor(private selectedChartStateService: SelectedChartStateService) { }

  public onRedrawChart(self, codeData?) {
    var currentObject = this;
    function handleRedraw(_self, th) {
      if (_self.allowOnClick && _self.chartConfig.chartType !== 'pie' && _self.chartConfig.chartType !== 'map' && _self.chartConfig.chartType !== 'sunburst') {
        if (!_self.chartConfig.blockSelectedPointColor) {
          const selectedChart = _self.selectedChartStateService.getSelectedChartDetails(_self.chartConfig.ReportId);
          if (selectedChart === '' || selectedChart === null || selectedChart === undefined
            || selectedChart.pointX === '' || selectedChart.pointY === '') {
            currentObject.resizeChart(_self.objChart);
            return;
          }
          if (th.series.length > 0) {
            for (let i = 0; i <= th.series.length - 1; i++) {
              const series = th.series[i];

              for (let j = 0; j <= series.data.length - 1; j++) {
                const point = series.data[j];
                if (point.x === selectedChart.pointX && point.y === selectedChart.pointY && point.series.name === selectedChart.series) {
                  point.select(true, true);
                  currentObject.resizeChart(_self.objChart);
                  return;
                }
              }
            }
          }
        }
        currentObject.resizeChart(_self.objChart);
      } else if (_self.chartConfig.chartType === 'map') {
        if (!_self.objChart.isInitialized) {
          currentObject.zoomMapChart(_self, codeData);
        }
        currentObject.resizeChart(_self.objChart);
        const selectedChart: any = currentObject.selectedChartStateService.getSelectedChartDetails(_self.chartConfig.ReportId);
        if (selectedChart === '' || selectedChart === null || selectedChart === undefined) {
          return;
        }
        _self.objChart.get(selectedChart.point.id).select(true);
      } else {
        if (th.series[0] && th.series[0].drillUpButton) {
          th.series[0].drillUpButton.hide();
        }
        currentObject.resizeChart(_self.objChart);
      }
    }
    return function () {
      return handleRedraw(self, this);
    }
  }

  public resizeChart(Chart) {
    const chart = Chart;

    function smoothReflow() {
      setInterval(() => {
        if (chart && chart.options) {
          chart.reflow();
        }
      }, 30, 10); // sidebar animation time - 300, 30 - interval, repeat 10 times
    }

    if (chart) {
      smoothReflow();
    }
  }

  zoomMapChart(self, codeData) {
    if (codeData.length > 0) {
      const zoomRange = codeData.map(function (code) {
        return self.objChart.get(code);
      }).reduce(function (acc, current) {
        // Set map bounds
        acc._minX = Math.min(acc._minX, current._minX);
        acc._maxX = Math.max(acc._maxX, current._maxX);
        acc._minY = Math.min(acc._minY, current._minY);
        acc._maxY = Math.max(acc._maxY, current._maxY);
        return acc;
      });
      setTimeout(() => {
        self.objChart.series[0].xAxis.setExtremes(
          zoomRange._minX,
          zoomRange._maxX,
          false
        );
        self.objChart.series[0].yAxis.setExtremes(
          zoomRange._minY,
          zoomRange._maxY,
          false
        );
        self.objChart.series[0].chart.redraw();
      });
      self.objChart.isInitialized = true;
    }
  }
}

import { Injectable } from '@angular/core';
import { FilterSettings } from '../../models/filter-settings';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { SelectedChartService } from '../selected-chart.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { RtmsConstantService } from '../rtms-constant.service';
import * as moment from 'moment';
import { StateService } from '@uirouter/core';
import { CardFilterStateService } from '../card-filter-state.service';
import { DrillDownStateService } from 'src/app/drill-down/store/services/drill-down-state.service';
import { DataFilter } from '../../models/data-filter';
import { FilterSettingsDto } from '../../models/filter-settings-dto';
import { select } from '@ngrx/store';
import { ReportDataFilterStateService } from '../report-data-filter-state.service';
import { FilterStateService } from '../filter-state.service';
import { DashboardHelperService } from './dashboardHelper.service';


@Injectable()
export class GenericDashboardFilterService {

  constructor(private listsStateService: ListsStateService,
    private filterStateService: FilterStateService,
    private selectedChartService: SelectedChartService,
    private filtersService: FiltersService,
    private rtmsConstantService: RtmsConstantService,
    private stateService: StateService,
    private cardFilterStateService: CardFilterStateService,
    private drillDownStateService: DrillDownStateService,
    private reportDatafilterStateService: ReportDataFilterStateService,
    private dashboardHelperService: DashboardHelperService
  ) { }

  getQMReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard): FilterSettingsDto {
    this.getFilterSettingsForQMReports(dashboardControl.ReportId)
    if (isReportDashboard) {
      return this.getQMReportsReportDashboardFilter(dashboardControl, selectedChartDetails);
    } else if (isDetailDashboard) {
      return this.getQMReportsDetailDashboardFilter(dashboardControl, selectedChartDetails);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      return filterSettingsDto;
    }
  }

  getQMReportsReportDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('QMDetail').Id) {
      if (selectedChartDetails.filter && selectedChartDetails.category) {
        filterSettingsDto.QMTypes = selectedChartDetails.category.replace('*', '');
        if (this.listsStateService.getQMs().length > 0) {
          this.listsStateService.getQMs().map(val => {
            if (selectedChartDetails.category.replace('*', '') === val.QMTypeDesc.replace('*', '')) {
              filterSettingsDto.FilterSettings.QMTypeID = val.QMTypeId;
            }
          });
        }
        filterSettingsDto.FilterSettings.SortType = selectedChartDetails.series;
      } else {
        filterSettingsDto.FilterSettings.user = selectedChartDetails.filter.user;
        filterSettingsDto.FilterSettings.ShortStay = selectedChartDetails.filter.ShortStay;
        filterSettingsDto.FilterSettings.QMTypeID = selectedChartDetails.filter.QMTypeID;
        filterSettingsDto.FilterSettings.Sort = selectedChartDetails.filter.Sort;
        filterSettingsDto.FilterSettings.Denominator = selectedChartDetails.filter.Denominator;
        this.listsStateService.getQMs().forEach(val => {
          if (val.QMTypeId === filterSettingsDto.FilterSettings.QMTypeID) {
            filterSettingsDto.QMTypes = val.QMTypeDesc;
          }
        });
      }
    }
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('LongStayQalityMeasures').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ShortStayQalityMeasures').Id) {
      filterSettingsDto.FilterSettings.ShortStay = dashboardControl.ReportId ===
        this.listsStateService.getReportEnumByName('ShortStayQalityMeasures').Id ? 1 : 0;
      filterSettingsDto.FilterSettings.ReportId = this.listsStateService.getReportEnumByName('QMSummary').Id;
      filterSettingsDto.FilterSettings.RangeFilter = null;
    }
    return filterSettingsDto;
  }

  getQMReportsDetailDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('QMDetail').Id) {
      filterSettingsDto.FilterSettings.ShortStay = selectedChartDetails.reportId
        === this.listsStateService.getReportEnumByName('ResidentShortStayQMs').Id ? 1 : 0;
      filterSettingsDto.FilterSettings.SortType = selectedChartDetails.series;
      // this is so "Detail Date" will display in the report header
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      filterSettingsDto.FilterSettings.Denominator = false;
    }
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('QMSummaryDetail').Id) {
      filterSettingsDto.FilterSettings.IsFilterApplied = true;
      filterSettingsDto.FilterSettings.RangeFilter = selectedChartDetails.filter.RangeFilter;
      filterSettingsDto.FilterSettings.DayRange = selectedChartDetails.filter.DayRange;
    } else if (this.dashboardHelperService.isEnterpriseDashboard()
      && (
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseShortStayQM').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseLongStayQM').Id
      )
    ) {
      filterSettingsDto.FilterSettings.ShortStay = selectedChartDetails.reportId ===
        this.listsStateService.getReportEnumByName('EnterpriseShortStayQM').Id ? 1 : 0;
    } else if (this.dashboardHelperService.isEnterpriseDashboard()
      && (
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ShortStayQMDetailsByFacility').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('LongStayQMDetailsByFacility').Id
      )
    ) {
      filterSettingsDto.FilterSettings.IsFilterApplied = true;
      filterSettingsDto.FilterSettings.RangeFilter = null;
    } else if (this.dashboardHelperService.isEnterpriseDashboard()
      && (
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ShortStayQMsDetails').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('LongStayQMsDetails').Id
      )
    ) {
      filterSettingsDto.FilterSettings.IsFilterApplied = true;
      filterSettingsDto.FilterSettings.RangeFilter = null;
      if (selectedChartDetails.filter.FilterType) {
        filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
      }
    }
    filterSettingsDto.FilterSettings.DataFilters = this.getQMReportsDataFilters(dashboardControl, selectedChartDetails);
    return filterSettingsDto;
  }

  getQMReportsDataFilters(dashboardControl, selectedChartDetails): DataFilter[] {
    const dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('QMDetail').Id) {
      dataFilters
        .push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.category));
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.None, selectedChartDetails.series));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('QMSummaryDetail').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ResidentQMCounts').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.None, selectedChartDetails.filter.FilterValue));
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && (
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ShortStayQMDetailsByFacility').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('LongStayQMDetailsByFacility').Id)) {

      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.QM, selectedChartDetails.filter.FilterValue));
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.CorporationStateNational, selectedChartDetails.series));
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && (
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ShortStayQMsDetails').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('LongStayQMsDetails').Id)) {

      if (selectedChartDetails.filter.FilterType) {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.QM, selectedChartDetails.series));
        dataFilters.push(
          this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.QM, selectedChartDetails.filter.FilterValue));
      }
    }
    return dataFilters;
  }

  getClinicalDashboardReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard): FilterSettingsDto {
    if (isReportDashboard) {
      return this.getClinicalDashboardReportsReportDashboardFilter(dashboardControl);
    } else if (isDetailDashboard) {
      return this.getClinicalDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      return filterSettingsDto;
    }
  }

  getClinicalDashboardReportsReportDashboardFilter(dashboardControl): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (filterSettingsDto.FilterSettings.Categories && filterSettingsDto.FilterSettings.Categories !== '') {
      filterSettingsDto.FilterSettings.DataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.AlertsCategory, filterSettingsDto.FilterSettings.Categories));
    }

    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordSearch').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordCMISearch').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordRiskManagement').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordInfectionControl').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordDischarge').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordHSSearch').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
    }

    filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    return filterSettingsDto;
  }

  getClinicalDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (dashboardControl.ReportId !== selectedChartDetails.reportId) {
      if (this.dashboardHelperService.isEnterpriseDashboard()
        && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinuteDetail').Id) {
        filterSettingsDto.FilterSettings.IsFilterApplied = true;
      }
      filterSettingsDto.FilterSettings.DataFilters = this.getClinicalDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    }
    return filterSettingsDto;
  }

  getClinicalDashboardReportsDataFilters(dashboardControl, selectedChartDetails): DataFilter[] {
    const dataFilters = [];

    if (selectedChartDetails.filter.FilterType && selectedChartDetails.filter.FilterValue) {
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    }
    return dataFilters;
  }

  getRehospitalizationDashboardReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard): FilterSettingsDto {
    let filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.SelectedChartDetails = selectedChartDetails;
    if (isReportDashboard) {
      filterSettingsDto.FilterSettings = this.filterStateService.getFilter();
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (isDetailDashboard || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HospitalTransferReasons').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasons').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseLongStayHospitalizationsByFacility').Id) {
      filterSettingsDto = this.getRehospitalizationDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails);
    } else {
      filterSettingsDto.FilterSettings = this.filterStateService.getFilter();
    }
    return filterSettingsDto;
  }

  getRehospitalizationDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HospitalTransferReasons').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HospitalTransferReasonsDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('HospitalTransferReasons').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HospitalTransferReasonsDetail').Id) {
        filterSettingsDto.FilterSettings.DataFilters = this.getRehospitalizationDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.FilterValue === 'Current' ? moment().format('MM/YYYY') : selectedChartDetails.filter.FilterValue;
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasons').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsByFacility').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasons').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsDetail').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsByFacility').Id) {
        filterSettingsDto.FilterSettings.DataFilters = this.getRehospitalizationDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseLongStayHospitalizationsByFacility').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseLongStayHospitalizationsDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseLongStayHospitalizationsByFacility').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseLongStayHospitalizationsDetail').Id) {
        filterSettingsDto.FilterSettings.DataFilters = this.getRehospitalizationDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      }
    } else if (dashboardControl.ReportId !== selectedChartDetails.reportId) {
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmissionRateDetails').Id) {
        const dateformat = selectedChartDetails.category.split('/');
        const dateformatString = dateformat[0] + '/1/' + dateformat[1];
        filterSettingsDto.FilterSettings.StartDate = moment(new Date(dateformatString)).utc().toDate();
        selectedChartDetails.filter.FilterValue = selectedChartDetails.series;
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.StartDate).format('MM/YYYY');
      } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('DaysPerThousandDetail').Id &&
        selectedChartDetails.filter.FilterType !== this.rtmsConstantService.filterTypes.Payer) {
        selectedChartDetails.filter.FilterType = selectedChartDetails.series === 'Long Stay' ?
          this.rtmsConstantService.filterTypes.LongStay : this.rtmsConstantService.filterTypes.ShortStay;
        filterSettingsDto.FilterSettings.FilterType = selectedChartDetails.filter.FilterType;
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo;
      } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmissionRateDetailsDx').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmittedPrimaryDx3090Detail').Id) {
        switch (selectedChartDetails.series.toLowerCase()) {
          case 'last 30 days':
            filterSettingsDto.FilterSettings.ThirtyNinety = 30;
            break;
          default:
            filterSettingsDto.FilterSettings.ThirtyNinety = 90;
        }
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageLOSDetail').Id) {
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.FilterValue === 'Current' ? moment().format('MM/YYYY')
          : selectedChartDetails.filter.FilterValue;
        filterSettingsDto.FilterSettings.IsDrillDown = true;
      } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersByFacility').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersDetail').Id) {
          if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersByFacility').Id) {
            selectedChartDetails.category = null;
            selectedChartDetails.filter.FilterType = this.rtmsConstantService.filterTypes.MonthYear;
            selectedChartDetails.filter.FilterValue = selectedChartDetails.filter.DetailInfo;
          }
      } else {
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo ? selectedChartDetails.filter.DetailInfo
          : moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
        filterSettingsDto.FilterSettings.IsDrillDown = true;
      }
      filterSettingsDto.FilterSettings.DataFilters = this.getRehospitalizationDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
    }

    filterSettingsDto.SelectedChartDetails = selectedChartDetails;
    return filterSettingsDto;
  }

  getRehospitalizationDashboardReportsDataFilters(dashboardControl, selectedChartDetails): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmissionRateDetails').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.PayerCategory, selectedChartDetails.filter.FilterValue));
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.MonthYear, selectedChartDetails.category));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('DaysPerThousandDetail').Id &&
      selectedChartDetails.filter.FilterType !== this.rtmsConstantService.filterTypes.Payer) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.None, selectedChartDetails.series));
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmissionRateDetailsDx').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmittedPrimaryDx3090Detail').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.Diagnosis, selectedChartDetails.filter.FilterValue));
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.ThirtyNinety, selectedChartDetails.series));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HospitalTransferReasonsDetail').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsByFacility').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsDetail').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseHospitalTransferReasonsByFacility').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.filter.FilterValue));
      }
      else {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.TransferReasonCategory, selectedChartDetails.filter.FilterValue));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ReadmissionRateDetailsDx').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MonthlyAdmissionsPerThousandMembersDetail').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersByFacility').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.MonthYear, selectedChartDetails.filter.FilterValue));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersDetail').Id ) {
      var facReportfilters = this.reportDatafilterStateService.getReportDataFilterForReport(this.listsStateService.getReportEnumByName('EnterpriseMonthlyAdmissionsPerThousandMembersByFacility').Id);
      if (facReportfilters && facReportfilters.length) {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.MonthYear, facReportfilters[0].FilterValue));
      }
      if (selectedChartDetails.category != null) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.category));
      }
    } else {
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    }
    return dataFilters;
  }

  getHSDashboardReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard, allFacsSelectedForDrilldown): FilterSettingsDto {
    if (isDetailDashboard) {
      return this.getHSDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getFilter();

      if (!isReportDashboard) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      }

      return filterSettingsDto;
    }
  }

  getHSDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getFilter();
    filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(selectedChartDetails.reportId);
    if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
      && selectedChartDetails.filter) {
      filterSettingsDto.FilterSettings.IsDrillDown = true;
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSFacilityReadmissionsByPrimaryDiagnosis').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityReadmissionsByPrimaryDiagnosis').Id) {
      filterSettingsDto.FilterSettings.IsDrillDown = true;
    }
    if (dashboardControl.data['parentReportId'] === this.listsStateService.getReportEnumByName('FacilityReadmissionsByPrimaryDiagnosis').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('FacilityReadmissionsByPrimaryDiagnosis').Id);
    }
    filterSettingsDto.FilterSettings.DataFilters = this.getHSDashboardReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    return filterSettingsDto;
  }

  getHSDashboardReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): DataFilter[] {
    let dataFilters = [];
    if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
      && selectedChartDetails.filter) {
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('CardReadmissionRiskDistributionDetail').Id) {
        if (selectedChartDetails.series) {
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.RiskLevel, selectedChartDetails.series));
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility,
            selectedChartDetails.filter.FilterValue));
        } else {
          dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
        }
      } else {
        if (dashboardControl.ReportId !== this.listsStateService.getReportEnumByName('CardReadmissionRiskDistributionByFacility').Id) {
          dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.category));
        }
      }
    }
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSFacilityReadmissionsByPrimaryDiagnosisDetail').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSFacilityReadmissionsByPrimaryDiagnosisTrending').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSFacilityReadmissionsByPrimaryDiagnosis').Id) {

      dataFilters = this.getFiltersforPrimaryDiagnosis(selectedChartDetails, allFacsSelectedForDrilldown);

    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTDaysPer1000Detail').Id) &&
      selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('HSProACTDaysPer1000').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Month, selectedChartDetails.series));

    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTAdmissions1000Detail').Id) &&
      selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('HSProACTAdmissions1000').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Month, selectedChartDetails.series));
    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSRehospitalizationRateDetail').Id) &&
      selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('HSRehospitalizationRate').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Month, selectedChartDetails.series));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSAverageLOSDetail').Id) {
      const facilityFilterIndex = dataFilters.findIndex(s => s.FilterType === 'Facility');
      if (facilityFilterIndex >= 0) {
        dataFilters.splice(facilityFilterIndex, 1);
      }
      if (selectedChartDetails.series === 'Current LOS') {
        dataFilters.push(this.addDataFilter('LOSFacilityCurrent', selectedChartDetails.category));
      } else {
        dataFilters.push(this.addDataFilter('LOSFacilityHistorical', selectedChartDetails.category));
      }
    }
    return dataFilters;
  }

  getHHDashboardReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard, allFacsSelectedForDrilldown): FilterSettingsDto {
    if (isDetailDashboard) {
      return this.getHHDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getFilter();

      if (!isReportDashboard) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      }

      return filterSettingsDto;
    }

  }

  getHHDashboardReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();

    if ((dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActAcuteHospitalizations306090').Id) ||
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsTrend').Id) ||
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsDetail').Id)) {
      if ((dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActAcuteHospitalizations306090').Id)) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      } else if (
        (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsTrend').Id) ||
        (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsDetail').Id)
      ) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('HHHSProActAcuteHospitalizations306090').Id);
      }
    } else {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(selectedChartDetails.reportId);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHProActAcuteHospitalizations306090').Id ||
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHProActAcuteHospitalizations306090Detail').Id) {
        switch (selectedChartDetails.series.toLowerCase()) {
          case '30 days':
            filterSettingsDto.FilterSettings.ThirtySixtyNinety = 30;
            break;
          case '60 days':
            filterSettingsDto.FilterSettings.ThirtySixtyNinety = 60;
            break;
          default:
            filterSettingsDto.FilterSettings.ThirtySixtyNinety = 90;
        }
      }
      else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHReadmittedPrimaryDx3090Detail').Id) {
        switch (selectedChartDetails.series.toLowerCase()) {
          case 'last 30 days':
            filterSettingsDto.FilterSettings.ThirtyNinety = 30;
            break;
          default:
            filterSettingsDto.FilterSettings.ThirtyNinety = 90;
        }
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      } else if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid) && selectedChartDetails.filter) {
        filterSettingsDto.FilterSettings.IsDrillDown = true;
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSFacilityReadmissionsByPrimaryDiagnosis').Id) {
        filterSettingsDto.FilterSettings.IsDrillDown = true;
        filterSettingsDto.FilterSettings.DataFilters = this.getHHDashboardReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
      }
    }
    if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
      && selectedChartDetails.filter) {
      filterSettingsDto.FilterSettings.DataFilters = this.getHHDashboardReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    }

    return filterSettingsDto;
  }

  getHHDashboardReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSFacilityReadmissionsByPrimaryDiagnosis').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSFacilityReadmissionsByPrimaryDiagnosisTrend').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSFacilityReadmissionsByPrimaryDiagnosisDetail').Id) {
      dataFilters = this.getFiltersforPrimaryDiagnosis(selectedChartDetails, allFacsSelectedForDrilldown);
    }
    else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSLOSDetail').Id) {
      const facilityFilterIndex = dataFilters.findIndex(s => s.FilterType === 'Facility');
      if (facilityFilterIndex >= 0) {
        dataFilters.splice(facilityFilterIndex, 1);
      }
      if (selectedChartDetails.series === 'ALOS') {
        dataFilters.push(this.addDataFilter('LOSFacilityHistorical', selectedChartDetails.category));
      } else {
        dataFilters.push(this.addDataFilter('LOSFacilityCurrent', selectedChartDetails.category));
      }
    } else if (
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsTrend').Id) ||
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHHSProActHospitalizationsDetail').Id)
    ) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.category));
    } else if (
      (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHProActAcuteHospitalizations306090Detail').Id)
    ) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.HHThirtySixtyNinety, selectedChartDetails.series));
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.HHMonthYear, selectedChartDetails.category));

    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HHReadmittedPrimaryDx3090Detail').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Diagnosis, selectedChartDetails.category));
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.ThirtyNinety, selectedChartDetails.series));
    }
    return dataFilters;
  }

  getReportsReportDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();

    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    filterSettingsDto.FilterSettings.IsDrillDown = selectedChartDetails.filter.IsDrillDown;
    filterSettingsDto.FilterSettings.Denominator = selectedChartDetails.filter.Denominator;
    filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');

    if (filterSettingsDto.FilterSettings.IsDrillDown) {
      if (!selectedChartDetails.filter.DataFilters || selectedChartDetails.filter.DataFilters.length === 0) {
        filterSettingsDto.FilterSettings.DataFilters = [];
        filterSettingsDto.FilterSettings.DataFilters.push(
          this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      } else {
        filterSettingsDto.FilterSettings.DataFilters = selectedChartDetails.filter.DataFilters;
      }
    }
    return filterSettingsDto;
  }

    getInfectionControlReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard): FilterSettingsDto {
        if (isReportDashboard) {
            return this.getInfectionControlReportDashboardFilter(dashboardControl, selectedChartDetails);
        } else if (isDetailDashboard) {
            return this.getInfectionControlDetailDashboardFilter(dashboardControl, selectedChartDetails);
        } else {
            const filterSettingsDto = new FilterSettingsDto();
            filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();

            if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByClass').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypes').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByProvider').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypeSummary').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTrendSummary').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypesDetail').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHealthcareVsCommunityInfections').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControl').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedications').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizations').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsByUnit').Id
                || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsDetail').Id
            ) {
              filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
            }
            return filterSettingsDto;
        }
    }

  getInfectionControlReportDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionSurveillance').Id
         || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('Immunizations').Id) {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      return filterSettingsDto;
    } else {
      return this.getReportsReportDashboardFilter(dashboardControl, selectedChartDetails);
    }
  }

  getInfectionControlDetailDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedications').Id
          || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsByFacility').Id
          || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsDetail').Id) {
          if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedications').Id) {
              filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
          } else {
              filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(selectedChartDetails.reportId);
          }
      }
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionSurveillance').Id) {
          filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
          filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('DiseaseWatch').Id);
      }

    if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByClass').Id
      || selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByClassPerUnit').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('AntibioticOrdersByClass').Id);
    } else if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('InfectionTypes').Id
      || selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('InfectionTypesPerUnit').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('InfectionTypes').Id);
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByProvider').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByProviderDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(selectedChartDetails.reportId);
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControl').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControlFacility').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControlDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseInfectionControl').Id);
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypeSummary').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypesDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('InfectionTypeSummary').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypesDetail').Id) {
        filterSettingsDto.DetailInfo = '';
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHealthcareVsCommunityInfections').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHealthcareVsCommunityInfectionsByFacility').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionsDetails').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseHealthcareVsCommunityInfections').Id);
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PsychotropicMedicationsDetail').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HighRiskMedicationsDetail').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsDetail').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsDetail').Id) {
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizations').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsByUnit').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsDetail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('NumberOfImmunizations').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsByUnit').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsDetail').Id) {
        if (selectedChartDetails.series !== "Immunization Counts") {
          const filter: any[] = this.checkCardFilter(selectedChartDetails.reportId);
          selectedChartDetails.series = filter[0].SelectedCardFilters[0].Name;
        }
        else {
          const numberFromChartName = selectedChartDetails.chartName.match(/\d+/g);
          const numberOfImmunizations = `${numberFromChartName[0].toString()} ${numberFromChartName[0] == 0 || numberFromChartName[0] > 1 ? "Immunizations" : "Immunization"} `
          selectedChartDetails.filter.FilterValue = numberOfImmunizations;
        }
      }
    } else {
      if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
        && selectedChartDetails.filter) {
        filterSettingsDto.FilterSettings.StartDate = selectedChartDetails.filter.StartDate;
        filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
        filterSettingsDto.FilterSettings.IsDrillDown = true;
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo;
      }
    }

    filterSettingsDto.FilterSettings.DataFilters = this.getInfectionControlDetailDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
    return filterSettingsDto;
  }

  getDiseaseWatchReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard): FilterSettingsDto {
    if (isReportDashboard) {
      return this.getReportsReportDashboardFilter(dashboardControl, selectedChartDetails);
    } else if (isDetailDashboard) {
      return this.getDiseaseWatchDetailDashboardFilter(dashboardControl, selectedChartDetails);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('DiseaseWatch').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionPositivity').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Current').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsByCountyCurrent').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MetricByCountyPer1000Beds').Id
      ) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      }
      return filterSettingsDto;
    }
  }

  getDiseaseWatchDetailDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();

    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();

    filterSettingsDto.SelectedChartDetails = selectedChartDetails;
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('DiseaseWatch').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(selectedChartDetails.reportId);
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Current').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Trending').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Detail').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Current').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Detail').Id) {
        if (this.dashboardHelperService.isEnterpriseDashboard() && selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Trending').Id) {
          filterSettingsDto.DetailInfo = '';
        } else {
          filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
        }
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MetricByCountyPer1000Beds').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SignsSymptomsTrending16Weeks').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SymptomsSurveillanceDetail').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('DiseaseWatch').Id) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('DiseaseWatch').Id);
      }
      else {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('MetricByCountyPer1000Beds').Id);
      }
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SymptomsSurveillanceDetail').Id) {
        if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('SignsSymptomsTrending16Weeks').Id) {
          filterSettingsDto.DetailInfo = '';
        } else {
          filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
        }
      }
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsTrending').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsByCountyCurrent').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsByCountyDetail').Id)) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('VaccinationsByCountyCurrent').Id);
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsByCountyDetail').Id) {
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      }
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SymptomsSurveillanceDetail').Id) {
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else {
      if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
        && selectedChartDetails.filter) {
        filterSettingsDto.FilterSettings.StartDate = selectedChartDetails.filter.StartDate;
        filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
        filterSettingsDto.FilterSettings.IsDrillDown = true;
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo;
      }
    }
    filterSettingsDto.FilterSettings.DataFilters = this.getDiseaseWatchDetailDashboardReportsDataFilters(dashboardControl, selectedChartDetails);
    return filterSettingsDto;
  }

  getResidentDashboardReportsFilter(dashboardControl, isReportDashboard, isModal): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (isReportDashboard && !isModal) {
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    }
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ResidentAbnormalLabs').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ResidentOrders').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('Labs').Id
    ) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
    }
    return filterSettingsDto;
  }

  getPDPMDashboardReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard,
    allFacsSelectedForDrilldown): FilterSettingsDto {
    if (isReportDashboard) {
      return this.getReportsReportDashboardFilter(dashboardControl, selectedChartDetails);
    } else if (isDetailDashboard) {
      return this.getPDPMReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummary').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id
      ) {
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(dashboardControl.ReportId);
      }
      return filterSettingsDto;
    }
  }

  getPDPMReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    if (this.dashboardHelperService.isEnterpriseDashboard() && (
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinutes').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinsByDiagnosis').Id)) {
      filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && (
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummary').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummaryByFacility').Id)) {
      filterSettingsDto.FilterSettings.IsFilterApplied = true;
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && (
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummaryDetail').Id)) {
      filterSettingsDto.FilterSettings.IsFilterApplied = true;
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id);
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id) {
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id);
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsCurrent').Id) {
      filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id);
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsCurrent').Id) {
      filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
      filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id);
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('IPAAlertDetail').Id) {
      filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
      const ipaAlertsChartData = this.drillDownStateService.getDrillDownHistory().find(a => a.reportId === this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id);
      if (ipaAlertsChartData) {
        filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(ipaAlertsChartData.filter.FilterValue);
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsByFacility').Id);
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      }
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('StateMdsAlertsDetail').Id) {
      filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
      const ipaAlertsChartData = this.drillDownStateService.getDrillDownHistory().find(a => a.reportId === this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id);
      if (ipaAlertsChartData) {
        filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(ipaAlertsChartData.filter.FilterValue);
        filterSettingsDto.FilterSettings.SelectedCardFilters = this.checkCardFilter(this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsByFacility').Id);
        filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
      }
    } else {
      if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
        && selectedChartDetails.filter) {
        filterSettingsDto.FilterSettings.StartDate = selectedChartDetails.filter.StartDate;
        filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
        filterSettingsDto.FilterSettings.IsDrillDown = true;
        filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo;
      }
    }
    filterSettingsDto.FilterSettings.DataFilters = this.getPDPMReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    return filterSettingsDto;
  }

  getPDPMReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('TherapyFunctionalScoreDetail').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.FunctionalScoreCategory,
          selectedChartDetails.filter.FilterValue));
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard() && (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinutes').Id
        || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinsByDiagnosis').Id))) {
      dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard()
        && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AverageTherapyMinuteDetail').Id)) {
      if (selectedChartDetails.filter.FilterType) {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.series));
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.Month, selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.filter.FilterValue));
      }
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard() &&
        (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummary').Id))) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.PDPMSummaryCategory, selectedChartDetails.series));
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard() &&
        dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummaryByFacility').Id)) {
      dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.PDPMSummarySubCategory, selectedChartDetails.filter.FilterValue));
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard() &&
        (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterprisePDPMSummaryDetail').Id))) {
      dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
      if (selectedChartDetails.filter.FilterType) {
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.PDPMSummarySubCategory, selectedChartDetails.filter.FilterValue));
      }
    } else if (
      (this.dashboardHelperService.isEnterpriseDashboard()
        && (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseIPAAlertsCurrent').Id || selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseStateMDSAlertsCurrent').Id )
        && (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('IPAAlertDetail').Id))
    ) {
      dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
      dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
      && selectedChartDetails.filter) {
      dataFilters = [];
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    }
    return dataFilters;
  }

  getFinancialReportsFilter(dashboardControl, selectedChartDetails, isDetailDashboard, isReportDashboard,
    allFacsSelectedForDrilldown): FilterSettingsDto {
    if (isReportDashboard) {
      return this.getFinancialReportsReportDashboardFilter(dashboardControl, selectedChartDetails);
    } else if (isDetailDashboard) {
      return this.getFinancialReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    } else {
      const filterSettingsDto = new FilterSettingsDto();
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      return filterSettingsDto;
    }
  }

  getFinancialReportsReportDashboardFilter(dashboardControl, selectedChartDetails): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.SelectedChartDetails = selectedChartDetails;
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    filterSettingsDto.FilterSettings.IsDrillDown = selectedChartDetails.filter.IsDrillDown;
    filterSettingsDto.FilterSettings.Denominator = selectedChartDetails.filter.Denominator;
    filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');

    if (filterSettingsDto.FilterSettings.IsDrillDown) {
      if (!selectedChartDetails.filter.DataFilters || selectedChartDetails.filter.DataFilters.length === 0) {
        filterSettingsDto.FilterSettings.DataFilters = [];
        if (dashboardControl.data['parentReportId'] === this.listsStateService.getReportEnumByName('RUGsIVADLDistributionBarChart').Id
          && selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('RUGsIVADLDistributionBarChart').Id) {
          return this.setFilterSettingsForADLDistTrendingDetail(filterSettingsDto);
        } else {
          filterSettingsDto.FilterSettings.DataFilters.push(
            this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
        }
      } else {
        filterSettingsDto.FilterSettings.DataFilters = selectedChartDetails.filter.DataFilters;
      }
    }
    return filterSettingsDto;
  }

  getFinancialReportsDetailDashboardFilter(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();

    filterSettingsDto.SelectedChartDetails = selectedChartDetails;
    if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('MDSQRP').Id) {
      filterSettingsDto.SelectedChartDetails.pointX = filterSettingsDto.SelectedChartDetails.pointY = '';
    } else if ((this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseADLRUGsIVDistributionDetail').Id)
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityPayerRatio').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityPayerRatioDetail').Id
      || (dashboardControl.Category === 'pdpm' && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PDPMWorksheet').Id)) {
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MDSQRPDetail').Id) {
      filterSettingsDto.FilterSettings.isFilterApplied = true;
      filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
      filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseIllogicalADLCodingTrending').Id) {
      filterSettingsDto.FilterSettings.StartDate = selectedChartDetails.filter.StartDate;
      filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(selectedChartDetails.filter.FilterValue);
    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('IllogicalADLDetail').Id) {
      const illogicalADLCodingChartData = this.drillDownStateService.getDrillDownHistory().
        find(a => a.reportId === this.listsStateService.getReportEnumByName('EnterpriseIllogicalADLCoding').Id);
      if (illogicalADLCodingChartData) {
        filterSettingsDto.FilterSettings.OrganizationId = this.listsStateService.getOrganizationIdByName(illogicalADLCodingChartData.filter.FilterValue);
      }
    } else {
      if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
        && selectedChartDetails.filter) {
        if (dashboardControl.data['parentReportId'] ===
          this.listsStateService.getReportEnumByName('RUGsIVADLDistributionBarChart').Id) {
          return this.setFilterSettingsForADLDistTrendingDetail(filterSettingsDto);
        } else {
          filterSettingsDto.FilterSettings.StartDate = selectedChartDetails.filter.StartDate;
          filterSettingsDto.FilterSettings.MDSId = selectedChartDetails.filter.MDSId;
          filterSettingsDto.FilterSettings.IsDrillDown = true;
          filterSettingsDto.DetailInfo = selectedChartDetails.filter.DetailInfo;
        }
      }
    }
    filterSettingsDto.FilterSettings.DataFilters = this.getFinancialReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown);
    return filterSettingsDto;
  }

  getFinancialReportsDataFilters(dashboardControl, selectedChartDetails, allFacsSelectedForDrilldown): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseQRPPercentages').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MDSQRP').Id ||
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('ADLRUGsIVDistribution').Id) {
      dataFilters = [];
    } else if (this.dashboardHelperService.isEnterpriseDashboard() && dashboardControl.ReportId === this.listsStateService.getReportEnumByName('MDSQRPDetail').Id) {
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.Quarter, selectedChartDetails.series));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseIllogicalADLCodingTrending').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility,
        selectedChartDetails.filter.FilterValue));
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Month,
        selectedChartDetails.series));
    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('IllogicalADLDetail').Id) {
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseADLRUGsIVDistributionDetail').Id) {
      if (selectedChartDetails.series) {
        dataFilters.push(
          this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility,
          selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(
          this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    }
    else {
      if ((dashboardControl.ReportId !== selectedChartDetails.reportId || dashboardControl.data.isFooterGrid)
        && selectedChartDetails.filter) {
        dataFilters = [];
        if (dashboardControl.data['parentReportId'] !==
          this.listsStateService.getReportEnumByName('RUGsIVADLDistributionBarChart').Id) {
          if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityPayerRatio').Id ||
            dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityPayerRatioDetail').Id) {
            dataFilters = this.getFiltersforPayerRatio(selectedChartDetails, allFacsSelectedForDrilldown);
          } else {
            dataFilters.push(
              this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
          }
        }
      } else {
        if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacilityPayerRatio').Id) {
          dataFilters = this.getFiltersforPayerRatio(selectedChartDetails, allFacsSelectedForDrilldown);
        }
      }
    }
    return dataFilters;
  }

  getAdminReportsFilter(dashboardControl): FilterSettingsDto {
    const filterSettingsDto = new FilterSettingsDto();
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('FacPortalUsage').Id) {
      filterSettingsDto.DetailInfo = moment(new Date()).format('MM/DD/YYYY');
      filterSettingsDto.FilterSettings = null;
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('KeywordsByFacility').Id) {
      filterSettingsDto.DetailInfo = moment(new Date()).format('MM/DD/YYYY');
      filterSettingsDto.FilterSettings = {
        OrganizationId: this.filterStateService.getSelectedOrganization().OrganizationId,
        ReportId: this.listsStateService.getReportEnumByName('KeywordsByFacility').Id
      };
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PortalUsage').Id) {
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      filterSettingsDto.FilterSettings.reverseSort = this.stateService.params.reverseSort;
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('UtilizationScore').Id) {
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
      filterSettingsDto.DetailInfo = ` ${moment(filterSettingsDto.FilterSettings.StartDate).format('MM/DD/YYYY')}
          - ${moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY')}`;
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('UserLoginReport').Id) {
      filterSettingsDto.FilterSettings = this.filterStateService.getCurrentFilter();
    }
    return filterSettingsDto;
  }

  getFiltersforPrimaryDiagnosis(selectedChartData, allFacsSelectedForDrilldown) {
    if (allFacsSelectedForDrilldown) {
      return [this.addDataFilter(selectedChartData.filter.FilterType, selectedChartData.filter.FilterValue)];
    } else {
      return [
        this.addDataFilter('Facility', selectedChartData.category),
        this.addDataFilter(selectedChartData.filter.FilterType, selectedChartData.series)
      ];
    }
  }

  getFiltersforPayerRatio(selectedChartDetails, allFacsSelectedForDrilldown) {
    if (allFacsSelectedForDrilldown) {
      return [this.addDataFilter(this.rtmsConstantService.filterTypes.PayerRatioDetail,
        selectedChartDetails.point.name === undefined ? selectedChartDetails.series : selectedChartDetails.point.name)];
    } else {
      return [
        this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.category),
        this.addDataFilter(this.rtmsConstantService.filterTypes.PayerRatioDetail, selectedChartDetails.series)
      ];
    }
  }

  setFilterSettingsForADLDistTrendingDetail(filterSettingsDto: FilterSettingsDto): FilterSettingsDto {
    filterSettingsDto.SelectedChartDetails.filter.FilterValue =
      this.selectedChartService.getADLDistTrendingDetailFilterValue(filterSettingsDto.SelectedChartDetails.category);
    filterSettingsDto.FilterSettings.StartDate = this.selectedChartService.getADLDistDetailDateBySeries(filterSettingsDto.SelectedChartDetails.series);
    filterSettingsDto.FilterSettings.EndDate = filterSettingsDto.FilterSettings.StartDate;
    filterSettingsDto.FilterSettings.IsDrillDown = true;
    filterSettingsDto.FilterSettings.DataFilters.push(
      this.addDataFilter(filterSettingsDto.SelectedChartDetails.filter.FilterType, filterSettingsDto.SelectedChartDetails.filter.FilterValue));
    filterSettingsDto.FilterSettings.DataFilters.push(
      this.addDataFilter(this.rtmsConstantService.filterTypes.None, filterSettingsDto.SelectedChartDetails.series));
    filterSettingsDto.DetailInfo = moment(filterSettingsDto.FilterSettings.EndDate).format('MM/DD/YYYY');
    return filterSettingsDto;
  }

  getInfectionControlDetailDashboardReportsDataFilters(dashboardControl, selectedChartDetails): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationPercentagesEnterpriseDetail').Id) {
      dataFilters.push(
        this.addDataFilter(this.rtmsConstantService.filterTypes.Facility,
          selectedChartDetails.filter.FilterValue));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticSurveillanceDetail').Id) {
      switch (selectedChartDetails.series.toLowerCase()) {
        case 'point prevalence':
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.AntibioticPointPrevalence, selectedChartDetails.filter.FilterValue));
          break;
        case 'antibiotic starts':
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.AntibioticStarts, selectedChartDetails.filter.FilterValue));
          break;
        case 'antibiotic dot':
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.AntibioticDOT, selectedChartDetails.filter.FilterValue));
          break;
        default:
          throw new Error(('Invalid series filter.'));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByClassPerUnit').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypesPerUnit').Id) {
      dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByProviderDetail').Id) {
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.PhysicianName, selectedChartDetails.point.name));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('AntibioticOrderDetail').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypeDetail').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('AntibioticOrdersByClassPerUnit').Id
        || selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('InfectionTypesPerUnit').Id) {
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.UnitName, selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionSurveillance').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTrendSummary').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionTypesDetail').Id
    ) {
      if (selectedChartDetails.filter.FilterValue) {
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControlFacility').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControl').Id) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.IndicationForUse, selectedChartDetails.category));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControlDetail').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionControlFacility').Id) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.IndicationForUse, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.filter.FilterValue));
      } else {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.IndicationForUse, selectedChartDetails.category));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('InfectionsDetails').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HealthcarevsCommunityInfectionsPerUnit').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('HealthcarevsCommunityInfectionsPerUnit').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.UnitName, selectedChartDetails.filter.FilterValue));
      }
      else {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.InfectionType, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseInfectionsDetails').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('EnterpriseHealthcareVsCommunityInfections').Id) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.InfectionType, selectedChartDetails.filter.FilterValue));
      }
      else {
        dataFilters.push(
          this.addDataFilter(this.rtmsConstantService.filterTypes.InfectionType, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PsychotropicMedicationsDetail').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HighRiskMedicationsDetail').Id

      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsByFacility').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsByFacility').Id) {
      dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.ShortStay, selectedChartDetails.series));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsDetail').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsByFacility').Id);
        if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('HSProACTHighRiskMedicationsByFacility').Id) {
            dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
        }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsDetail').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsByFacility').Id);
        if (selectedChartDetails.series == "Facility") {
          dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
        }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsByUnit').Id || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('NumberOfImmunizationsDetail').Id) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.NumberOfImmunizations, selectedChartDetails.filter.FilterValue));
        if (selectedChartDetails.category) {
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.UnitName, selectedChartDetails.category));
        }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsDetail').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsByFacility').Id);
        if (selectedChartDetails.filter.FilterType !== this.rtmsConstantService.filterTypes.MedClass) {
          dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.filter.FilterValue));
        }
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('EnterpriseHighRiskMedicationsByFacility').Id) {
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.ShortStay, selectedChartDetails.series));
        dataFilters.push(this.addDataFilter(this.rtmsConstantService.filterTypes.Facility, selectedChartDetails.filter.FilterValue));
    }
    return dataFilters;
  }
  getDiseaseWatchDetailDashboardReportsDataFilters(dashboardControl, selectedChartDetails): DataFilter[] {
    let dataFilters = [];
    if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SignsSymptomsTrending16Weeks').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SignsSymptomsTrending8Weeks').Id) {
      dataFilters.push(
        this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    } else if (dashboardControl.ReportId === this.listsStateService.getReportEnumByName('SymptomsSurveillanceDetail').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsTrending').Id
      || dashboardControl.ReportId === this.listsStateService.getReportEnumByName('VaccinationsByCountyDetail').Id) {
      if (selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('SignsSymptomsTrending16Weeks').Id) {
        dataFilters = this.reportDatafilterStateService.getReportDataFilterForReport(selectedChartDetails.reportId);
      }
      if (selectedChartDetails.filter.FilterValue) {
        dataFilters.push(
          this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
      }
    } else if (this.dashboardHelperService.isEnterpriseDashboard() &&
      dashboardControl.ReportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Detail').Id &&
      selectedChartDetails.reportId === this.listsStateService.getReportEnumByName('PercentPositiveCOVID19Trending').Id) {
      dataFilters.push(this.addDataFilter(selectedChartDetails.filter.FilterType, selectedChartDetails.filter.FilterValue));
    }
    return dataFilters;
  }

  addDataFilter(filterType, filterValue) {
    return {
      FilterType: filterType === null ? this.rtmsConstantService.filterTypes.None : filterType,
      FilterValue: filterValue
    };
  }

  checkCardFilter(reportId: number): [] {
    return this.cardFilterStateService.getCardFilterForReport(reportId);
  }

  getSelectedCardFilterName(reportId: number): string {
    var name: string = "";
    var cardFilters = this.cardFilterStateService.getCardFilterForReport(reportId);
    if (cardFilters && cardFilters.length > 0 && cardFilters[0].CardFilterItems && cardFilters[0].CardFilterItems.length) {
      name = cardFilters[0].CardFilterItems[0].Name;
    }

    return name;
  }

  getFilterSettingsForQMReports(reportId) {
    let filterSettings = this.filterStateService.getFilter();
    filterSettings.DayRange = 7;
    switch (reportId) {
      case this.listsStateService.getReportEnumByName('ResidentQMCounts').Id:
        filterSettings.DayRange = 0;
        break;
      case this.listsStateService.getReportEnumByName('QMCounts').Id:
      case this.listsStateService.getReportEnumByName('QMSummary').Id:
      case this.listsStateService.getReportEnumByName('EnterpriseShortStayQM').Id:
      case this.listsStateService.getReportEnumByName('EnterpriseLongStayQM').Id:
        filterSettings.RangeFilter = null;
        break;
      case this.listsStateService.getReportEnumByName('ResidentLongStayQMs').Id:
        filterSettings.ShortStay = false;
        break;
      case this.listsStateService.getReportEnumByName('ResidentShortStayQMs').Id:
        filterSettings.ShortStay = true;
        break;
      case this.listsStateService.getReportEnumByName('QMAverageCompare').Id:
        filterSettings.ShortStay = filterSettings.ShortStay === undefined ? true : filterSettings.ShortStay;
        break;
      default:
        break;
    }
    this.filterStateService.setFilter(filterSettings);
  }
  isDateRange(): boolean {
    return (this.stateService.params.category === 'enterprise-diseasewatch');
  }
}

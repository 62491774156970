import { Component, Input, OnInit } from '@angular/core';
import { UtilizationMetricsService } from 'src/app/shared/analytics/utilization-metrics.service';
import { InboxGroupModel } from 'src/app/shared/models/inbox-group.model';
import { InboxModel } from 'src/app/shared/models/inbox.model';
import { OrganizationModel } from 'src/app/shared/models/organization.model';
import { ExportService, ReportService } from 'src/app/shared/services/services-index';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { InboxService } from '../inbox.service';
import { MessageService } from '../message.service';

@Component({
  selector: 'rtms-scheduler-message',
  templateUrl: './scheduler-message.component.html'
})
export class SchedulerMessageComponent implements OnInit {

  constructor(        
    private _inboxService: InboxService,
    private _utilizationMetricsService: UtilizationMetricsService,
    private _messageService: MessageService) {
    
    this.loadGroupsArray();
    this.getScheduledReportsData();    
   }

  ngOnInit(): void {
  }
  private _gridData: Array<InboxModel> = [];
  public _groups: Array<InboxGroupModel>= [];
  @Input() searchText: string = '';
  loadGroupsArray(): any {
      this._groups = [];
      this._groups.push(new InboxGroupModel('Today',1,true));
      this._groups.push(new InboxGroupModel('Last 7 Days',2,true));
      this._groups.push(new InboxGroupModel('Older',3,true));
  }
  
  get visibleGroups() {
      return this._groups.filter( x => x.Hide===false);
    }

  protected getScheduledReportsData(): void {
      this._gridData = [];

      this._inboxService.getScheduledReports().then(
          res => {
              this._gridData = res;
              let groups = this._groups;
              this._gridData.forEach(function (report) {
                  groups.filter( x => x.Id===report.InboxGroup)[0].Hide=false;
              });
              this.removeGroupsWithNoItems();
          }
      );
  }

  protected removeGroupsWithNoItems(): any {
      for(var i = this._groups.length -1; i >= 0 ; i--){
          if(this._groups[i].Hide){
              this._groups.splice(i, 1);
          }
      }
  }

  protected filterInbox(event: any) { 
      this._gridData = this._gridData.filter((listing: any) => listing.DisplayName === event.target.value);
      console.log(event.target.value);
  }

  protected getReport(model:InboxModel ): void {
      this._utilizationMetricsService.recordInboxActivity (model.ReportId, model.ReportScheduleHistoryId);
      this._messageService.downloadScheduledReport({...model, EntityId: model.ReportScheduleHistoryId});
    }
}

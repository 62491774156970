export class TagResidentModel {
    public ResNameF : string;
    public ResNameL : string;
    public ResMRN  : string;
    public ICDCode : string;
    public ICDDesc : string;
    public PhysicianName : string;
    public OrganizationId : number;
    public Residents : Array<any>;
    public TaggingStatus : boolean;
    public FilterValue : string;
    public UserIds : number[];
    public IsSearched: boolean;
    public Key : string;
}
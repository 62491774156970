<span *ngIf="opioidScore != OpioidScoreEnum.NotApplicable && opioidScore != null">
    <a [ngClass]="{'cursor': isClickable}" (click)="this.showDetail(opioidPopover)" [ngbPopover]="scorePopOver" id="ngbPopover" #opioidPopover="ngbPopover"
        container="body" placement="right" triggers="outsideClick" popoverClass="{{popoverClass}}"
        [title]="isClickable ? 'Click to show Adverse Drug Event (ADE) risk' : '' ">
        <span class="badge opioidIndicator"
            [ngClass]="{'badge-danger': opioidScore == OpioidScoreEnum.HighRisk, 'badge-warning': opioidScore == OpioidScoreEnum.ModerateRisk, 'badge-success': opioidScore == OpioidScoreEnum.LowRisk}">O</span>
    </a>
    <ng-template #scorePopOver>
        <div class="popover-title" class="{{popoverClass}}">
            <span id="ngbPopoverClose" class="cursor pull-right" (click)="closePopover(opioidPopover)"><i
                    class="fa fa-close"></i></span>
            <span class="text-center font-16"
                [ngClass]="{'danger': opioidScore == OpioidScoreEnum.HighRisk, 'warning': opioidScore == OpioidScoreEnum.ModerateRisk, 'success': opioidScore == OpioidScoreEnum.LowRisk}">
                {{popoverTitle}}</span>
        </div>
        <div class="pts pbs">
            <div class="col-md-12 pls prn bold pbm">{{resident}}</div>
            <div class="col-md-12 pls prn font-13" [innerHTML]="opioidMessage"></div>
        </div>
    </ng-template>
</span>

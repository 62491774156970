<div [ngClass]="{ 'portalusage full-height' : isFacPortalUsage } ">
    <div id="wrapper">
        <!--BEGIN PAGE WRAPPER-->
        <div id="page-wrapper" class="page-wrapper" [ngClass]="DashboardClass">
            <!--BEGIN TITLE & BREADCRUMB PAGE-->
            <div *ngIf="reportsHeaderIsVisible()">
                <rtms-reports-header [reportTitle]="DisplayName"
                    [showFilter]="!isDetailDashboard && !hideFilterButton"
                    [reportsVm]="genericDashboardVM" [showQms]="isReportDashboard" [showPayers]="!isDetailDashboard"
                    [showUnits]="!isDetailDashboard" [showTypes]="isReportDashboard"
                    [showCategories]="isReportDashboard" [currentDisplayReport]="''" [showBackToButton]="showBackButton"
                    [backButtonText]="isReportDashboard? 'Back to Reports Menu' : 'Back to Dashboard'"
                    [backButtonHref]="backButtonHref" [showMenu]="false"
                    [allowChangeOrg]="!isDetailDashboard && !hideFacilityDropdown && !isAdminReports && !isInfectionSurveillance && !isResidentList"
                    [showDischargeOnly]="isReportDashboard" [hideFacilityName]="shouldHideFacilityName()"
                    [dashboardId]="dashboardId"
                    [showMedClasses]="!isDetailDashboard">
                </rtms-reports-header>
            </div>
            <button *ngIf="isModal" class="btn-close pull-right" style="margin-right: 5px;" (click)='closeModal()'></button>
            <!--END TITLE & BREADCRUMB PAGE-->
            <!--BEGIN CONTENT-->
            <div class="page-content" [ngClass]="{ 'portalusage-noPadding' : ( isFacPortalUsage || isKeywordAdminReport ) ,'details': isDetailDashboard, 'multi-report': isMdsDashDetail, 'adlvsmdtable-content': isPDPMWorksheet}" id="step1">
                <div class="row">
                    <div id="add-filter"
                        [ngClass]="{'col-lg-2 clearfix': gridFullSize, 'col-lg-3 clearfix': !gridFullSize }"
                        [hidden]="!IsShowFilter">
                        <rtms-filter #rtmsFilter *ngIf="filterIsVisible()" (reload)="dashboardBuilderReload()"></rtms-filter>
                      </div>

                    <div id="dashboard-data"
                        [ngClass]="{'full-width': !IsShowFilter,'col-lg-10': gridFullSize, 'col-lg-9': !gridFullSize}">
                          <div id="draggablePanelList" class="row list-unstyled">
                              <div *ngFor="let control of dashboards"
                                [ngClass]=" control.data.cardClass? control.data.cardClass : control.data.isWideCard? 'col-lg-12' : 'col-lg-6' "
                                   [hidden]="control.data.flipCard && !control.data.flipChartShow ">
                                  <div *ngIf="!control.data.hiddenCard"
                                      id="Dashboard_{{dashboardId}}_card_{{control.ReportId}}">
                                      <div class="fullscreen-holder chart-position"
                                          *ngIf="(!control.data.isFooterGrid) || showFooterDetails">
                                          <div class="fullscreen flip-container"
                                              [ngClass]="{'hover' : control.data.flipCard && !control.data.flipChartShow}"
                                              *ngIf="control.data.flipCard == null || (control.data.flipCard && control.data.flipChartShow) ">
                                              <div [ngBusy]="control.data.statusPromise"
                                                  [class.flipper]="control.data.flipCard"
                                                  [ngClass]="getGridClass(control.data.cardType, control.data.gridClass, control.data.isFooterGrid, control.data.reportId)">
                                              <rtms-card [cardType]="control.data.cardType === 'ipaAlert' ? 'grid' :control.data.cardType"
                                                      [chartName]="control.data.chartName"
                                                      [reportId]="control.data.reportId"
                                                      [templateId]="control.data.templateId"
                                                      [isLoading]="control.isLoading"
                                                      [hasData]="control.data.hasData"
                                                      [exportDisabled]="control.data.exportDisabled"
                                                      [exportPdfDisabled]="control.data.exportPdfDisabled"
                                                      [embedChart]="control.data.embedChart"
                                                      [filterOptions]="control.data.filterOptions"
                                                      [isHeaderClickable]="control.data.isHeaderClickable"
                                                      [drilldownReport]="control.data.drilldownReport"
                                                      [drilldownDestination]="control.data.drilldownDestination"
                                                      [isShortCard]="control.data.isShortCard"
                                                      (reloadChart)="reloadChart.emit($event)"
                                                      (resetCardDetails)="resetCardDetails()"
                                                      [flipCard]="control.data.flipCard"
                                                      (cardFlipped)="cardFlipped($event)"
                                                      [detailInfo]="control.data.hideDetailDate? '' : control.data.detailInfo"
                                                      [drilldownId]="control.data.detailDashboardId"
                                                      [autoHeight]="control.data.autoHeight && !showFooterDetails"
                                                      [rowSpan]="control.data.rowSpan"
                                                      [showResetDetailsIcon]="control.data.showResetDetailsIcon"
                                                      [exportFilter]="control.data.exportFilter"
                                                      [noDataMessage]="control.data.noDataMessage"
                                                      [hideHamburgerMenu]="isModal"
                                                      [headerTemplate] = "headerTemplate"
                                                      >
                                                      <rtms-chart-wrapper exportable [data]="control.data" [dashboard]="control" [hasCitation]="control.HasCitation"
                                                          (onChartClick)="gotoDetails($event)"
                                                          (gridRowClick)="( (control.data.drillDownGridId || control.data.detailDashboardId || (control.data.gridOptions && control.data.gridOptions.rowClickEnabled)) && gridRowClicked($event))"
                                                          (gridCellClick)="gridCellDrillDown($event)">
                                                      </rtms-chart-wrapper>
                                                  </rtms-card>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <ng-template #headerTemplate>
                                      <span class="panel-heading-title pull-left topmargin7 reportTitle" [ngClass]="getHeaderClass(control.data)">
                                          <strong
                                              [attr.title]="control.data.chartName"
                                              id="ReportHeader_{{control.data.reportId}}"
                                              [innerHTML]="control.data.chartName">
                                          </strong>
                                          <a *ngIf="control.data.showResetDetailsIcon !== true ? false : true"
                                              class="reset-button"
                                              (click)="resetCardDetails()" title="Click here to reset to all.">
                                              <i class="fa fa-undo" aria-hidden="true"></i>
                                          </a>
                                          <span *ngIf="control.data.cardType === 'residentDetail' && control.data.seriesData && control.data.seriesData[0]">:
                                              <rtms-resident-detail-card-header [data]="control.data"></rtms-resident-detail-card-header>
                                          </span>
                                      </span>
                                  </ng-template>
                              </div>
                          </div>
                    </div>
                </div>
            </div>
            <!--END CONTENT-->
            <div *ngIf="feedbackIsVisible()">
                <rtms-feedback></rtms-feedback>
            </div>
            <div>
                <rtms-resident-card-score-detail #residentCardScoreDetailComponent></rtms-resident-card-score-detail>
            </div>
            <div>
                <rtms-resident-lab-results #residentLabResultsComponent ></rtms-resident-lab-results>
            </div>
            <div>
              <rtms-hipps-popup #hippsPopupComponent ></rtms-hipps-popup>
          </div>
      </div>

        <!--END PAGE WRAPPER-->
    </div>
</div>

import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/user';
import { InboxService } from '../inbox.service';
import * as _ from 'lodash';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { UserMessageModel } from 'src/app/shared/models/user-message.model';
import { OrganizationModel } from 'src/app/shared/models/models-index';
import { FilterStateService, UINotifierService } from 'src/app/shared/services/services-index';
import { UIEventTypes } from 'src/app/shared/enums/ui-event-types';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rtms-compose-message',
  templateUrl: './compose-message.component.html',
  styleUrls: ['./compose-message.component.scss']
})
export class ComposeMessageComponent extends BaseComponent implements OnInit {

  constructor(
    private _modalService: NgbModal,
    private _toastrService: ToastrService,
    private _inboxService: InboxService,
    private _userStateService: UserStateService,
    public uiNotifierService: UINotifierService,
    private filterStateService: FilterStateService,
  ) {
      super();
      this.subscribeFilterState();
   }
  @ViewChild('template') public template: TemplateRef<any>;
  @Output() sentMessage = new EventEmitter<any>();
  subjectText = '';
  userMessages : Array<UserMessageModel> = [];
  preReplyText = "Re:"
  messageQueueId: number;
  users: Array<User> = [];
  user = this._userStateService.getLoggedInUser();
  title = '';
  selectable = true;
  removable = true;
  autoCompleteMinChars: number = 3;
  selectedUser: any;
  prevSearchString: string = '';
  searchedUsers: Array<User> = [];
  showSpinner = false;
  composeMessageText: '';
  userId = this.user.userId;
  isCareCoordinator = this.user.IsCareCoordinator;
  _errorMessages: any = [];
  autoFocus = false;
  recipientsLabel = "Recipients";
  toLabel = "To";
  label = this.toLabel;
  filterOrg: OrganizationModel = this.filterStateService.getSelectedOrganization();

  @HostListener('document:keydown', ['$event'])
  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === "Escape") {
      if (this._modalService.hasOpenModals()) {
        this._modalService.dismissAll();
      }
      this.clear();
    }
  }
  ngOnInit(): void {
  }
  remove(user: User): void {
    const index = this.users.indexOf(user);

    if (index >= 0) {
      this.users.splice(index, 1);
    }
    this.label = this.toLabel;
  }
  selected(val: User): void {
    if (!this.users.find(x => x.UserId == val.UserId)) {
      this.users.push(val);
    }
    this.selectedUser = null;
  }

  subscribeFilterState() {
    this.subscriptions.push(this.uiNotifierService.getUIEvents().
        pipe(filter(notificationEvent =>
            notificationEvent && notificationEvent.type === UIEventTypes.ChangeFacility))
        .subscribe(event => {
            if (event) {
                this.filterOrg = event.value;
                this.users = [];
                this.searchedUsers = [];
                this.selectedUser = '';
            }
        })
    );
  }

  displayUser(data: User) {
    if (typeof data === 'string' || data === null || data === undefined) {
      return data;
    }
    return data.UserName;
  }
  private filterUser = _.debounce((val: string): void => {
    if (val && val.length >= this.autoCompleteMinChars) {
      if (this.prevSearchString && this.prevSearchString != ''
        && val.toLowerCase().indexOf(this.prevSearchString.toLowerCase()) >= 0
        && this.searchedUsers && this.searchedUsers.length > 0) {
        this.searchedUsers = this.searchedUsers.filter(option => option.FullUserName.toLowerCase().indexOf(val.toLowerCase()) >= 0);
      } else {
        this.showSpinner = true;
        let orgId = -1;
        if (this.isCareCoordinator) {
          orgId = this.filterOrg.OrganizationId;
        }
        this._inboxService.getUsersByName(val, orgId).then(
          res => {
            this.searchedUsers = res.filter(x => x.UserId != this.userId && !this.users.find(f => f.UserId === x.UserId));
            this.showSpinner = false;
          }
        ).catch(err => {
          this.showSpinner = false;
        });
      }
      this.prevSearchString = val;
    } else {
      this.searchedUsers = [] as Array<User>;
    }

  }, 500);
  showReplyMessage(userMessages, users) {
    this.userMessages = userMessages;
    this.users = users;
    this.subjectText = this.userMessages.map((x) => x.Subject)[0];
    this.subjectText = this.subjectText.indexOf(this.preReplyText) !== -1 ? this.subjectText : this.preReplyText + ' ' + this.subjectText;
    this.messageQueueId = this.userMessages.map((x) => x.MessageQueueId)[0];
    this.autoFocus = true;
    this.showModal( "Reply Message");
  }
  showModal(title)
  {
    this.title = title;
    this._modalService.open(this.template, { backdrop: 'static', keyboard: false, windowClass:'message-compose-popup-width' });
  }
  sendMessage() {
    var toUser = new UserMessageModel();
    toUser.ToUserIds = this.users.map((x) => x.UserId);
    toUser.Message = this.composeMessageText;
    toUser.Subject = this.subjectText;
    toUser.ParentMessageQueueId = this.messageQueueId
    this._inboxService.saveMessages(toUser).then(
      res => {
        this.closeModal();
        this._toastrService.success('Message sent successfully');
        this.sentMessage.emit(toUser);
      }, (error) => {
        if (error.status === 400) {
          this._errorMessages= [];
          error.error.forEach(err => {
            this._errorMessages.push(err);
          });
        }
      });
  }
  public closeModal() {
    if (this._modalService.hasOpenModals()) {
      this._modalService.dismissAll();
    }
    this.clear();
  }
  clear() {
    this.users = [];
    this.subjectText = '';
    this.messageQueueId = null;
    this.selectedUser = null;
    this.userMessages = [];
    this.title = '';
    this._errorMessages = [];
    this.composeMessageText = '';
    this.autoFocus = false;
    this.label = this.toLabel;
    this.searchedUsers = [];
  }
  setRecipientLabel() {
    this.selectedUser || this.users.length > 0 ?  this.label = '' : this.label = this.recipientsLabel;
  }

  setToLabel() {
    this.label = this.toLabel;
  }
}

<div id="wrapper">
  <!--BEGIN PAGE WRAPPER-->
  <div id="page-wrapper" class="page-wrapper alt-background-color">
    <!--BEGIN TITLE & BREADCRUMB PAGE-->
    <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
      <div class="page-header pull-left">
        <div class="page-title">Request Password Reset</div>
      </div>
      <div class="clearfix"></div>
    </div>
    <!--END TITLE & BREADCRUMB PAGE-->
    <!--BEGIN CONTENT-->
    <div class="page-content">
      <div class="row">
        <div class="col-lg-3">&nbsp;</div>
        <div class="body-content col-lg-4">
          <div [ngClass]="resetSuccess ? 'alert alert-success' : 'alert alert-danger'" role="alert"
            *ngIf="errMessage !== ''" style="font-size:14px;">
            {{errMessage}}
          </div>
          <div *ngIf="!showUsernameBlock">
            <div class="mb-3" *ngIf="!resetSuccess">
              <div>
                Please enter your email address below, the Reset Password button will become enabled after your email
                address has been entered.
                <br /><br /> If you do not have an email address associated with your RealTime Portal Account or you are
                not sure
                <a (click)="toggleDiv()" class='btn-forgot-pwd cursor'>click here</a>.
              </div> <br />
              <div class="input-icon">
                <i class="fa fa-envelope"></i><input autofocus type="email" placeholder="Email Address" size="40"
                  [(ngModel)]="email" (ngModelChange)="checkEmail()"  name="emailRef" class="form-control" required>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="mb-3 text-center">
              <br />
              <div>
                <button type="button" [hidden]="!resetSuccess" (click)="cancel()" class="btn btn-success">Back to Login
                  &nbsp;<i class="fa fa-times"></i></button>
              </div>
              <div [hidden]="resetSuccess">
                <button [disabled]="!allowRequest" class="btn btn-info" (click)="sendPwReset()">Reset Password
                  &nbsp;<i class="fa fa-chevron-circle-right"></i></button>
                <button type="button" class="btn btn-dark mlx" (click)="cancel()">Cancel &nbsp;<i
                    class="fa fa-times"></i></button>
              </div>
            </div>
          </div>
          <div *ngIf="showUsernameBlock">
            <div class="mb-3" *ngIf="!resetSuccess">
              <div>
                Please enter your username below, submitting this will alert support team and they will get back to you.
                <br /><br /> If you have
                an email address associated with your RealTime Account <a (click)="toggleDiv()"
                  class='btn-forgot-pwd cursor'>
                  click here
                </a>.
              </div><br />
              <div class="input-icon">
                <i class="fa fa-user"></i><input autofocus type="text" placeholder="Username" size="40"
                [(ngModel)]="username" (ngModelChange)="checkUsername()"  name="username" class="form-control" required>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="mb-3 text-center">
              <div>
                <button type="button" [hidden]="!resetSuccess" (click)="cancel()" class="btn btn-success">Back to Login
                  &nbsp;<i class="fa fa-times"></i></button>
              </div>
              <br />
              <div [hidden]="resetSuccess">
                <button [disabled]="!allowRequest" class="btn btn-info"
                  (click)="requestPasswordResetWithoutEmail()">Request Password Reset &nbsp;<i
                    class="fa fa-chevron-circle-right"></i></button>
                <button type="button" class="btn btn-dark mlx" (click)="cancel()">Cancel &nbsp;<i
                    class="fa fa-times"></i></button>
              </div>
            </div>
          </div>
          <div class="clearfix"></div>
          <br />
        </div>
      </div>
    </div>
    <!--END CONTENT-->
    <!--END PAGE WRAPPER-->
  </div>
</div>

<mat-expansion-panel expanded="true" hideToggle="true" class="figcaption.ratings panel-margin">

  <tabset #messageCenterTabset>
    <ng-container>
      <tab>
        <ng-template tabHeading>
          <i class="fa fa-envelope" aria-hidden="true"></i> Inbox
        </ng-template>
        <div class="card-block">

          <div class="main-section">
            <div class="d-block d-sm-inline-block col-lg-3 col-md-4 col-sm-6 col-xs-12 no-padding" *ngIf="this.messages.length > 0 && !showSpinner">
              <input placeholder="Search" class="form-control" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChanged()">
            </div>
            <span class="pull-right no-padding">
              <button *ngIf="hasMessageFeatureAccess" type="button" class="btn btn-info mrm" (click)="openComposeMessage()" title="Compose">
                <i class="fas fa-edit"></i> Compose
              </button>
              <button type="button" class="btn btn-danger mrm" (click)="deleteMessages()"
                *ngIf="selectedUserMessages.length > 0" title="Delete">
                <i class="fas fa-trash-alt"></i> Delete
              </button>
            </span>
          </div>

          <div class="table-scroll">
            <table width="100%"
              class="table ignore-table-limits table-bordered table-hover reports_background reportSmallFont"
              border="1" *ngIf="this.messages.length > 0 && !showSpinner">
              <tbody>
                <th></th>
                <th>From</th>
                <th>Subject</th>
                <th (click)="onSortInbox()" style="cursor: pointer;" title="Click to Sort">Received<i *ngIf="sortDirection === 'asc'" class="fa fa-sort-asc ng-star-inserted"></i><i *ngIf="sortDirection === 'desc'" class="fa fa-sort-desc ng-star-inserted"></i></th>
                <tr *ngFor="let message of this.messages|messageFilter:this.searchText"
                  [ngClass]="message.DateRead === null ? 'new-message' : 'old-message'"
                  (click)="openMessage(message.MessageQueueId, message.MessageRecipientId)" class="cursor">
                  <td class="col-content-similar cursor-auto vertical-align-middle" (click)="$event.stopPropagation();"
                    width="3%">
                    <input type="checkbox" class="checkbox" value="{{message.MessageQueueId}}"
                      [checked]="isChecked(message.MessageQueueId)"
                      (change)="onMessageSelectionChange(message, $event.target.checked)" #checkboxes />
                  </td>

                  <td class="vertical-align-middle" width="15%">
                    {{message.FromUser.FullUserName}}
                  </td>
                  <td width="35%">
                    <a *ngIf="hasReportDownload(message)" (click)="getReport(message, $event)">
                      <div class="vertical-align-middle underline">
                        <div class="message-subject-format">{{message.Subject | striphtml}}</div>
                        <div class="message-format reportSmallFont message">{{message.Message | striphtml}}</div>
                      </div>
                    </a>
                    <div class="vertical-align-middle" *ngIf="!hasReportDownload(message)">
                      <div class="message-subject-format">{{message.Subject | striphtml}}</div>
                      <div class="message-format reportSmallFont message">{{message.Message | striphtml}}</div>
                    </div>
                  </td>
                  <td class="vertical-align-middle " width="20%">
                    <span class="datediv">{{message.MessageDate}}</span>

                    <span class="button" (click)="$event.stopPropagation();">
                      <i class="fa fa-reply cursor icon" *ngIf="hasMessageFeatureAccess && message.IsReplyEnabled"
                        (click)="replyMessage(message.MessageQueueId)" title="Reply"></i>&nbsp;
                      <i class="fa fa-reply-all cursor icon" *ngIf="hasMessageFeatureAccess && message.IsReplyEnabled"
                        (click)="openReplyToAllMessage(message.MessageQueueId)" title="Reply All"></i>&nbsp;
                      <i class="fa fa-trash cursor icon" (click)="deleteMessageByMessageRecipientId(message)"
                        title="Delete"></i>&nbsp;
                    </span>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
          <div *ngIf="showSpinner" class="main-section load-icon msg-noreport-text">
            <div title="Loading..." class="icon"><i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </div>
          <div *ngIf="this.messages.length <= 0 && !showSpinner">
            <div class="text-center" id="noMessage">
              <div class="msg-noreport-text">Currently no messages to view. </div>
            </div>
          </div>

        </div>
      </tab>
    </ng-container>
    <ng-container *ngIf="hasMessageFeatureAccess">
      <tab>
        <ng-template tabHeading>
          <i class="fa fa-paper-plane" aria-hidden="true"></i> Sent
        </ng-template>
        <div class="card-block">
          <div class="main-section" *ngIf="this.sentMessages.length > 0 && !showSentTabSpinner">
            <div class="d-block d-sm-inline-block col-lg-3 col-md-4 col-sm-6 col-xs-12 no-padding">
              <input placeholder="Search" class="form-control" [(ngModel)]="searchText" (ngModelChange)="onSearchTextChanged()">
            </div>
            <span class="pull-right no-padding">
              <button type="button" class="btn btn-danger mrm" (click)="deleteSentMessages()"
                *ngIf="selectedUserSentMessages.length > 0" title="Delete">
                <i class="fas fa-trash-alt"></i> Delete
              </button>
            </span>
          </div>

          <div class="table-scroll">
            <table width="100%"
              class="table  ignore-table-limits table-bordered table-hover reports_background reportSmallFont"
              border="1" *ngIf="this.sentMessages.length > 0 && !showSentTabSpinner">
              <tbody>
                <th></th>
                <th>To</th>
                <th>Subject</th>
                <th (click)="onSortSent()" style="cursor: pointer;" title="Click to Sort">Sent<i *ngIf="sortDirectionSent === 'asc'" class="fa fa-sort-asc ng-star-inserted"></i><i *ngIf="sortDirectionSent === 'desc'" class="fa fa-sort-desc ng-star-inserted"></i></th>
                <tr *ngFor="let sentMessage of this.sentMessages|sentMessageFilter:this.searchText"
                  (click)="openMessage(sentMessage.MessageQueueId, sentMessage.MessageRecipientId, false)" class="old-message cursor">
                  <td class="col-content-similar cursor-auto vertical-align-middle" (click)="$event.stopPropagation();"
                    width="3%">
                    <input type="checkbox" class="checkbox" value="{{sentMessage.MessageQueueId}}"
                      [checked]="isCheckedSent(sentMessage.MessageQueueId)"
                      (change)="onSentMessageSelectionChange(sentMessage, $event.target.checked)" #checkboxes />
                  </td>

                  <td class="vertical-align-middle" width="15%" title={{sentMessage.ToUserNamesDisplay}}>
                    <i class="fa" [ngClass]="getReadStatusClass(sentMessage)" [title]="getReadByNames(sentMessage)"></i>
                    <span *ngFor = "let recpient of sentMessage.MessageRecipients" [ngClass]="{ 'read-message-format' :recpient.DateRead, 'not-read-message-format': !recpient.DateRead}">
                      {{recpient.Name}} 
                    </span>
                  </td>
                  <td width="35%">
                    <div class="vertical-align-middle">
                      <div class="message-subject-format">{{sentMessage.Subject | striphtml}}</div>
                      <div class="message-format reportSmallFont message">{{sentMessage.Message | striphtml}}</div>
                    </div>
                  </td>
                  <td class="vertical-align-middle " width="20%">
                    <span class="datediv">{{sentMessage.DateCreatedFormatted}}</span>

                    <span class="button" (click)="$event.stopPropagation();">
                      <i class="fa fa-trash cursor icon" (click)="deleteSentMessageByMessageQueueId(sentMessage)"
                        title="Delete"></i>&nbsp;
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="showSentTabSpinner" class="main-section load-icon msg-noreport-text">
            <div title="Loading..." class="icon"><i class="fa fa-circle-notch fa-spin fa-2x"></i>
            </div>
          </div>
          <div *ngIf="this.sentMessages.length <= 0 && !showSentTabSpinner">
            <div class="text-center" id="noMessage">
              <div class="msg-noreport-text">Currently no sent messages to view. </div>
            </div>
          </div>
        </div>
      </tab>
    </ng-container>
  </tabset>
</mat-expansion-panel>


<rtms-message-detail *ngIf="hasMessageFeatureAccess" #messageDetailComponent (reloadMessage)="hideRow(userMessages)" (reloadSentMessage)="hideRowSent(userMessages)"></rtms-message-detail>
<rtms-compose-message *ngIf="hasMessageFeatureAccess" (sentMessage)="onMessageSent($event)" #composeMessageComponent></rtms-compose-message>
<confirm *ngIf="hasMessageFeatureAccess" (onConfirmAcknowledge)="onConfirmDeleteSent($event)" [body]="confirmDeleteBody" [title]="'Sent'" #confirmDeleteSent></confirm>
<confirm (onConfirmAcknowledge)="onConfirmDelete($event)" [body]="confirmDeleteBody" [title]="'Inbox'" #confirmDelete></confirm>

import { Injectable } from '@angular/core';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { FiltersService } from '../../filter/store/services/filters.service';
import { Filters } from '../enums/filters';
import { UIEventTypes } from '../enums/ui-event-types';
import { FilterSettings } from '../models/filter-settings';
import { OrganizationModel } from '../models/organization.model';
import { PortalUIEvent } from '../models/portal-ui-event';
import { UINotifierService } from './public/uinotifier.service';
import * as moment from 'moment';
import { list } from '../utility/list';
import { StateService } from '@uirouter/core';

@Injectable()
export class FilterStateService {

    constructor(private filterService: FiltersService,
        private listsStateService: ListsStateService,
        private uiNotifierService: UINotifierService,
        private userStateService: UserStateService,
        private stateService: StateService
    ) { }

    setFilter(filter, publishEvent?: boolean) {
        this.filterService.filterSettings.set(filter);
        if(publishEvent){
            const filterUpdatePortalUIEvent = {
                type: UIEventTypes.FilterUpdate
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(filterUpdatePortalUIEvent);
        }
    }

    public getFilterSettings(isQM?: boolean, isResidentDashboard?: boolean): FilterSettings {
        let filterSettings = this.getFilter();
        if (!filterSettings) {
            filterSettings = {
                StartDate: moment(new Date().setHours(0, 0, 0, 0)).toDate(),
                EndDate: moment(new Date().setHours(0, 0, 0, 0)).toDate(),
                UserTimeZoneId: this.userStateService.getLoggedInUser().TimeZoneId
            };
            if (isQM) {
                filterSettings.EndDate = moment().seconds(0).milliseconds(0);
                filterSettings.StartDate = moment().seconds(0).milliseconds(0).subtract(7, 'days');
            }
        } else {
            filterSettings = {
                StartDate: filterSettings.StartDate,
                EndDate: filterSettings.EndDate,
                UserStatuses: filterSettings.UserStatuses,
                Units: this.getSelectedUnitIds(),
                FilterType: null,
                FilterValue: null,
                UserTimeZoneId: this.userStateService.getLoggedInUser().TimeZoneId,
                Payers: this.getSelectedPayersIds(),
                MedClasses: this.filterService.getMedClasses(),
                ResMRN: (isResidentDashboard === undefined || isResidentDashboard) ?
                    filterSettings.ResMRN : '',
                DataFilters: [],
                Denominator: false,
                DateRange: filterSettings.DateRange,
                QMTypeIDs: this.getSelectedQMTypeIds(),
                RangeFilter: filterSettings.RangeFilter,
                Categories: this.filterService.getCategoryTagIdCSV(),
                ShortStay: filterSettings.ShortStay,
                OnlyDischargedResidents: filterSettings.OnlyDischargedResidents,
                Type: this.filterService.getTypeIdCSV(),
                VaccineTypes: this.filterService.getVaccineTypes()
            };
        }

        filterSettings.OrganizationId = this.getSelectedOrganization().OrganizationId;
        this.setFilter(filterSettings);
        return filterSettings;
    }

    getFilter() {
        return this.filterService.filterSettings.get();
    }

    getCurrentFilter() {
        const filterSettings = this.getFilter();
        filterSettings.FilterType = null;
        filterSettings.FilterValue = null;
        filterSettings.DetailInfo = null;
        filterSettings.DataFilters = [];

        this.setFilter(filterSettings);
        return { ...this.filterService.filterSettings.get() };
    }

    public getSelectedOrganization(): OrganizationModel {
        return this.filterService.organizations.getFirstOrDefault();
    }

    public getSelectedUnits(): string {
        let unitCSV = '';
        if (this.getFilter() && this.getFilter().Units) {
            const unitsIdArray = list(this.getFilter().Units.split(',').map(Number));
            const unitLists = list(this.listsStateService.getUnits()).Where(a => unitsIdArray.Any(t => t === a.FacilityUnitId)).ToArray();
            unitCSV = list(unitLists).Select(a => a.UnitName).ToArray().join(',');
        }
        return unitCSV;
    }

    public getSelectedMedClasses(): string {
        return this.filterService.getMedClasses()?.join(',');
    }

    public getSelectedDiagnosisTypes(): string {
      return this.filterService.getDiagnosisTypes()?.join(',');
    }

    public getSelectedVaccineTypes(): string {
      return this.filterService.getVaccineTypes()?.join(',');
    }

  public getSelectedUnitIds(): string {
        return this.filterService.getUnitIdCSV();
    }

    public getSelectedPayers(): string {
        let payersCSV = '';
        if (this.getFilter() && this.getFilter().Payers) {
            const payersIdArray = list(this.getFilter().Payers.split(',').map(Number));
            const payerLists = list(this.listsStateService.getPayers()).Where(a => payersIdArray.
                Any(t => t === a.OrigPayerId)).ToArray();
            payersCSV = list(payerLists).Select(a => a.PayerName).ToArray().join(',');
        }
        return payersCSV;
    }

    public getSelectedPayersIds(): string {
        return this.filterService.getPayersIdCSV();
    }

    public getSelectedQMTypes(): string {
        let qmTypesCSV = '';
        if (this.getFilter() && this.getFilter().QMTypeIDs) {
            const qmTypesIdArray = list(this.getFilter().QMTypeIDs.split(',').map(Number));
            const qmLists = list(this.listsStateService.getQMs()).Where(a => qmTypesIdArray.
                Any(t => t === a.QMTypeId)).ToArray();
            qmTypesCSV = list(qmLists).Select(a => a.QMTypeDesc).ToArray().join(',');
        }
        return qmTypesCSV;
    }

    public getSelectedQMTypeIds(): string {
        return this.filterService.getQmTypeIdCSV();
    }

    public getSelectedCategories(): string {
        let categoriesNameCSV = '';
        if (this.getFilter() && this.getFilter().Categories) {
            const categoriesIdArray = list(this.getFilter().Categories.split(','));
            const categoryLists = list(this.listsStateService.getCategories()).Where(a => categoriesIdArray.
                Any(t => t === a.TagId)).ToArray();
            categoriesNameCSV = list(categoryLists).Select(a => a.TagName).ToArray().join(',');
        }
        return categoriesNameCSV;
    }

    public getDischargeOnly(): boolean {
        return this.getFilter() && this.getFilter().OnlyDischargedResidents;
    }

    public getSelectedTypes(): string {
        let typesCSV = '';
        if (this.getFilter() && this.getFilter().Type) {
            const typesIdArray = list(this.getFilter().Type.split(','));
            const typeLists = list(this.listsStateService.getTypes()).Where(a => typesIdArray.
                Any(t => t === a.id)).ToArray();
            typesCSV = list(typeLists).Select(a => a.label).ToArray().join(',');
        }
        return typesCSV;
    }

    public getHSFromFilterOrDefault(): OrganizationModel {
        if (!this.filterService.selectedHsOrganization.get()) {
            return this.listsStateService.getUserHSOrganization()[0];
        } else {
            return this.filterService.selectedHsOrganization.get();
        }
    }

    public getHHFromFilterOrDefault(): OrganizationModel {
        if (!this.filterService.selectedHhOrganization.get()) {
            return this.listsStateService.getUserHHOrganization()[0];
        } else {
            return this.filterService.selectedHhOrganization.get();
        }
    }

    public getHHFacilityFromFilterOrDefault(): OrganizationModel {
        if (!this.filterService.selectedHhFacility.get()) {
            return this.listsStateService.getUserHHFacility()[0];
        } else {
            return this.filterService.selectedHhFacility.get();
        }
    }

    public getEnterpriseFromFilterOrDefault(): OrganizationModel {
        if (!this.filterService.selectedEnterpriseOrganization.get()) {
            const enterpriseOrgs = 
                (this.stateService.current.name === 'home.enterpriseMedicalDirectorDashboard') ?
                this.listsStateService.getUserOrganizations().filter(x => x.OrganizationType === 2 || x.OrganizationType === 3) :
                this.listsStateService.getUserOrganizations().filter(x => x.OrganizationType === 2 || x.OrganizationType === 3 || x.OrganizationType === 6);
            if (enterpriseOrgs.length > 0) { return enterpriseOrgs[0]; } else { return new OrganizationModel(); }
        } else {
            return this.filterService.selectedEnterpriseOrganization.get();
        }
    }

    getSelectedOrganizationId(): any {
        if (this.filterService.isEnterpriseDashboard.get() === true) {
            return this.getEnterpriseFromFilterOrDefault().OrganizationId;
        } else if (this.filterService.isHSDashboard.get() === true) {
            return this.getHSFromFilterOrDefault().OrganizationId;
        } else if (this.filterService.isHHDashboard.get() === true) {
            return this.getHHFromFilterOrDefault().OrganizationId;
        } else if (this.filterService.isHHProactDashboard.get() === true) {
            return this.getHHFacilityFromFilterOrDefault().OrganizationId;
        } else {
            return this.getSelectedOrganization().OrganizationId;
        }
    }

    clearFilterState() {
        this.filterService.filterSettings.clear();
    }

    public removeSelectedType(): void {
        const filterSettings = this.getFilter();
        filterSettings.Type = null;
        this.setFilter(filterSettings);
        const portalUIEvent = {
            type: UIEventTypes.ClearFilters,
            value: {
                type: Filters.Types
            }
        } as PortalUIEvent;
        this.uiNotifierService.publishEvents(portalUIEvent);
    }

    public removeSelectedPayers(): void {
        const filterSettings = this.getFilter();
        if (filterSettings) {
            filterSettings.Payers = null;
            this.setFilter(filterSettings);
            const portalUIEvent = {
                type: UIEventTypes.ClearFilters,
                value: {
                    type: Filters.Payers
                }
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(portalUIEvent);
        }
    }

    public removeSelectedUnits(): void {
        const filterSettings = this.getFilter();
        if (filterSettings) {
            filterSettings.Units = null;
            this.setFilter(filterSettings);
            const portalUIEvent = {
                type: UIEventTypes.ClearFilters,
                value: {
                    type: Filters.Units
                }
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(portalUIEvent);
        }
    }

    public removeSelectedMedClasses():void{
        const filterSettings = this.getFilter();
        if(filterSettings)
        {
            filterSettings.MedClasses = null;
            this.setFilter(filterSettings);
            const portalUIEvent = {
                type: UIEventTypes.ClearFilters,
                value:{
                    type: Filters.MedClasses
                }
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(portalUIEvent);
        }
    }

    public removeSelectedDiagnosisTypes():void{
      const filterSettings = this.getFilter();
      if(filterSettings)
      {
          filterSettings.DiagnosisTypes = null;
          this.setFilter(filterSettings);
          const portalUIEvent = {
              type: UIEventTypes.ClearFilters,
              value:{
                  type: Filters.DiagnosisTypes
              }
          } as PortalUIEvent;
          this.uiNotifierService.publishEvents(portalUIEvent);
      }
  }

  public removeSelectedVaccineTypes(): void{
    const filterSettings = this.getFilter();
    if (filterSettings) {
      filterSettings.VaccineTypes = null;
      this.setFilter(filterSettings);
      const portalUdEvent = {
        type: UIEventTypes.ClearFilters,
        value: {
          type: Filters.VaccineTypes
        }
      } as PortalUIEvent;
      this.uiNotifierService.publishEvents(portalUdEvent);
    }    
  }

  public removeSelectedQMTypes(): void {
        const filterSettings = this.getFilter();
        filterSettings.QMTypeIDs = null;
        this.setFilter(filterSettings);
        const portalUIEvent = {
            type: UIEventTypes.ClearFilters,
            value: {
                type: Filters.QmTypes
            }
        } as PortalUIEvent;
        this.uiNotifierService.publishEvents(portalUIEvent);
    }

    public removeSelectedCategories(): void {
        const filterSettings = this.getFilter();
        filterSettings.Categories = null;
        this.setFilter(filterSettings);
        const portalUIEvent = {
            type: UIEventTypes.ClearFilters,
            value: {
                type: Filters.Categories
            }
        } as PortalUIEvent;
        this.uiNotifierService.publishEvents(portalUIEvent);
    }

    public removeDischargeOnly(): void {
        const filterSettings = this.getFilter();
        if (filterSettings) {
            filterSettings.OnlyDischargedResidents = false;
            this.setFilter(filterSettings);
            const portalUIEvent = {
                type: UIEventTypes.ClearFilters,
                value: {
                    type: Filters.OnlyDischargedResidents
                }
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(portalUIEvent);
        }
    }

    public removeSelectedDateRange(): void {
        const filterSettings = this.getFilter();
        if (filterSettings) {
            filterSettings.DateRange = undefined;
            this.setFilter(filterSettings);
            const portalUIEvent = {
                type: UIEventTypes.ClearFilters,
                value: {
                    type: Filters.DateRange
                }
            } as PortalUIEvent;
            this.uiNotifierService.publishEvents(portalUIEvent);
        }
    }
    public getSelectedOrDefaultDateRange() {
        const dateRanges = this.listsStateService.getFilterOptions().DateRanges;
        return this.getFilter().DateRange ?
            this.getFilter().DateRange : dateRanges[0].FilterOptionId;
    }
}

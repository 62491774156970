import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/shared/services/services-index';
import { UserMessageModel } from 'src/app/shared/models/user-message.model';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class InboxService {
    constructor(
        private _http: HttpClient,
        private envService:EnvService
    ) { }

    getScheduledReports(): Promise<any> {
        return this._http.get<any>(this.envService.api + 'reports/getreportexecutions')
            .toPromise()
            .then(data => data.Items);
    }
    getMessages(): Promise<UserMessageModel[]> {
        return this._http.get<UserMessageModel[]>(this.envService.api + 'messaging/inbox').toPromise();
    }
    getSentMessages(): Promise<UserMessageModel[]> {
      return this._http.get<UserMessageModel[]>(this.envService.api + 'messaging/sent').toPromise();
  }
  deleteMessages(messageRecipientIds: number[]): Promise<any> {
        var recipientsModel = {'RecipientIds': messageRecipientIds}
        return this._http.post<any>(this.envService.api + 'messaging/delete', recipientsModel).toPromise();
    }
    getUserMessage(messageRecipient: any): Promise<any> {
        const req = { 'intRequest': messageRecipient };
        return this._http.post<any>(this.envService.api + 'messaging/trails', req).toPromise();
    }
    saveMessages(userMessage: UserMessageModel): Promise<any> {
        return this._http.post<any>(this.envService.api + 'messaging', userMessage).toPromise();
    }
    readUserMessage(messageQueueId: any): Promise<any> {
        const req = { 'intRequest': messageQueueId };
        return this._http.post<any>(this.envService.api + 'messaging/read', req).toPromise();
    }
    getMessageNotification(): Promise<any> {
        return this._http.get<any>(this.envService.api + 'messaging/message-notification').toPromise();
    }
    getUsersByName(SearchText: any, organizationId: number): Promise<any> {
        const req = { 'SearchText': SearchText, 'OrganizationId': organizationId };
        return this._http.post<any>(this.envService.api + 'messaging/users', req).toPromise().then(data => data.Items);
    }

    async deleteSentMessages(messageQueueIds: number[]): Promise<any> {
        var recipientsModel = {'MessageQueueIds': messageQueueIds};
        return await firstValueFrom(this._http.post<any>(this.envService.api + 'messaging/delete-from-sent', recipientsModel));
    }
}

import { Directive, ElementRef, Input, OnInit } from '@angular/core';
    
@Directive({
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnInit {

  @Input() autofocusSelectValue = false;

  constructor(
    private elRef: ElementRef<HTMLInputElement>
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.autofocusSelectValue) {
        this.elRef.nativeElement.focus();
      }
    });
  }
}
<!--BEGIN SIDEBAR MENU-->
<nav id="sidebar" role="navigation" class="navbar navbar-light bg-light navbar-static-side"
    style="border-radius: 0px !important; align-items: start"
    [ngClass]="_showSideBar?'pWSideBarHeight':''" *ngIf="_showSideBar">
  <div style="width:100%">
    <a id="menu-toggle" class="d-none d-md-flex" (click)="toggleCollapse()"
       [attr.title]="! _isSidebarExpanded?'Click to Expand':'Click to Collapse Menu'">
      <i class="fa" [ngClass]="(!_isSidebarExpanded)?'fa-chevron-right':'fa-chevron-left'"></i>
    </a>
    <div id="sidebar-collapse"
         class="sidebar-collapse menu-scroll no-select collapse"
         *ngIf="! _hideSideBarMenu">
        <ul id="side-menu" class="navbar sidebar-navigation-menu">
            <li class="user-panel">
                <div class="info" style="width:100%;">
                    <div class="user-name">{{_userName}}</div>
                    <div class="sidebar-icons">
                        <div id="userSettings"><a class="user-panel-icon no-padding"
                                [ngClass]="toggleHighlightedLink('home.userSettings')"
                                (click)="navigate('home.userSettings')" data-hover="tooltip" title="My Settings"><i
                                    class="fa fa-cog"></i></a></div>
                        <div id="inbox" *ngIf="_inboxEnabled">
                            <a class="user-panel-icon no-padding" [ngClass]="toggleHighlightedLink('home.inbox')"
                                (click)="navigate('home.inbox')" data-hover="tooltip" title="Mail"><i
                                    class="fa fa-envelope"></i><span *ngIf="this.isMessageFeatureAccess" class="badge message-notification-badge">{{this._messageNotification}}</span></a>
                        </div>
                        <div id="userManagement" *ngIf="_portalUserAdminEnabled"><a class="user-panel-icon no-padding"
                                [ngClass]="toggleHighlightedLink('home.userManagement')"
                                (click)="navigate('home.userManagement')" data-hover="tooltip"
                                title="User Management"><i class="fa fa-user-plus"></i></a></div>
                        <div id="switchOrgListItem" *ngIf="_showSwitchOrgIcon"><a class="user-panel-icon no-padding"
                                (click)="showSwitchOrgModal()" data-hover="tooltip" title="Switch Organization"><i
                                    class="fa fa-users"></i></a></div>
                        <div id="tagResident"  *ngIf="(_residentTaggingEnabled && _showTagResidentIcon)"><a class="user-panel-icon no-padding"
                                        [ngClass]="toggleHighlightedLink('home.tagResident')"
                                        (click)="navigate('home.tagResident')"  data-hover="tooltip"
                                        title="Tag Resident"><i class="fa fa-tags"></i></a></div>
                        <div id="logout"><a class="user-panel-icon no-padding" (click)="navigate('home.logout')"
                                data-hover="tooltip" title="Logout"><i class="fa fa-sign-out"></i></a></div>
                    </div>
                </div>
            </li>
            <div class="panel-group-wrapper">
                <div class="panel-group no-margin-bottom">
                    <div class="panel panel-default sidebar-panel" style="border-radius: 0px !important;">
                        <div id="dashboards" class="panel-heading-menu sidebar-panel">
                            <ul class="navbar nav-second-level">
                                <li href="#dashboard"
                                    class="nav-item"
                                    (click)="toggleMenuIcon('Dashboards')"
                                    aria-expanded="true">
                                    <a class="nav-link"><i class="fa fa-tachometer fa-fw"></i><span
                                            class="menu-title">Dashboards</span><span class="fa pull-right"
                                            [ngClass]="_collapseDashboard?'fa-angle-left':'fa-angle-down'"></span></a>
                                </li>
                            </ul>
                        </div>
                        <div id="dashboard" [ngbCollapse]="_collapseDashboard">
                            <ul class="navbar nav-second-level list-group">
                                <li id="medical_director_dashboard" class="nav-item list-group-item-action" *ngIf="_medicalDirectorDashboardEnabled">
                                  <a
                                    class="nav-link"
                                    [ngClass]="toggleHighlightedLink('home.medicalDirectorDashboard')"
                                    (click)="navigate('home.medicalDirectorDashboard')"><i class="fa fa-user-md"></i><span
                                        class="submenu-title">Medical Director</span></a>
                                </li>
                                <li id="enterprise_medical_director_dashboard" class="list-group-item-action"
                                    *ngIf="_enterpriseMedicalDirectorDashboardEnabled">
                                    <a [ngClass]="toggleHighlightedLink('home.enterpriseMedicalDirectorDashboard')"
                                        class="nav-link"
                                        (click)="navigate('home.enterpriseMedicalDirectorDashboard')">
                                        <i class="fa fa-user-md"></i>
                                        <span class="submenu-title">MD - Enterprise</span>
                                    </a>
                                </li>
                                <li id="pdpm_dashboard" class="nav-item list-group-item-action" *ngIf="_pdpmDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.pdpm')"
                                        (click)="navigate('home.pdpm')"><i class="fas fa-procedures"></i><span
                                            class="submenu-title">PDPM</span></a></li>
                                <li id="enterprise_dashboard" class="list-group-item-action"
                                    *ngIf="_enterpriseDashboardEnabled" href="#enterpriseDashboard">
                                    <a (click)="toggleMenuIcon('enterpriseDashboards')" class="nav-link">
                                        <i class="icomoon icon-enterprise"></i>
                                        <span class="submenu-title" style="width: 100%">Enterprise</span>
                                        <span class="fa pull-right"
                                            [ngClass]="_collapseEnterpriseDashboards?'fa-angle-left':'fa-angle-down'"></span>
                                    </a>
                                </li>

                                <div id="enterpriseDashboard"
                                    [ngbCollapse]="_collapseEnterpriseDashboards">
                                    <ul class="navbar nav-pills nav-third-level list-group">
                                        <li id="enterprise_financial_dashboard" class="list-group-item-action"
                                            *ngIf=" _enterpriseDashboardEnabled && _enterpriseFinancialDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.enterpriseFinancialDashboard') "
                                                (click)="navigate('home.enterpriseFinancialDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fa fa-pie-chart"></i>
                                                <span class="submenu-title">Financial</span>
                                            </a>
                                        </li>
                                        <li id="enterprise_clinical_dashboard" class="list-group-item-action"
                                            *ngIf=" _enterpriseDashboardEnabled && _enterpriseClinicalDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.enterpriseClinicalDashboard')"
                                                (click)="navigate('home.enterpriseClinicalDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fa fa-heartbeat"></i>
                                                <span class="submenu-title">Clinical</span>
                                            </a>
                                        </li>
                                        <li id="enterprise_proact_dashboard" class="list-group-item-action"
                                            *ngIf=" _enterpriseDashboardEnabled && _enterpriseProACTDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.enterpriseProactDashboard')"
                                                (click)="navigate('home.enterpriseProactDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fa fa-refresh"></i>
                                                <span class="submenu-title">PROACT</span>
                                            </a>
                                        </li>
                                        <li id="enterprise_pdpm_dashboard" class="list-group-item-action"
                                            *ngIf=" _enterpriseDashboardEnabled && _enterprisePDPMDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.enterprisePDPMDashboard')"
                                                (click)="navigate('home.enterprisePDPMDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fas fa-procedures"></i>
                                                <span class="submenu-title">PDPM</span>
                                            </a>
                                        </li>
                                        <li id="enterprise_infection_control_dashboard" class="list-group-item-action"
                                            *ngIf=" _enterpriseDashboardEnabled && _enterpriseInfectionControlDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.enterpriseInfectionControlDashboard')"
                                                (click)="navigate('home.enterpriseInfectionControlDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fas fa-shield-virus"></i>
                                                <span class="submenu-title">Infection Control</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <li id="home-health_dashboard" class="list-group-item-action"
                                    *ngIf="_homeHealthDashboardEnabled" href="#homeHealthDashboard">
                                    <a (click)="toggleMenuIcon('homeHealthDashboards')" class="nav-link">
                                        <i class="icomoon icon-enterprise"></i>
                                        <span class="submenu-title">Home Health</span>
                                        <span class="fa pull-right"
                                            [ngClass]="_collapseHomeHealthDashboards?'fa-angle-left':'fa-angle-down'"></span>
                                    </a>
                                </li>

                                <div id="homeHealthDashboard"
                                    [ngbCollapse]="_collapseHomeHealthDashboards">
                                    <ul class="navbar nav-third-level">
                                        <li id="home_health_proact_hs_dashboard" class="list-group-item-action"
                                            *ngIf="_homeHealthDashboardEnabled && _homeHealthProactHSDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.homeHealthProactHSDashboard') "
                                                (click)="navigate('home.homeHealthProactHSDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fa fa-hospital-o"></i>
                                                <span class="submenu-title">PROACT HH</span>
                                            </a>
                                        </li>
                                        <li id="home_health_proact_dashboard" class="list-group-item-action"
                                            *ngIf="_homeHealthDashboardEnabled && _homeHealthProactDashboardEnabled">
                                            <a [ngClass]="toggleHighlightedLink('home.homeHealthProactDashboard') "
                                                (click)="navigate('home.homeHealthProactDashboard')"
                                                class="enterprise-sub-menu nav-link">
                                                <i class="fa fa-refresh"></i>
                                                <span class="submenu-title">PROACT</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                                <li id="resident_dashboard" class="list-group-item-action" *ngIf="_residentDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.residentDashboard')"
                                        (click)="navigate('home.residentDashboard')"><i
                                            class="fas fa-laptop-medical"></i><span
                                            class="submenu-title">Resident</span></a></li>
                                <li id="financial_dashboard" class="list-group-item-action" *ngIf="_financialDashboardEnabled">
                                    <a [ngClass]="toggleHighlightedLink('home.financial')"
                                       class="nav-link"
                                        (click)="navigate('home.financial')"><i class="fa fa-pie-chart"></i><span
                                            class="submenu-title">Financial</span></a>
                                </li>
                                <li id="clinical_dashboard" class="list-group-item-action" *ngIf="_clinicalDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.clinical')"
                                        (click)="navigate('home.clinical')"><i class="fa fa-heartbeat"></i><span
                                            class="submenu-title">Clinical</span></a></li>
                                <li id="genericDashboard_dashboard" class="list-group-item-action"
                                    *ngIf="_realTimeLiteEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.genericDashboard')"
                                        (click)="navigate('home.genericDashboard',6)"><i class="fa fa-bolt"></i><span
                                            class="submenu-title">Real Time Lite</span></a></li>
                                <li id="rehospitalization_dashboard" class="list-group-item-action" *ngIf="_rehospEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.rehospitalization')"
                                        (click)="navigate('home.rehospitalization')"><i class="fa fa-refresh"></i><span
                                            class="submenu-title">PROACT</span></a></li>
                                <li id="hs_dashboard" class="list-group-item-action" *ngIf="_healthSystemEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.hs')" (click)="navigate('home.hs')"><i
                                            class="fa fa-hospital-o"></i><span class="submenu-title">PROACT
                                            HS</span></a></li>
                                <li id="hospitalDashboard_dashboard" class="list-group-item-action"
                                    *ngIf="_hospitalDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.hospitalDashboard')"
                                        (click)="navigate('home.hospitalDashboard')"><i class="fa fa-h-square"></i><span
                                            class="submenu-title">PROACT CM</span></a></li>
                                <li id="infectionControl_dashboard" class="list-group-item-action"
                                    *ngIf="_infectionControlDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.infectionControl')"
                                        (click)="navigate('home.infectionControl')"><i
                                            class="fas fa-shield-virus"></i><span class="submenu-title">Infection
                                            Control</span></a></li>
                                <li id="enterprise_diseasewatch_dashboard" class="list-group-item-action"
                                    *ngIf=" _enterpriseDashboardEnabled && _enterpriseDiseaseWatchDashboardEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.enterpriseDiseaseWatchDashboard')"
                                        (click)="navigate('home.enterpriseDiseaseWatchDashboard')"><i
                                            class="fa  fa-map-marker"></i><span class="submenu-title">DiseaseWatch
                                        </span></a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </div>

                <div class="panel-group no-margin-bottom">
                    <div id="admin_reports" class="panel panel-default sidebar-panel" *ngIf="_adminReportsEnabled"
                        style="border-radius: 0px !important">
                        <div class="panel-heading-menu sidebar-panel">
                            <ul class="navbar nav-second-level">
                                <li href="#reports" (click)="toggleMenuIcon('adminReports')">
                                    <a class="nav-link"><i class="fa fa-files-o fa-fw"></i><span class="menu-title">Admin
                                            Reports</span><span class="fa pull-right"
                                            [ngClass]="_collapseReports?'fa-angle-left':'fa-angle-down'"></span></a>
                                </li>
                            </ul>
                        </div>
                        <div id="reports" [ngbCollapse]="_collapseReports">
                            <ul class="navbar nav-third-level">
                                <li id="facilityPortalUsageReport" class="list-group-item-action"
                                    *ngIf="_facPortalUsageReportEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.facilityPortalUsageReport')"
                                        (click)="navigate('home.facilityPortalUsageReport')"><i
                                            class="fa fa-users nav-link"></i>
                                        <span class="submenu-title">
                                          Facility Portal Usage Report
                                        </span></a></li>
                                <li id="portalUsageReport" class="list-group-item-action" *ngIf="_portalUsageReportEnabled"> <a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.portalUsageReport')"
                                        (click)="navigate('home.portalUsageReport')"><i class="fa fa-lock"></i><span
                                            class="submenu-title">Portal Usage Report</span></a></li>
                                <li id="keywordReport" class="list-group-item-action" *ngIf="_keywordReportEnabled"><a
                                        class="nav-link"
                                        [ngClass]="toggleHighlightedLink('home.keywordReport')"
                                        (click)="navigate('home.keywordReport')"><i class="fa fa-key"></i><span
                                            class="submenu-title">Keyword List Report</span></a></li>
                                <li id="utilizationScoreCard" class="list-group-item-action" *ngIf="_facUtilizationScoreCard">
                                    <a
                                      class="nav-link"
                                      [ngClass]="toggleHighlightedLink('home.utilizationScoreCard')"
                                        (click)="navigate('home.utilizationScoreCard')"><i
                                            class="fa fa-hand-o-up nav-link"></i><span class="submenu-title">Utilization Score
                                            Card</span></a>
                                </li>
                                <li id="userLoginReport" class="list-group-item-action" *ngIf="_userLoginReportEnabled">
                                    <a [ngClass]="toggleHighlightedLink('home.userLoginReport')"
                                        class="nav-link"
                                        (click)="navigate('home.userLoginReport')"><i
                                            class="fa fa-keyboard nav-link"></i><span class="submenu-title">User Login Report</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="panel-group no-margin-bottom">
                    <div id="reportSidebarLink" class="panel panel-default sidebar-panel" *ngIf="_reportsEnabled">
                        <div class="panel-heading-menu sidebar-panel reportSidebarLink">
                            <ul class="navbar nav-second-level">
                                <li>
                                    <a [ngClass]="toggleHighlightedLink('home.reportsLanding')"
                                       class="nav-link"
                                        (click)="navigate('home.reportsLanding')">
                                        <i class="fa fa-files-o fa-fw nav-link"></i><span class="menu-title">Reports</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="inboxLink" class="panel-group no-margin-bottom" *ngIf="_inboxEnabled">
                    <div class="panel panel-default sidebar-panel">
                        <div class="panel-heading-menu sidebar-panel">
                            <ul class="navbar nav-second-level">
                                <li>
                                    <a [ngClass]="toggleHighlightedLink('home.inbox')"
                                       class="nav-link"
                                       (click)="navigate('home.inbox')">
                                      <i class="fa fa-envelope"></i>
                                      <span *ngIf="this.isMessageFeatureAccess" class="badge message-notification-badge">{{this._messageNotification}}</span>
                                      <span class="menu-title">Inbox</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div id="documentationLink" class="panel-group no-margin-bottom" *ngIf="_documentationEnabled">
                    <div class="panel panel-default sidebar-panel">
                        <div class="panel-heading-menu sidebar-panel">
                            <ul class="navbar nav-second-level">
                                <li>
                                    <a [ngClass]="toggleHighlightedLink('home.documentation')" class="nav-link"
                                        (click)="navigate('home.documentation')"><i class="fa fa-file"></i><span
                                            class="menu-title">Documentation</span></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="panel-group no-margin-bottom">
                    <div class="footer">
                        <div class="footer-content reportSmallFont">Version <span [innerHTML]="_version"></span></div>
                        <div class="footer-content reportSmallFont" *ngIf="_isSidebarExpanded">
                            <a href="mailto:help@realtimemed.com">help@realtimemed.com</a><br>
                            888.546.9786<br>
                            &copy;{{_currentYear}} Real Time Medical Systems LLC<br>
                        </div>
                        <div class="footer-content reportSmallFont"><a target="new"
                                href="https://realtimemed.com/terms-of-use/">Terms of Use</a></div>
                    </div>
                </div>
            </div>
        </ul>
    </div>
  </div>
</nav>
<!--END SIDEBAR MENU-->

<user-organization-edit #userOrgEditModal>
</user-organization-edit>

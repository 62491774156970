<ng-template #template>
  <div>
    <div class="modal-header justify-content-between">
      <span class="modal-title" [innerHTML]="this.title"></span>
      <span class="close-btn" (click)="closeModal()">
        <i class="fa fa-window-close" title="Close"></i>
      </span>
    </div>
    <div class = "modal-body">
      <div class="row" *ngFor="let h of this.hippsData">
        <div class="col-lg-2" [innerHTML]="h.hipps"></div>
        <div class="col-lg-10" [innerHTML]="h.descr"></div>
      </div>
  </div>
  </div>
</ng-template>

<div class="chart-container-wrapper" *ngIf = "options">
   <highcharts-chart id="map-container" class="chart-container map-chart"
      style="width: 100%;display: block;"
      [ngStyle]="{'height': getHeight()}"
      [Highcharts]="Highcharts"
      [constructorType]="chartConstructor"
      [options]="options"
      [runOutsideAngular]="runOutsideAngular"
      [callbackFunction]="getChartInstance.bind(this)">
   </highcharts-chart>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { CardFilterStateService } from '../../services/card-filter-state.service';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { list } from '../../utility/list';
import * as _ from 'lodash';

@Component({
  selector: 'rtms-card-filter',
  templateUrl: './rtms-card-filter.component.html',
  styleUrls: ['./rtms-card-filter.component.scss']
})
export class RtmsCardFilterComponent implements OnInit {

  @Input() reportId: number;
  @Input() cardFilterOptions: Array<any>;

  selectedCardFilters: Array<any>;
  selectedOptions: any;

  constructor(
    private utilizationMetricsService: UtilizationMetricsService,
    private cardFilterStateService: CardFilterStateService
  ) {
  }

  ngOnInit(): void {
    if (this.reportId > 0) {
      if (this.cardFilterOptions && this.cardFilterOptions.length > 0) {
        const foundSelectedItems = this.cardFilterStateService.getCardFilterForReport(this.reportId);
        if (foundSelectedItems && foundSelectedItems.length > 0) {
          foundSelectedItems.forEach(item => {
            if (item.SelectedCardFilters) {
              if (!item.IsMultiSelect) {
                this.cardFilterOptions.find(c => c.ReportCardFilterId === item.ReportCardFilterId).SelectedCardFilters = item.SelectedCardFilters[0];
              } else {
                this.cardFilterOptions.find(c => c.ReportCardFilterId === item.ReportCardFilterId).SelectedCardFilters = item.SelectedCardFilters;
              }
            }
          });
        } else {
          this.cardFilterOptions.forEach(item => {
            let cardFilter = this.cardFilterOptions.find(c => c.ReportCardFilterId === item.ReportCardFilterId);
            if (item.CardFilterItems.find(o => o.IsDefault)) {
              if (!item.IsMultiSelect) {
                cardFilter.SelectedCardFilters = item.CardFilterItems.find(o => o.IsDefault);
              } else {
                cardFilter.SelectedCardFilters = [item.CardFilterItems.find(o => o.IsDefault)];
              }
            } else {
              if (!item.IsMultiSelect) {
                cardFilter.SelectedCardFilters = item.CardFilterItems[0];
              } else {
                cardFilter.SelectedCardFilters = [item.CardFilterItems[0]];
              }
            }
          });
          this.cardFilterStateService.setCardFilter(this.reportId, this.getSelectedCardFilters(), false);
        }
      }
    }
  }

  public changeSelectedFilterOption(): void {
    this.cardFilterOptions.forEach(cardFilter => {
      if (cardFilter.IsMultiSelect && cardFilter.SelectedCardFilters.length === 0) {
        if (cardFilter.CardFilterItems.find(o => o.IsDefault)) {
          cardFilter.SelectedCardFilters = [cardFilter.CardFilterItems.find(o => o.IsDefault)];
        } else {
          cardFilter.SelectedCardFilters = [cardFilter.CardFilterItems[0]];
        }
      }
    });
    const selectedCardFilters = this.getSelectedCardFilters();
    this.utilizationMetricsService.recordCardFilter(this.reportId, selectedCardFilters);
    this.cardFilterStateService.setCardFilter(this.reportId, selectedCardFilters, true);
  }

  getSelectedCardFilters() {
    const cardFilters = _.cloneDeep(this.cardFilterOptions);
    cardFilters.forEach(cardFilter => {
      if (!cardFilter.IsMultiSelect) {
        cardFilter.SelectedCardFilters = [cardFilter.SelectedCardFilters];
      }
      cardFilter.CardFilterItems = cardFilter.SelectedCardFilters;
    });
    return cardFilters;
  }
}

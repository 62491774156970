<div class="panel divlogin body-content" *ngIf="!isOlderBrowser">
  <a href="https://www.realtimemed.com" target="_blank">
    <img src="/src/assets/images/logo-rtms.png" alt="Logo" class="img-responsive" />
  </a>
  <div class="errorText text-center" *ngIf="loginFailureMessage">{{loginFailureMessage}}</div>
  <div class="text-center" *ngIf="!loginFailureMessage">You have been logged out of the Real Time portal.</div>
  <div class="text-center ptm">
    <button type="button" class="btn btn-info m-l-3" (click)="login()">Log In</button>
  </div>
</div>

<ng-template #template>
  <div class="modal-header bg-blue title chart-value titleContent">
    <div class="subject-format">{{ this.userMessages[0].Subject | striphtml }}</div>
    <i class="fa fa-close cursor fa-3x btn-format mts close-btn" (click)="closeModal()" title="Cancel"></i>
  </div>
  <div class="modal-body modal-padding-top modal-bg-white">
    <div *ngFor="let userMessage of userMessages" class="message-box message-top">
      <div class="subject">
        <span class="mrm subject-header">
          <span class="label-color btn-format">Subject: </span>
          <span class="subject-text" title={{getSubjectTooltip(userMessage)}}>
            {{ userMessage.Subject | striphtml }}
          </span>
        </span>
      </div>
      <div class="date">
        <p class="date-format label-color">{{ userMessage.DateCreatedFormatted }}</p>
      </div>
      <div class="contacts label-format">
        <div>
          <span class="label-color">From: </span>
          <span class="label-color">
            {{ userMessage.FromUser.UserId === userId ? 'You' : userMessage.FromUser.FullUserName }};
          </span>
        </div>
        <div>
          <span class="label-color">To: </span>
          <span class="label-color" title={{getTooltip(userMessage)}}>
            {{ getUser(userMessage) ? 'You' : userMessage.MessageRecipients[0].ToUser.FullUserName }} {{ getCount(userMessage) }};
          </span>
        </div>
      </div>
      <div class="message-text" [innerHTML]="userMessage.Message"></div>
    </div>
  </div>
  <div class="modal-padding-top modal-bg-white">
    Sending messages using Real Time User Messaging is the responsibility of the sender.
  </div>
  <div class="modal-footer modal-bg-white">
    <button *ngIf="IsReplyVisible" type="button" class="btn btn-info" (click)="replyMessage()" title="Reply"
            [disabled]="!IsReplyEnabled">
      <i class="fa fa-reply"></i> Reply
    </button>
    <button *ngIf="IsReplyVisible" type="button" class="btn btn-info" (click)="openReplyToAllMessage()"
            title="Reply All" [disabled]="!IsReplyEnabled">
      <i class="fa fa-reply-all"></i> Reply All
    </button>
    <button type="button" class="btn btn-danger" (click)="deleteUserMessages()" title="Delete">
      <i class="fa fa-trash"></i> Delete
    </button>
    <button type="button" class="btn btn-dark" (click)="closeModal()" title="Close">
      <i class="fa fa-times"></i> Close
    </button>
    <button *ngIf="hasReportDownload" type="button" class="btn btn-info slimScrollDiv mln" (click)="getReport()"
            title="Download Report">
      <i class="fa fa-download"></i> Download Report
    </button>
  </div>
</ng-template>
<rtms-compose-message #composeMessageComponent></rtms-compose-message>
<confirm (onConfirmAcknowledge)="onConfirmDelete($event)"
         [body]="confirmDeleteBody"
         [isSelfCloseOnly]="true"
         [title]=undefined
         #confirmDelete>
</confirm>

import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { EnvService } from '../services-index';
import { InstrumentationSoftwareRecorder } from './instrumentation-software-recorder';
import { ImportInstrumentationSoftwareRecorder } from './instrumentation-software-recorder-import';

@Injectable({ providedIn: 'root' })
export class InstrumentationSoftwareRecorderService implements OnDestroy {
  initialized: boolean = false;
  instrumentationSoftwareRecorder: InstrumentationSoftwareRecorder;
  importInstrumentationSoftwareRecorder = ImportInstrumentationSoftwareRecorder;

  private subscription = new Subscription()
  instrumentationSoftwareRecorderId: number;
  instrumentationSoftwareRecorderIdAttributes: object = {};

  constructor(
    private userStateService: UserStateService,
    private _envService: EnvService,
  ) {
    this.init();
  }

  init() {
    if (!this._envService.fullStoryEnabled) {
      return;
    }
    this.instrumentationSoftwareRecorder = this.importInstrumentationSoftwareRecorder();
    this.subscription.add(this.userStateService.getLoggedInUserStream()
      .subscribe(user => {
        if (user && this._envService.fullStoryEnabled) {
          this.instrumentationSoftwareRecorderId = user.userId;
          this.instrumentationSoftwareRecorderIdAttributes = {
            "organizationId": user.OrganizationId,
            "organizationName": user.OrganizationName,
            "staffTypeId": user.StaffTypeId,
            "staffTypeName": user.StaffTypeName,
            "displayName": user.UserName,
            "email": user.EmailId
          };
          this.instrumentationSoftwareRecorder.identify(this.instrumentationSoftwareRecorderId.toString(), this.instrumentationSoftwareRecorderIdAttributes);
          this.initialized = true;
        }
      }));
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  addEvent(name: EventName, eventTitle: string, eventData: object) {
    if (this.initialized) {
      this.instrumentationSoftwareRecorder.event(name + ': ' + eventTitle, eventData);
    }
  }
}

export type EventName = 'Error' | 'Info'

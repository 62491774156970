<div class="panel divlogin body-content">
    <div>
        <div class="mb-3 text-center">
            <a href="https://www.realtimemed.com" target="_blank">
                <img src="/src/assets/images/logo-rtms.png" alt="Logo" class="img-responsive" />
            </a>
        </div>
    </div>
    <div class="text-center">
        <i class="fa fa-spinner fa-spin fa-2x"></i>
    </div>
</div>

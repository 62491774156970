export const ReportGroups = {
    FinancialReports : 'FinancialReports',
    ClinicalReports : 'ClinicalReports',
    QMReports : 'QMReports',
    RTAReports : 'RTAReports',
    AdminReports : 'AdminReports',
    HealthSystem: 'HealthSystem',
    ResidentDashboard: 'ResidentDashboard',
    PDPMDashboard: 'PDPMDashboard',
    PDPMReports: 'PDPMReports',
    InfectionControlReports: 'InfectionControlReports',
    DiseaseWatchReports:'DiseaseWatchReports',
    HomeHealth: 'HomeHealth'
};

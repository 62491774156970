<ng-template #template>
    <div class="modal-header">
        <span class="modal-title">
            <div class="resident-info">
              <i class="fa fa-circle" [ngStyle]="{'color': this._indicatorColor}"></i>
              <span>{{_residentScoreDetail.Resident}}</span>
              <span *ngIf="_residentScoreDetail.RequiresACP==true" class="mrm"><rtms-acp-status [acpStatus] = "_residentScoreDetail.RequiresACP==true"></rtms-acp-status></span>
              <span *ngIf="setOpioidVisibility(_residentScoreDetail.OpioidScore)" class="mrm"><rtms-opioid-score [opioidScore] = "_residentScoreDetail.OpioidScore" [resident]="_residentScoreDetail.Resident" [opioidMessage] = "this.opioidMessage"></rtms-opioid-score></span>
            </div>
            <div class="reports">
                <button
                    title="Export Detail to PDF"
                    class="btn btn-info"
                    type="button"
                    (click)='this.exportResidentCARDDetail("PDF")'
                >
                    <i class="fa fa-download"></i>
                    Export Detail
                </button>
                <button
                  title="Export Detail without Interventions to PDF"
                  *ngIf="_residentScoreDetail.AlertsAndInterventions"
                  class="btn btn-info"
                  type="button"
                  (click)='this.exportResidentCARDDetail("DETAILWITHOUTINTERVENTIONSPDF")'
                >
                    <i class="fa fa-download"></i>
                    Export Basic
                </button>
            </div>
            <div class="code-status">
                <span class="inline-flex no-wrap"><b>Code Status: </b>
                    <span class="code-status-wrap" title="{{getCodeStatus()}}">{{ getCodeStatus() }}</span>
                </span>
            </div>
            <button class="btn-close" (click)='this.closeModals()'></button>
        </span>
    </div>
    <div class="modal-body">
        <tabset>
            <tab>
                <ng-template tabHeading>
                    <i class="fas fa-poll-h" aria-hidden="true"></i> CARD Details
                </ng-template>
                <div class="tab-content tab-main-content card-details-tab-content">
                    <div class="pln">
                        <div class="text-end title">
                            <span>{{this._residentScoreDetail.AlertScore}}</span>
                        </div>
                        <div class="pln">
                            <div class="pln title">
                                <span>Clinical Alerts </span>
                            </div>
                            <div>
                                <ul *ngIf="_residentScoreDetail.AlertScore > 0">
                                    <li *ngFor="let detail of _residentScoreDetail.AlertDetailsParsed">{{detail}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pln gray-background">
                        <div class="text-end title">
                            <span>{{_residentScoreDetail.LengthOfStayScore}}</span>
                        </div>
                        <div class="pln">
                            <div class="pln title">
                                <span>Admission Recency </span>
                            </div>
                            <div>
                                <ul *ngIf="_residentScoreDetail.LengthOfStayScore > 0">
                                    <li>{{_residentScoreDetail.LengthOfStayDetail}} Day<span *ngIf="_residentScoreDetail.LengthOfStayDetail > 1">s</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pln">
                        <div class="text-end title">
                            <span>{{_residentScoreDetail.AcuityScore}}</span>
                        </div>
                        <div class="pln">
                            <div class="pln title">
                                <span>Readmitted </span>
                            </div>
                            <div>
                                <ul *ngIf="_residentScoreDetail.AcuityScore > 0">
                                    <li>Last admitted: {{_residentScoreDetail.AcuityDetail}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pln gray-background">
                        <div class="text-end title">
                            <span>{{_residentScoreDetail.DiagnosisScore}}</span>
                        </div>
                        <div class="pln">
                            <div class="pln title">
                                <span>Diagnoses </span>
                            </div>
                            <div>
                                <ul *ngIf="_residentScoreDetail.DiagnosisScore > 0">
                                    <li *ngFor="let detail of _residentScoreDetail.DiagnosisDetailsParsed">{{detail}}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="pln tally" [ngStyle]="{'background-color': this._indicatorColor}">
                        <div class="text-end title">
                            <span>{{_residentScoreDetail.TotalScore}}</span>
                        </div>
                        <div class="pln">
                            <div class="pln title">
                                <span>Total Score </span>
                            </div>
                        </div>
                    </div>
                </div>
            </tab>
            <tab>
              <div *ngIf=" _residentScoreDetail.KeywordsEnabled">
                <ng-template tabHeading>
                    <i class="fas fa-key" aria-hidden="true"></i> Keywords
                </ng-template>
                <div class="tab-content tab-main-content">
                      <div *ngFor="let keyword of _residentScoreDetail.Keywords" class="keyword-item">
                          <div class="meta"><span class="keyword">{{keyword.Keyword}}</span> - noted by <span class="author">{{keyword.Initials}}</span> on <span class="date">{{keyword.EventDt}}</span>.</div>
                          <div class="phrase" [innerHTML]="keyword.KeywordPhrase"></div>
                      </div>
                      <rtms-no-data-found *ngIf="_residentScoreDetail.Keywords.length == 0" [noDataMessage]="'No current Keywords for this resident.'"  ></rtms-no-data-found>
                </div>
              </div>
            </tab>
            <tab>
                <ng-template tabHeading>
                    <i class="fas fa-tasks" aria-hidden="true"></i> Interventions
                </ng-template>
                <div class="tab-content tab-main-content interventions">
                    <div *ngIf="_residentScoreDetail.AlertsAndInterventions" [innerHTML]="_residentScoreDetail.AlertsAndInterventions"></div>
                    <rtms-no-data-found *ngIf="!_residentScoreDetail.AlertsAndInterventions" [noDataMessage]="'No current Interventions for this resident.'"></rtms-no-data-found>
                </div>
            </tab>
            <tab *ngIf="_residentScoreDetail.RequiresACP == true">
                <ng-template tabHeading>
                    <i class="fas fa-hand-holding-heart" aria-hidden="true"></i> ACP
                </ng-template>
                <div class="tab-content tab-main-content">
                    <div *ngIf="_residentScoreDetail.ACPTagDetails">
                        <div class="row-bordered">
                            <ul>
                                <li *ngFor="let acpTag of _residentScoreDetail.ACPTagDetails">
                                    {{ acpTag.Description}}
                                </li>
                            </ul>
                        </div>
                        <div class="pln tally bold" style="background-color:#f46f34;">
                            <div class="text-left title padding-left-psi-score">
                                ACP Score: {{_residentScoreDetail.PsiScore}}
                            </div>
                        </div>
                    </div>
                    <rtms-no-data-found *ngIf="!_residentScoreDetail.ACPTagDetails" [noDataMessage]="'No current ACP tag detail for this resident.'"></rtms-no-data-found>
                </div>
            </tab>
        </tabset>
    </div>
  </ng-template>

import { Component, OnInit, ViewChild } from '@angular/core';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { RtmsConstantService } from 'src/app/shared/services/rtms-constant.service';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { UserSettingService } from 'src/app/shared/services/portal/userSetting.service';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from '../login/login.service';
import { PasswordChangeComponent } from '../password-change/password-change.component';
import { OrganizationModel } from 'src/app/shared/models/models-index';
import { UserAdminService } from 'src/app/shared/services/portal/userAdmin.service';
import { LookUpDataService } from 'src/app/shared/services/portal/lookUpData.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import * as _ from 'lodash';
import { concat, firstValueFrom, Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, pairwise, switchMap, tap } from 'rxjs/operators';
import { UserSetting, ParsedUserSetting } from 'src/app/shared/models/user-setting';

@Component({
  selector: 'rtms-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {

  @ViewChild('passwordChangeComponent') passwordChangeComponent: PasswordChangeComponent;

  user = this.userStateService.getLoggedInUser();
  passwordChangeIsValid = false;
  newPasswordValue = '';
  currentPasswordValue = '';
  requireCurrentPassword = true;
  userSettingConstants;
  facility: OrganizationModel[];
  selectedFacility: OrganizationModel;
  autoCompleteMinChars = 3;
  selectedOrganization: OrganizationModel;
  organizations: Observable<OrganizationModel[]>;
  organizationLoading = false;
  organizationInput = new Subject<string>();
  isChangingPassword = false;
  receiveInboxNotifications = true;
  defaultInboxNotificationsSettingForInitialization = true;
  only30DayAdmits = false;
  has30DayAdmitFeatureAccess = false;
  hasMessageFeatureAccess = false;

  constructor(private userStateService: UserStateService,
    private rtmsConstantService: RtmsConstantService,
    private listsStateService: ListsStateService,
    private userSettingService: UserSettingService,
    private toastrService: ToastrService,
    private loginService: LoginService,
    private lookupDataService: LookUpDataService,
    private userAdminService: UserAdminService,
    private filterService: FiltersService,
    private featureService: FeatureService) { }

  async ngOnInit() {
    this.userSettingConstants = this.rtmsConstantService.settings;
    this.facility = this.listsStateService.getUserFacilities();
    this.selectedFacility = this.getSelectedFacility();
    this.receiveInboxNotifications = this.getInboxNotificationsSetting();
    this.only30DayAdmits = this.getOnly30DayAdmitsSetting();
    if(this.user.IsRealTimeUser) {
      this.selectedOrganization = this.listsStateService.getUserOrganizations().find(x => x.OrganizationId == this.user.OrganizationId);
      this.loadOrganizations();
    }
    const organizations = await firstValueFrom(this.filterService.organizations.getOrganizationFeatureStream());
    if (organizations && organizations.length > 0) {
        const facility = organizations[0];
        this.has30DayAdmitFeatureAccess = this.featureService.isFeatureEnabledForFacility(facility, "Resident.Filter.Only30DayAdmits");
        this.hasMessageFeatureAccess = this.featureService.isFeatureEnabledForFacility(facility, "Messages");
    }
  }

  getSelectedFacility(): OrganizationModel {
    const setting = this.userStateService.getSettings()[this.userSettingConstants.DefaultFacility];
    let selFacility = null;

    if (setting.settingObj.SettingValue !== null) {
      this.facility.forEach(fac => {
        if (fac.OrganizationId === parseInt(setting.settingObj.SettingValue)) {
          selFacility = fac;
        }
      });
    }

    return selFacility;
  }

  saveFacility(): void {
    const value = this.selectedFacility == null ? 0 : this.selectedFacility.OrganizationId;
    const setting = this.userStateService.getSettings()[this.userSettingConstants.DefaultFacility];
    if (setting.value !== value) {
      setting.value = value;
      setting.settingObj.SettingValue = value.toString();
      this.userSettingService.saveUserSetting(setting.settingObj).subscribe(response => {
        if (response) {
          this.toastrService.success('Changed default facility.');
        } else {
          this.toastrService.error('Unable to change default facility.');
        }
      });
    }
  }

  getOnly30DayAdmitsSetting(): boolean {
    let retVal = this.only30DayAdmits;
    const setting = this.userStateService.getSettings()[this.userSettingConstants.Only30DayAdmits];
    if (setting.settingObj.SettingValue !== null) {
      retVal = setting.value as boolean;
    }
    return retVal;
  }

  private async saveOnly30DayAdmits(): Promise<void> {
    const success = await firstValueFrom(this.userSettingService.setUserSetting(this.rtmsConstantService.settings.Only30DayAdmits, this.only30DayAdmits))
    if (success) {
      this.toastrService.success('Changed 30 Day Admission Filter.');
    } else {
      this.toastrService.error('Unable to change 30 Day Admission Filter.');
    }
  }

  getInboxNotificationsSetting(): boolean {
    let retVal = this.receiveInboxNotifications;
    const setting = this.userStateService.getSettings()[this.userSettingConstants.ReceiveInboxNotifications];
    if (setting.settingObj.SettingValue !== null) {
      retVal = setting.value as boolean;
    }
    return retVal;
  }

  private async saveInboxNotifications(): Promise<void> {
    const success = await firstValueFrom(this.userSettingService.setUserSetting(this.rtmsConstantService.settings.ReceiveInboxNotifications, this.receiveInboxNotifications))
    if (success) {
      this.toastrService.success('Changed Inbox Email Notifications.');
    } else {
      this.toastrService.error('Unable to change Inbox Email Notifications.');
    }
  }

  async submit(): Promise<any> {
    this.isChangingPassword = true;
    this.currentPasswordValue = this.passwordChangeComponent.currentPasswordValue;
    this.newPasswordValue = this.passwordChangeComponent.newPasswordValue;
    if (this.currentPasswordValue) {
      const response = await this.loginService.login(this.user.UserName, this.currentPasswordValue).toPromise();

      if (!response || !response.Item) {
        this.isChangingPassword = false;
        return;
      }

      if (response.authticket != null && response.Item.AccountStatus === 1) {
        await this.loginService.resetPW(this.newPasswordValue).toPromise();
        this.passwordChangeComponent.clearChangePassword();
        this.isChangingPassword = false;
      } else {
        this.toastrService.error('Unable to Reset Password', 'Invalid current password.');
        this.isChangingPassword = false;
      }
    } else {
      this.toastrService.error('Unable to Reset Password', 'Please specify current password.');
      this.isChangingPassword = false;
    }
  }

  public passwordValueChanged(passwordChangeIsValid): void {
    this.passwordChangeIsValid = passwordChangeIsValid;
  }

  public async canHitEnter(passwordChangeIsValid): Promise<any> {
    if (passwordChangeIsValid) {
      await this.submit();
    }
  }
  updateUserOrganization(organizationId : number):void
  {
    if(organizationId != null)
    {
      this.userAdminService.updateUserOrganizationId(this.user.userId, organizationId).subscribe(response => {
          this.toastrService.success('Changed user organization.');
          this.filterService.organizations.set([]);
          this.loginService.refreshForSwitchingOrgs();
      });
    }
  }

  private loadOrganizations() {
    this.organizations = concat(
      of([]),
      this.organizationInput.pipe(
        distinctUntilChanged(),
        tap(() => this.organizationLoading = true),
        switchMap(searchValue => searchValue && searchValue.length >= this.autoCompleteMinChars?
          this.lookupDataService.getActiveOrganizations(searchValue).pipe(
          catchError(() => of([]))
        ) : of([])), tap(() => this.organizationLoading = false)
      )
    );
  }

  trackByFn(item: OrganizationModel) {
    return item.OrganizationId;
  }
}

<div class="info">
  <div class="full-height">
      <!--BEGIN PAGE WRAPPER-->
      <div id="wrapper">
          <<div id="page-wrapper" class="page-wrapper alt-background-color">
            <!--BEGIN TITLE & BREADCRUMB PAGE-->
            <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
                <div class="page-header pull-left">
                    <div class="help-title">Welcome to Real Time!</div>
                </div>
                <div class="clearfix"></div>
            </div><p><p>
            <!--END TITLE & BREADCRUMB PAGE--><!--BEGIN CONTENT test-->
            <div class="page-content help-content">
                <div class="help-subtitle">
                    Need help logging in?
                </div>
                <div>
                    <b>1. Check your browser and version.</b><br />
                    Real Time requires Microsoft Edge, Google Chrome, or Safari.
                </div>
                <br />
                <div>
                    <b>2. Forgot your username or password?</b><br />
                    Contact your Real Time Success Manager, call (888) 546-9786, Option 1, or send an email to <a href="mailto:help@realtimemed.com">help@realtimemed.com</a> to get things resolved.
                </div>
                <br />
                <div>
                    <b>3. Is your account locked out?</b><br />
                    After so many failed login attempts, your account will be locked out for a while.  This is a security measure.  Give it an hour and try again.
                </div>
                <br />
                <div>
                    <b>4. Location-specific log in protections</b><br />
                    Your facility may have restrictions on which users can log in outside your facility.  If so, and you are unsuccessfully trying to reach Real Time, check with your Real Time Success Manager.
                </div>
                <br /><br />
                <div class="help-subtitle">
                    We are here to help!
                </div>
                <div>
                    <b>1. If you still cannot log in</b><br />
                    Please contact your Real Time Success Manager, call (888) 546-9786, Option 1, or send an email to <a href="mailto:help@realtimemed.com">help@realtimemed.com</a> to get things resolved.
                </div>
                <br />
                <div>
                    <b>2. Send us your questions</b><br />
                    Not sure what a chart indicates?  Let us know.  We’ll help describe it in detail and what it means for your facility.
                </div>
            </div>
            <div style="text-align: center">
              <h1>
                  <a class="help" href="default.htm">
                      Log In
                  </a>
              </h1>
            </div>
        </div>
      </div>
  </div>
</div>

<div>
  <div *ngIf="cardFilterOptions && cardFilterOptions.length > 0" class="cardFilter_container">
    <ng-template ngFor let-cardFilter [ngForOf]="cardFilterOptions">
      <div *ngIf="cardFilter.IsMultiSelect" class="prx">
        <ng-select #select placeholder="Select" [items]="cardFilter.CardFilterItems" [clearable]="false"
          [(ngModel)]="cardFilter.SelectedCardFilters" (close)="changeSelectedFilterOption()" class="cardFilter"
          [multiple]="true" [closeOnSelect]="false" [selectableGroup]="true" bindLabel="Name" [searchable]="false">
          <ng-template ng-optgroup-tmp let-item="item" let-item$="item$" let-index="index" >
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> Check all
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" />
            {{item.Name}}
          </ng-template>
          <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
            <div class="ng-value" *ngFor="let item of (items ? items.slice(0,1): [])">
              <span class="ng-value-label " *ngIf="items.length == 1">
                {{ (item.Name.length>13)? (item.Name | slice:0:13)+'...':(item.Name) }}
              </span>
            </div>
            <div class="ng-value" *ngIf="items.length > 1">
              <span class="ng-value-label">{{items.length}} checked</span>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="!cardFilter.IsMultiSelect">
        <ng-select [items]="cardFilter.CardFilterItems" [clearable]="false" bindLabel="Name"
          [(ngModel)]="cardFilter.SelectedCardFilters" (change)="changeSelectedFilterOption()" class="cardFilter">
        </ng-select>
      </div>
    </ng-template>
  </div>
</div>
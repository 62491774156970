<div class="panel card-panel flip-container" [ngClass]="getClass()">
  <div class="panel-heading">
    <div [ngClass]="{'clickable': isHeaderClickable}"
         class="no-padding-left no-padding-right d-flex" (click)="isHeaderClickable && onHeaderClick()">
      <ng-container [ngTemplateOutlet]="headerTemplate"></ng-container>
      <div class="no-padding-left no-padding-right rtms-card-header" [ngClass]="{'col-6':detailInfo && (cardType === 'grid' || cardType === 'pdpmWorksheet')}">
        <div class="no-padding-left no-padding-right topmargin7 flex-shrink-1" [ngClass]="getDetailInfoClass()"
          *ngIf="detailInfo && (cardType === 'grid' || cardType === 'pdpmWorksheet')">
          <div class="panel-heading-date reportTitle" [attr.title]="'Detail Date'">
            <span class="text" [ngClass]="detailInfo ? (chartName ? 'd-none d-sm-inline' : ''):''">
              <span class="colon" [ngClass]="detailInfo ? (chartName ? 'd-none d-sm-inline' : ''):'hidden'">
                Detail Date:
              </span>
            </span>
            <span class="text date">{{detailInfo}}</span>
          </div>
        </div>
        <div class="rtms-card-controls">
          <div *ngIf="filterOptions">
            <rtms-card-filter [cardFilterOptions]="filterOptions" [reportId]="reportId"></rtms-card-filter>
          </div>
          <div class="card-options padding-x">
            <rtms-export-data *ngIf="!hideHamburgerMenu" [templateId]="templateId" [chartName]="chartName"
              [reportId]="reportId" [exportDisabled]="exportDisabled" [exportPdfDisabled]="exportPdfDisabled" [isDetailed]="false" (export)="onExport($event)">
            </rtms-export-data>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="cardType === 'grid'? 'panel-content': 'panel-body'"
    *ngIf="!isLoading && (hasData || !hasData && (cardType == 'line' || cardType === 'grid'))">
    <ng-content></ng-content>
    <div *ngIf="flipCard" class="clickable flip-text" (click)="executeFlip()"
      [ngStyle]="{'position':'absolute', 'left': '1%'}">
      <div class="card-info"> <a title="Click here to see the other side of this card.">
          <i class="fa fa-backward fa-2x" aria-hidden="true"></i>
        </a>
      </div>
    </div>
  </div>

  <div *ngIf="!hasData && (cardType != 'line' && cardType !== 'grid')" class="panel-body">
    <div class="noContent">
      <rtms-no-data-found class="rtms-card" *ngIf="!isLoading && hasData === false" [noDataMessage]="noDataMessage">
      </rtms-no-data-found>
      <div *ngIf="flipCard && hasData" ngClass="'clickable'" (click)="executeFlip()" [ngClass]="flip-text"
        [ngStyle]="{'position':'absolute', 'left': '1%'}">
        <div class="card-info"> <a title="Click here to see the other side of this card.">
            <i class="fa fa-backward fa-2x" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="citation">
      <rtms-information [chartName]="chartName" [reportId]="reportId" [embedChart]="!hasData ? false : embedChart">
      </rtms-information>
    </div>

  </div>
</div>

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService, FilterStateService } from '../../services/services-index';
import { Observable } from 'rxjs';
import { FilterSettings } from '../../models/filter-settings';
import { tap } from 'rxjs/operators';
import { DataService } from '../data.service';


@Injectable()
export class DashboardService {
    constructor(private _http: HttpClient,
        private envSerive: EnvService,
        private filterStateService: FilterStateService,
        private dataService: DataService
    ) { }

    getDataFromApi(filter: any, apiLocation: string): Promise<any> {
        if (filter) {
            filter.Organizations = this.dataService.getOrganizationsWithIdAndType(filter.Organizations);
            return this._http.post<any>(this.envSerive.api + apiLocation, filter)
                .toPromise()
                .then(function (data) {
                    if (data.hasOwnProperty('Items')) {
                        return data.Items;
                    }
                    return data.Item;
                });
        } else {
            return this._http.get<any>(this.envSerive.api + apiLocation)
                .toPromise()
                .then(function (data) {
                    if (data.hasOwnProperty('Items')) {
                        return data.Items;
                    }
                    return data.Item;
                });

        }
    }

    public setInitialFilterForDashboard(): Observable<FilterSettings> {
        return this._http.post(this.envSerive.api + "dashboard/dashboard-filter",
            this.filterStateService.getFilter())
            .pipe(
                tap((response: any) => {
                    if (response) {
                        this.filterStateService.setFilter(response, true);
                    }
                })
            );
    }
}

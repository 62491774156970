import { Component, Input } from '@angular/core';

@Component({
  selector: 'rtms-resident-detail-card-header',
  templateUrl: './resident-detail-card-header.component.html',
  styleUrls: ['./resident-detail-card-header.component.scss']
})
export class ResidentDetailCardHeaderComponent  {
  @Input() data: any;
}

<div id="wrapper">

    <div id="page-wrapper" class="page-wrapper">
        <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
          <div class="page-header pull-left">
              <div class="page-title">
                No Feature Access
              </div>
          </div>
          <div class="clearfix"></div>
          <div class="page-content">
            Sorry, but it appears as though the current Facility does not have access to this feature. Click the Help & Feedback link below if you have any questions.
          </div>
      </div>
      <rtms-feedback></rtms-feedback>
    </div>
</div>

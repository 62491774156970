import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../shared/services/services-index';
import { BehaviorSubject } from 'rxjs';

export type FeedbackStep = 'closed' | 'opened' | 'submitted';

@Injectable({ providedIn: 'root' })
export class FeedbackService {
  private _currentStep: FeedbackStep = 'closed';
  _feedbackStepSubject = new BehaviorSubject<FeedbackStep>(this._currentStep);
  public feedbackStep$ = this._feedbackStepSubject.asObservable();

  constructor(private http: HttpClient,
              private envService: EnvService) { }

  public openStep() {
    this.updateStep('opened');
  }

  public closeStep() {
    this.updateStep('closed');
  }

  public submitStep() {
    this.updateStep('submitted');
  }


  public toggleStep() {
      switch (this._currentStep) {
        case 'opened':
        case 'submitted': this.updateStep('closed'); break;
        case 'closed': this.updateStep('opened'); break;
        default: console.warn(`Step Missed: ${this._currentStep}`);
    }
  }

  private updateStep(step: FeedbackStep) {
    this._currentStep = step;
    this._feedbackStepSubject.next(step);
  }

  sendEmail(message) {
    return this.http.post(this.envService.api + 'feedback/submitfeedback', {
      'Message': message
    });
  }
}

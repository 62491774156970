import * as Highcharts from 'highcharts';
import { OnChanges, Component, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';

import MapModule from 'highcharts/modules/map';
import { ChartWidgetConfig } from '../../models/chart-widget-config';
import { any } from '@uirouter/core';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { HttpClient } from '@angular/common/http';
import { LabelFormatService } from '../../services/label-format.service';
import { ChartHelperService } from '../../services/chart-helper.service';
import { FilterStateService } from '../../services/filter-state.service';
MapModule(Highcharts);

@Component({
  selector: 'rtms-map-chart',
  templateUrl: './map-chart.component.html'
})
export class MapChartComponent implements OnChanges {

  @Input() chartConfig: ChartWidgetConfig;
  @Input() seriesData: any[];
  @Input() allowOnClick: boolean;
  @Input() runOutsideAngular = true;
  @Output() onChartColumnClick = new EventEmitter();
  @Input() autoHeight: boolean;
  @Input() rowSpan: number = 1;

  constructor(
    private filterStateService: FilterStateService,
    private utilizationMetricsService: UtilizationMetricsService,
    private httpClient: HttpClient,
    private labelFormatService: LabelFormatService,
    private chartHelperService: ChartHelperService
  ) { }

  options: any;
  Highcharts = Highcharts;
  chartConstructor = "mapChart";
  mapRegion = 'countries/us/us-all-all';
  objChart = any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['chartConfig'] && changes['chartConfig'].currentValue !== undefined) {
      if (this.chartConfig) {
        this.setChartValue();
      }
    }
    if (changes['seriesData']) {
      if (this.seriesData && this.seriesData.length > 0) {
        this.setChartValue();
      }
    }
  }

  setChartValue() {
    this.httpClient.get('assets/mapData/us/' + this.seriesData[0].mapRegion + '.geo.json').subscribe((mapData: any) => {
      Highcharts.maps[this.mapRegion] = mapData;
      const countiesMap = Highcharts.geojson(
        Highcharts.maps[this.mapRegion]
      ),
        // Extract the line paths from the GeoJSON
        lines = Highcharts.geojson(
          Highcharts.maps[this.mapRegion], 'mapline'
        ),
        // Filter out the state borders and separator lines, we want these
        // in separate series
        borderLines = lines.filter(l => l.properties['hc-group'] === '__border_lines__'),
        separatorLines = lines.filter(l => l.properties['hc-group'] === '__separator_lines__');
      // Add state acronym for tooltip
      countiesMap.forEach(function (mapPoint) {
        mapPoint.name = mapPoint.properties['name'] = mapPoint.name + ', ' +
          mapPoint.properties['hc-key'].substr(3, 2).toUpperCase();
      });

      var apiData = [];
      this.seriesData[0].data.forEach(d => {
        d.id = d.name;
        apiData.push(d.id);
      });

      var codeData = apiData.filter(apiCounty => countiesMap.some(mapCounty => mapCounty.name === apiCounty));
      if (apiData.length !== codeData.length) {
        var missingCounties = apiData.filter(apiCounty => !codeData.some(codeCounty => codeCounty === apiCounty));
        console.error('Highchart map county not found for ' + missingCounties);
      }

      this.options = {
        name: this.chartConfig.name,
        reportId: this.chartConfig.ReportId,
        drillsIntoReportId: this.chartConfig.DrillsIntoReportId,
        chart: {
          events: {
            redraw: this.chartHelperService.onRedrawChart(this, codeData)
          }
        },
        title: {
          text: ''
        },
        legend: {
          enabled: this.chartConfig.legendEnabled,
          layout: this.chartConfig.legendLayout,
          align: this.chartConfig.legendAlign,
          floating: true,
          backgroundColor: 'rgba(255, 255, 255, 0.85)'
        },
        credits: {
          enabled: false
        },
        mapNavigation: {
          enabled: true,
          buttonOptions: {
            align: 'right',
            verticalAlign: 'bottom'
          }
        },
        colorAxis: {
          min: this.seriesData[0].colorAxis.min,
          max: this.seriesData[0].colorAxis.max,
          tickInterval: this.seriesData[0].colorAxis.tickInterval,
          tickPositions: this.seriesData[0].colorAxis.tickPositions.length > 0 ?
            this.seriesData[0].colorAxis.tickPositions : undefined,
          stops: this.seriesData[0].colorAxis.stops,
          labels: {
            format: '{value}'
          }
        },
        series: [{
          name: this.seriesData[0].name,
          mapData: countiesMap,
          data: this.seriesData[0].data,
          customData: this.seriesData[0].customData,
          joinBy: ['name', 'name'],
          borderWidth: 0.3,
          borderColor: 'black',
          states: {
            hover: {
              color: '#a4edba'
            },
            select: {
              color: '#ffff00'
            }
          },
          shadow: false,
          showInLegend: false,
          allowPointSelect: true
        },
        {
          type: 'mapline',
          name: 'State borders',
          data: borderLines,
          color: 'gray',
          shadow: false,
          enableMouseTracking: false
        },
        {
          type: 'mapline',
          name: 'Separator',
          data: separatorLines,
          color: 'gray',
          shadow: false,
          enableMouseTracking: false
        }],
        plotOptions: {
          series: {
            point: {
              events: {
                mouseOver: function () {
                  $("#map-container").addClass("cursor");
                },
                mouseOut: function () {
                  $("#map-container").removeClass("cursor");
                }
              }
            },
            events: {
              click: this.onChartClick,
            }
          }
        },
        tooltip: {
          enabled: true,
          style: {
            padding: 10,
            fontWeight: 'bold'
          },
          formatter: this.getToolTipFormat,
        }
      };
    })
  }

  getToolTipFormat = (function (self) {
    return function () {
      return self.labelFormatService.getToolTipFormat(self.options, self.chartConfig.tooltipFormat, this, false, '');
    };
  })(this);

  onChartClick = (function (_self) {
    function handleOnClick(event, self, th) {
      const filterSettingsStoreData = self.filterStateService.getFilter();
      const filterSettings: any = {
        StartDate: filterSettingsStoreData.StartDate,
        EndDate: filterSettingsStoreData.EndDate,
        FilterType: event.point.series.userOptions.filterType ?
          event.point.series.userOptions.filterType : self.chartConfig.FilterType
      };
      self.utilizationMetricsService.recordChartDrilldown(
        self.chartConfig.ReportId,
        self.chartConfig.name,
        event.point.series.name,
        event.point.name,
        event.point.value,
        self.chartConfig.DrillsIntoReportId);
      filterSettings.FilterValue = event.point.id;
      event.filter = filterSettings;
      self.onChartColumnClick.emit(event);
    }
    return function (event) {
      handleOnClick(event, _self, this);
    };
  })(this);

  getChartInstance(chart): void {
    // chart instance
    this.objChart = chart;
  }

  getHeight(): string {
    if (this.rowSpan == 1) {
      return '230px';
    } else if (this.autoHeight) {
      return 'calc(100vh - 300px)';
    }
  }
}



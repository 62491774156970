import { Component, OnInit, Input, SimpleChanges, ViewChild  } from '@angular/core';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { RehospDashboardService } from 'src/app/shared/services/portal/rehospDashboard.service';
import { NgbPopover, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterSettings } from '../../models/filter-settings';
import { ResidentScoringDetail } from '../../models/resident-scoring-detail';
import { ResidentCardScoreDetailComponent } from '../resident-card-score-detail/resident-card-score-detail.component';
import { BaseComponent } from '../base.component';
import * as $ from 'jquery';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { getRiskColor } from '../../utility/ui-helper';
import { FeatureService } from '../../services/feature.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { GenericDashboardComponent } from 'src/app/pages';
import { BsModalService } from 'ngx-bootstrap/modal';
import { StateService } from '@uirouter/core';
import { FilterStateService } from '../../services/filter-state.service';
import { OpioidScore } from '../opioid-score/opioid-score.component';

@Component({
  selector: 'rtms-resident-card-score-indicator',
  templateUrl: './resident-card-score-indicator.component.html',
  styleUrls: ['./resident-card-score-indicator.component.scss']
})
export class ResidentCardScoreIndicatorComponent extends BaseComponent implements OnInit {

  @Input() organizationId: string;
  @Input() reportId: number;
  @Input() reportName: string;
  @Input() riskLevel: string;
  @Input() resMrn: string;
  @Input() requiresACP: boolean;
  @Input() opioidScore: OpioidScore;
  @Input() opioidMessage: string;
  public _showSpinner: boolean;
  public _hideIndicator: boolean;
  public _indicatorColor: string;
  public _residentScoreDetail: ResidentScoringDetail;
  public _showLinkToResidentDashboard: boolean = false;
  @ViewChild('scorePopOver') public scorePopOver: NgbPopover;
  @ViewChild('residentCardScoreDetailComponent') residentCardScoreDetailComponent: ResidentCardScoreDetailComponent;
  @ViewChild('residentDashboard') residentDashboard: GenericDashboardComponent;

  constructor(private utilizationMetricsService: UtilizationMetricsService,
    private rehospDashboardService: RehospDashboardService,
    private userStateService: UserStateService,
    private _filtersService: FiltersService,
    private _featureService: FeatureService,
    private _modalService: BsModalService,
    private _stateService: StateService,
    private _filterStateService: FilterStateService
  ) {
    super();

    this.subscriptions.push(this._filtersService.organizations.getOrganizationFeatureStream()
      .subscribe(organizations => {
        if (organizations && organizations.length > 0) {
          const facility = organizations[0];
          const residentDashboardIsEnabled = this._featureService.isFeatureEnabledForFacility(facility, 'Resident.Dashboard');
          const residentScoreCardLinkToResidentDashboardIsEnabled = this._featureService.isFeatureEnabledForFacility(facility, "Resident.Score.Card.Link.Resident.Dashboard");
          this._showLinkToResidentDashboard = residentDashboardIsEnabled && residentScoreCardLinkToResidentDashboardIsEnabled;
        }
      })
    );
   }

  ngOnInit() {
     this._showSpinner = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['riskLevel']) {
      if (this.riskLevel) {
        this.setRiskColor(this.riskLevel);
      } else {
        this._hideIndicator = true;
      }
    }
  }

  protected setRiskColor(riskLevel) {
    this._indicatorColor = getRiskColor(this.riskLevel);
    if (this._indicatorColor) {
      this._hideIndicator = false;
    } else {
      this._hideIndicator = true;
    }
  }

  protected setOpioidVisibility(opioidScore: number)  {
      return opioidScore == OpioidScore.NotApplicable ? false : true;
  }

  public showDetail(popover) {
      this._showSpinner = true;
      let filterSettings: FilterSettings;
      filterSettings = {
            OrganizationId: Number(this.organizationId),
            ResMrn: this.resMrn,
            UserTimeZoneId: this.userStateService.getLoggedInUser().TimeZoneId,
      };
      this.utilizationMetricsService.recordResidentIndicatorClick(this.reportId, this.reportName, this.resMrn);
      this.rehospDashboardService.getResidentScore(filterSettings).subscribe(data => {
      this._residentScoreDetail = data;
      this._residentScoreDetail.RequiresACP = this.requiresACP;
      this._residentScoreDetail.OpioidScore = this.opioidScore;
      this._showLinkToResidentDashboard &&= this._residentScoreDetail.IsActive;
        if (data !== null) {
          $('#ngbPopover').closest('.fh-report-table-content,.fh-report-table').scroll(function() {
            $('#ngbPopoverClose').trigger( 'click' );
           });
          popover.open();
        } else {
          popover.close();
        }
        this._showSpinner = false;
      });
    }

    protected viewDetails(popover) {
     this.residentCardScoreDetailComponent.showModalDetail(this._residentScoreDetail, this._indicatorColor);
     this.closePopover(popover);
    }

    protected closePopover(popover) {
      if (popover.isOpen()) {
        popover.close();
      }
     }

    protected viewResidentDashboard() {
      const filterSettings = this._filterStateService.getFilter();
      filterSettings.ResMRN = this.resMrn;
      filterSettings.OrganizationId = this.organizationId;
      this._filterStateService.setFilter(filterSettings);
      const modalRef = this._modalService.show(GenericDashboardComponent, {
        initialState: {
          category: 'resident-dashboard',
          DashboardName: 'resident-dashboard',
          isModal: true,
          isResidentDashboard: true,
        },
        class: 'generic-dashboard-modal',
        backdrop: true,
      });
    }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'rtms-acp-status',
  templateUrl: './acp-status.component.html',
  styleUrls: ['./acp-status.component.scss']
})
export class AcpStatusComponent {

  @Input() acpStatus: boolean = false;

  constructor() { }
}

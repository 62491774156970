import { Component, ViewChild } from '@angular/core';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { TagResidentService } from 'src/app/shared/services/portal/tag-resident.service';
import { TagResidentModel } from 'src/app/shared/models/tag-resident.model';
import { ToastrService } from 'ngx-toastr';
import { User } from 'src/app/shared/models/user';
import * as _ from 'lodash';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { StateService, Transition } from '@uirouter/angular';
import { CellClickComponent } from '../generic-dashboard/handlers/CellClickComponent';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { BaseComponent } from 'src/app/shared/components';

@Component({
  selector: 'rtms-tag-resident',
  templateUrl: './tag-resident.component.html',
  styleUrls: ['./tag-resident.component.scss']
})
export class TagResidentComponent extends BaseComponent {

  isTaggingResidentEnabledPrevious = false;
  residentSearchInput = '';
  selectedResidents: TagResidentModel[] = [];
  savedResidents: TagResidentModel[] = [];
  isTaggingResidentEnabled = false;
  selectedUsers: User[] = [];
  savedUsers: User[] = [];
  residents: TagResidentModel[] = [];
  users: User[] = [];
  userOrganizationId = this.userStateService.getLoggedInUser().OrganizationId;
  userId = this.userStateService.getLoggedInUser().userId;
  source: TagResidentModel[] = [];;
  isSearchResidentLoading: boolean = false;
  autoCompleteMinChars: number = 3;
  prevSearchString: String = '';
  isTaggedResidentLoading = false;
  admittedResidents: any = {};
  isAdmittedResidentLoading: boolean = false;
  isAdmittedResidentsAvailable: boolean = false;
  TagResidentsTabIndex: number = 0;
  OverseeUserResidentsTabIndex: number = 1;
  ResidentsAdmittedLast72HoursTabIndex: number = 2;
  selectedTabIndex: number = this.TagResidentsTabIndex;
  previousTabIndex: number = this.TagResidentsTabIndex;
  residentAdmittedLast72HoursEnabled: boolean = false;
  initialLoad: boolean = true;
  isUserLoading = false;
  isTaggedUserLoading = false;
  residentTaggingEnabled: boolean = false;

  @ViewChild('confirmResident') private confirmResident: ConfirmComponent;
  @ViewChild('residentTaggingTabs') residentTaggingTabs?: TabsetComponent;
  transition: Transition;

  constructor(
    private userStateService: UserStateService,
    private toastrService: ToastrService,
    private tagResidentService: TagResidentService,
    private stateService: StateService,
    private cellClickComponent: CellClickComponent,
    private featureService: FeatureService,
    private filtersService: FiltersService,
  ) {
    super();
    this.subscriptions.push(this.userStateService.getLoggedInUserStream()
      .subscribe(user => {
        if(user.OrganizationId != this.userOrganizationId) {
          this.stateService.reload();
        }})
      );
      
      this.subscriptions.push(
        this.filtersService.organizations.getOrganizationFeatureStream()
        .subscribe(organizations => {
        if (organizations && organizations.length > 0) {
          const facility = organizations[0];
          this.residentTaggingEnabled = this.featureService.isFeatureEnabledForFacility(facility, 'Resident.Tagging');
          if(!this.residentTaggingEnabled) {
            this.stateService.transitionTo('home.noFeatureAccess');
          }
          this.residentAdmittedLast72HoursEnabled = this.featureService.isFeatureEnabledForFacility(facility, 'Residents.Admitted.Last72Hours');
        }})
      );

    this.checkResidentTaggingStatus();
  }

  loadAdmittedResidents() {
    this.isAdmittedResidentLoading = true;
    this.tagResidentService.getAdmittedResidents().subscribe((response) => {
      this.admittedResidents = response;
      this.isAdmittedResidentsAvailable = true;
      this.isAdmittedResidentLoading = false;
    });
  }

  checkResidentTaggingStatus() {
    this.tagResidentService.getResidentTaggingStatus(this.userId).subscribe((response) => {
      this.isTaggingResidentEnabled = response;
      this.loadUsersAndResidents();
    });
  }

  loadUsers() {
    this.isUserLoading = true;
    this.tagResidentService.getUsers().subscribe((response) => {
      this.users = response;
      this.isUserLoading = false;
      this.loadTaggedUsers();
    });
  }

  searchResidents() {
    this.residents = [];
    let req = new TagResidentModel();
    req.FilterValue = this.residentSearchInput;
    req.OrganizationId = this.userOrganizationId;
    this.getResidents(req);
  }

  loadTaggedResident() {
    let req = new TagResidentModel();
    req.OrganizationId = this.userOrganizationId;
    this.getTaggedResident(req);
  }

  getResidents(req: any) {
    this.isSearchResidentLoading = true;
    this.tagResidentService.getResident(req).subscribe((response) => {
      this.isSearchResidentLoading = false;
      this.residents = response;
      this.residents.forEach(r => { r.IsSearched = true; r.Key = this.getKey(r); });
      this.selectedResidents.forEach(sr => { sr.IsSearched = false; sr.Key = this.getKey(sr); });
      this.source = this.prepareSource();
    }, (error: any) => {
      this.isSearchResidentLoading = false;
    });
  }

  getTaggedResident(req) {
    this.selectedResidents = [];
    this.savedResidents =[];
    this.tagResidentService.getTaggedResident(req).subscribe((response) => {
      this.isTaggedResidentLoading = false;
      this.selectedResidents = response;
      this.selectedResidents.forEach(sr => { sr.IsSearched = false; sr.Key = this.getKey(sr); });
      this.savedResidents = [...response];
      this.source = this.prepareSource();
    }, (error: any) => {
      this.isTaggedResidentLoading = false;
    });
  }

  toggleResidentTagging() {
    this.transition = null;
    if (!this.isTaggingResidentEnabled && this.hasAnyUnsavedChanges()) {
      this.confirmResident.body = "There are unsaved changes. Disabling Resident Tagging will discard those changes. Do you want to continue?";
      this.confirmResident.Show();
    } else {
      this.isTaggingResidentEnabledPrevious = !this.isTaggingResidentEnabled;
      var req = {
        TaggingStatus: this.isTaggingResidentEnabled
      };
      this.tagResidentService.saveTaggingStatus(req)
        .subscribe(_ => {
          if (!this.isTaggingResidentEnabledPrevious) {
            this.toastrService.success("Resident Tagging enabled successfully.");
          } else {
            this.toastrService.success("Resident Tagging disabled successfully.");
          }
        });
      this.loadUsersAndResidents();
    }
  }

  saveResidents() {
    if (this.hasAnyUnsavedChanges()) {
      this.saveTaggedResidents();
    } else {
      this.toastrService.success("Saved successfully");
    }
  }

  saveTaggedResidents() {
    let req = new TagResidentModel();
    req.TaggingStatus = this.isTaggingResidentEnabled;
    req.Residents = this.selectedResidents;
    req.OrganizationId = this.userOrganizationId;
    req.UserIds = this.selectedUsers.map(x => x.UserId);
    this.tagResidentService.saveTaggedResidents(req).subscribe((response) => {
      this.savedResidents = [...this.selectedResidents];
      this.savedUsers = [...this.selectedUsers];

      if (!this.isTaggingResidentEnabledPrevious) {
        this.toastrService.success("Saved successfully");
      }
    });
  }

  loadTaggedUsers() {
    this.isTaggedUserLoading = true;
    this.tagResidentService.getTaggedUsers(this.userId).subscribe((response) => {
      this.isTaggedUserLoading = false;
      let userIds = response;
      this.selectedUsers = this.users.filter(x => userIds.some(y => x.UserId === y));
      this.savedUsers = [...this.selectedUsers];
      this.users = [...this.users, ...this.selectedUsers];
    }, (error: any) => {
      this.isSearchResidentLoading = false;
    });
  }

  filterResident = _.debounce((val: string): void => {
    if (val && val.length >= this.autoCompleteMinChars) {
      if (!(this.prevSearchString && this.prevSearchString != ''
        && val.toLowerCase() == this.prevSearchString.toLowerCase()
        && this.residents && this.residents.length > 0)) {
        this.searchResidents();
      }
      this.prevSearchString = val;
    } else {
      this.residents = [] as Array<TagResidentModel>;
      this.source = [...this.selectedResidents];
    }
  }, 500);

  uiCanExit(transition: Transition) {
    this.transition = transition;
    if (this.hasAnyUnsavedChanges()) {
      if (this.transition.to().name == 'home.login') {
        return true;
      }
      this.confirmResident.body = "There are unsaved changes. Leaving away will discard those changes. Do you want to continue?";
      this.confirmResident.Show();
      return false;
    }
    return true;
  }

  navigate(isConfirmed: boolean) {
    if (isConfirmed) {
      this.removeUnsavedChanges();
      if (this.transition) {
        this.stateService.transitionTo(this.transition.$to().name);
      } else {
        if (this.selectedTabIndex === this.ResidentsAdmittedLast72HoursTabIndex) {
          this.isAdmittedResidentsAvailable = false;
          this.loadAdmittedResidents();
        } else {
          this.residentSearchInput = '';
          this.residents = [];
          this.isTaggingResidentEnabledPrevious = !this.isTaggingResidentEnabled;
          this.saveTaggedResidents();
        }
      }
    } else {
      if (this.selectedTabIndex === this.ResidentsAdmittedLast72HoursTabIndex) {
        if (this.residentTaggingTabs?.tabs[this.previousTabIndex]) {
          this.residentTaggingTabs.tabs[this.previousTabIndex].active = true;
        }
      } else {
        this.isTaggingResidentEnabled = true;
      }
    }
  }

  hasAnyUnsavedResidents() {
    let savedResMRNs = [...new Set(this.savedResidents.map(x => x.Key).sort())];
    let selectedResMRNs = [...new Set(this.selectedResidents.map(x => x.Key).sort())];
    return JSON.stringify(savedResMRNs) !== JSON.stringify(selectedResMRNs);
  }

  hasAnyUnsavedUsers() {
    let savedUserIds = [...new Set(this.savedUsers.map(x => x.UserId).sort())];
    let selectedUserIds = [...new Set(this.selectedUsers.map(x => x.UserId).sort())];
    return JSON.stringify(savedUserIds) !== JSON.stringify(selectedUserIds);
  }

  hasAnyUnsavedChanges(): boolean {
    let unsavedResidents = this.hasAnyUnsavedResidents();
    let unsavedUsers = this.hasAnyUnsavedUsers();
    return (unsavedResidents || unsavedUsers);
  }

  removeUnsavedChanges() {
    this.selectedResidents = [...this.savedResidents];
    this.selectedUsers = [...this.savedUsers];
  }

  loadUsersAndResidents() {
    if (this.isTaggingResidentEnabled) {
      if (this.selectedTabIndex === this.TagResidentsTabIndex) {
        this.isTaggedResidentLoading = true;
        this.loadTaggedResident();
      } else if (this.selectedTabIndex === this.OverseeUserResidentsTabIndex || this.initialLoad) {
        this.loadUsers();
        this.initialLoad = false;
      }
    }
  }

  prepareSource(): TagResidentModel[] {
    return [...this.residents,
    ...this.selectedResidents.filter(sr => this.residents.every(r => r.ResMRN != sr.ResMRN))];
  }

  destinationChange(): void {
    if (this.residentSearchInput) {
      var filteredValue = [... this.source.filter(_ => _.IsSearched == true)];
      this.source = [...filteredValue,
      ...this.selectedResidents.filter(sr => filteredValue.every(r => r.ResMRN != sr.ResMRN))];
    } else {
      this.source = [...this.selectedResidents];
    }
  }

  selectTab(tabIndex: number): void {
    this.previousTabIndex = this.selectedTabIndex;
    this.selectedTabIndex = tabIndex;
    if (this.selectedTabIndex === this.ResidentsAdmittedLast72HoursTabIndex) {
      if (this.hasAnyUnsavedChanges()) {
        this.transition = null;
        this.isAdmittedResidentsAvailable = false;
        this.confirmResident.body = "There are unsaved changes. Leaving away will discard those changes. Do you want to continue?";
        this.confirmResident.Show();
      } else {
        this.loadAdmittedResidents();
      }
    } else if (this.selectedTabIndex === this.TagResidentsTabIndex) {
      if (!this.hasAnyUnsavedResidents()) {
        this.source = [];
        this.residents = [];
        this.residentSearchInput = '';
        this.loadUsersAndResidents();
      }
    } else if (this.selectedTabIndex === this.OverseeUserResidentsTabIndex) {
      if (!this.hasAnyUnsavedUsers()) {
        this.users = [];
        this.selectedUsers = [];
        this.savedUsers = [];
        this.loadUsersAndResidents();
      }
    }
  }

  getKey(resident: TagResidentModel): string {
    return resident.OrganizationId + '_' + resident.ResMRN;;
  }

  cellClickHandler(event): void {
    event.UserIds = this.savedUsers.map(x => x.UserId);
    this.cellClickComponent[event.column.ReportColumnDefinitionProps.CellClickHandler](event);
  }
}
<div class="export-button" id="hamburger_{{reportId}}">
  <div class="dropdown">
    <button *ngIf="!exportDisabled" class="stop-wrap btn green dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
      <i *ngIf="!exportDisabled" class="fa fa-file-export" style="color:gray"></i>
      <!-- <i [ngClass]="{'export-enabled': ! exportDisabled}" class="fa fa-expand-alt" style="color:gray"></i> -->
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li *ngIf="!exportDisabled && !exportPdfDisabled" class="dropdown-item">
        <a class="exportDropDownMenu export-pdf" href="javascript:;" (click)="export.emit('pdf');">
          <i class="menu-icon">a</i>
          <span class="text">Export to PDF</span>
        </a>
      </li>
      <li *ngIf="!exportDisabled" class="dropdown-item">
        <a class="exportDropDownMenu export-excel" href="javascript:;" (click)="export.emit('excel');">
          <i class="menu-icon">e</i>
          <span class="text">Export to Excel</span>
        </a>
      </li>
      <li *ngIf="exportCardPdfEnabled" class="dropdown-item">
        <a class="exportDropDownMenu export-excel" href="javascript:;" (click)="export.emit('detailPDF');">
          <i class="menu-icon">d</i>
          <span class="text">Export Detail PDF</span>
        </a>
      </li>
      <li *ngIf="exportCardPdfWOInterventionsEnabled" class="dropdown-item">
        <a class="exportDropDownMenu export-excel" href="javascript:;" (click)="export.emit('detailWithoutInterventionsPDF');">
          <i class="menu-icon">d</i>
          <span class="text">Export Detail Without Interventions PDF</span>
        </a>
      </li>
    </ul>
  </div>
  <button id="fullscreen" class="stop-wrap btn green mlx"
    aria-expanded="false" href="javascript:;" (click)="showFullScreenClick($event);">
    <i class="fa fa-expand-alt" style="color:gray"></i>
  </button>
</div>

import { Injectable } from "@angular/core";
import { OrganizationModel } from "src/app/shared/models/organization.model";
import { ExportService, ReportService } from "src/app/shared/services/services-index";
import { EnvService } from 'src/app/shared/environment/env.service';
import { UserStateService } from "src/app/user/store/services/user-state.service";
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserMessageModel } from "src/app/shared/models/user-message.model";

interface DownloadScheduledReportModel { 
    ReportId: number | Number, 
    OrganizationId: number, 
    OrganizationName: string, 
    EntityId: number | Number
}

@Injectable()
export class MessageService {
    constructor(
        private _reportService: ReportService,
        private _exportService: ExportService,
        private _userStateService: UserStateService,
        private _http: HttpClient,
        private _envService: EnvService
    ) { }

    private downloadableEntityTypes = {
        ScheduledReport:  "ReportScheduleHistory",
        QueuedReport: 'ReportQueue'
    }

    hasReportDownload(userMessage: UserMessageModel): boolean {
        return this.isScheduledReport(userMessage)
            || this.isQueuedReport(userMessage);
    }

    isScheduledReport(userMessage: UserMessageModel): boolean {
        return userMessage.EntityType === this.downloadableEntityTypes.ScheduledReport;
    }

    isQueuedReport(userMessage: UserMessageModel): boolean {
        return userMessage.EntityType === this.downloadableEntityTypes.QueuedReport;
    }

    downloadReport(userMessage: UserMessageModel) : void {
        if (this.isScheduledReport(userMessage)) {
            this.downloadScheduledReport({...userMessage, EntityId: userMessage.EntityId ?? 0});
        } else if (this.isQueuedReport(userMessage)) {
            this.downloadQueuedReport(userMessage.Subject, userMessage.EntityId);
        }
    }
    
    downloadScheduledReport(model: DownloadScheduledReportModel): void {
        this._reportService.getReportById(model.ReportId).then(
            res => {
                const report = res;
                var org = new OrganizationModel();

                org.OrganizationId = model.OrganizationId,
                org.OrganizationName = model.OrganizationName;

                var user = this._userStateService.getLoggedInUser();
                var filter = { UserTimeZoneId: user.TimeZoneId, ReportScheduleHistoryId: model.EntityId };
                this._exportService.downloadScheduledReport(report, filter, org);
            }
        );
    }

    downloadQueuedReport(reportName: string, reportQueueId: number) : void {
        const downloadUrl = this._envService.api + 'reports/getqueuedreportdownload/' + reportQueueId;
      
        this.downloadFile(downloadUrl).subscribe(r => {
            this._exportService.saveFile(r.body, reportName, r.body.type);
        });
    }

    downloadFile(downloadUrl): Observable<HttpResponse<Blob>> {
        return this._http.get(downloadUrl, {observe: 'response', responseType: 'blob'}).pipe();
    }
}
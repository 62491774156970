<ng-template #template>
    <div class="modal-header">
            <span class="modal-title">
                {{testName}}
            </span>
            <button class="btn-close" (click)='this.closeModals()'></button>
    </div>
    <div class="modal-body">
            <div style="padding-top: 50px; text-align: center;" [hidden]="!showSpinner">
                    <span  title="Retrieving details...">
                        <i class="fa fa-spinner fa-spin fa-2x"></i>
                    </span>
            </div>
            <div class="row">
                    <div class="col-12 grid-container residentLabs">
                        <table class="table table-striped table-bordered" id="tblGridHeader" *ngIf="residentLabResult && residentLabResult.length > 0"
                            style="width: 100%;">
                            <thead>
                                <tr>
                                    <th>
                                        Date
                                    </th>
                                    <th class="text-center">
                                        Value
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of residentLabResult" >
                                    <td>{{data.ReportedDate  | date:'M/dd/yyyy HH:mm:ss a'}}</td>
                                    <td class="text-center">{{data.LabResult}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div>
                            <table id="tblGridHeader" style="width: 100%;"  [hidden]="showSpinner">
                                <tr *ngIf="residentLabResult && residentLabResult.length === 0">
                                    <td style="text-align: center; padding-top: 50px;"> No data found.</td>
                                </tr>
                            </table>
                        </div>
                    </div>
            </div>
    </div>
</ng-template>

import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { QmReportService } from '../../services/portal/qmReport.service';
import { LookUpDataService } from '../../services/portal/lookUpData.service';
import { NavigationService } from '../../services/portal/navigation.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { DisplayFormats, RtmsConstantService } from '../../services/rtms-constant.service';
import { FilterSettings } from '../../models/filter-settings';
import { StateService } from '@uirouter/core';
import { FacilityUnit } from '../../models/facility-unit';
import { Payer } from '../../models/payer';
import { QMType } from '../../models/qm-type';
import { OrganizationModel } from '../../models/models-index';
import { SelectOption } from '../../models/select-option';
import { Category } from '../../models/category';
import { FilterStateService, UINotifierService } from '../../services/services-index';
import { UIEventTypes } from '../../enums/ui-event-types';
import { filter } from 'rxjs/operators';
import { list } from '../../utility/list';
import { PortalUIEvent } from '../../models/portal-ui-event';
import { Filters } from '../../enums/filters';
import { BaseComponent } from '../base.component';
import { SelectedChartStateService } from '../../services/selected-chart-state.service';
import { FeatureService } from '../../services/feature.service';
import { DashboardFilterOption } from '../../models/dashboard-filter-options';
import { FilterOptions } from '../../models/filter-options';
import * as _ from 'lodash';
import { MedClass } from '../../models/med-class';
import { DiagnosisType } from '../../models/diagnosis-type';
import { VaccineType } from '../../models/vaccine-type';

@Component({
    selector: 'rtms-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})

export class FilterComponent extends BaseComponent implements OnInit {

    constructor(
        private stateService: StateService,
        private filterStateService: FilterStateService,
        private qmReportService: QmReportService,
        private lookDataService: LookUpDataService,
        private navigationService: NavigationService,
        public filterService: FiltersService,
        private listStateService: ListsStateService,
        private userStateService: UserStateService,
        public toasterService: ToastrService,
        private utilizationMetricsService: UtilizationMetricsService,
        public uiNotifierService: UINotifierService,
        private selectedChartStateService: SelectedChartStateService,
        private featureService: FeatureService,
        private listsStateService: ListsStateService,
        private rtmsConstantService: RtmsConstantService
    ) {
        super();
        this.setSelectedOrganization();
        this.subscribeFilterState();
        this.setReportFlags();
    }

    selStay: SelectOption = null;
    selCategories: Category[] = [];
    isKeywordSearch = false;
    isUtilizationScore = false;
    isEnterpriseProactDashboard = false;
    isEnterpriseFinancialDashboard = false;
    isEnterpriseClinicalDashboard = false;
    isEnterprisePDPMDashboard = false;
    isEnterpriseDiseaseWatchDashboard = false;
    isEnterpriseInfectionControlDashboard = false;
    isHomeHealthProactHSDashboard = false;
    isHomeHealthProactDashboard = false;
    isInfectionControlDashboard = false;
    isAdvanceCarePlanningReport = false;
    isTwentyFourHour = false;
    isImmunizationReport = false;
    isCovidReport = false;
    previousOrg: OrganizationModel = null;
    filterSettings: FilterSettings = null;
    filterDate: Date = null;
    startDate: Date = null;
    endDate: Date = null;
    qmTypes: QMType[] = [];
    isQM = false;
    isQMCount = false;
    isQMSummary = false;
    isClinicalDashboard = false;
    isPortalUsage = false;
    isInfectionSurveillance = false;
    isResidentList = false;
    isPPSReport = false;
    isResidentDashboard = false;
    selectedOrganization: OrganizationModel = null;
    isHealthSystem = this.isHealthSystemFun();
    showFilterUnits = true;
    facility = this.getFacilitiesForDropdown(this.stateService.current.name);
    enableTime = true;
    dateFormat = DisplayFormats.date;
    selUnit: FacilityUnit[] = [];
    selMultiFacs: OrganizationModel[] = [];
    selPayer: Payer[] = [];
    selMedClasses: MedClass[] = [];
    selDiagnosisTypes: DiagnosisType[] = [];
    selVaccineTypes: VaccineType[] = [];
    selUserStatus: SelectOption[] = [];
    selQMType: QMType[] = [];
    selType: SelectOption[] = [];
    chkOnlyDischarged = false;
    chkIncludeDischarged = false;
    userStatus = this.listStateService.getUserStatus();
    stay = this.listStateService.getStay();
    type = this.listStateService.getTypes();
    units: FacilityUnit[] = [];
    payers: Payer[] = [];
    medClasses: MedClass[] = [];
    diagnosisTypes: DiagnosisType[] = [];
    vaccineTypes: VaccineType[] = [];
    isMultiFacilityUnit = false;
    categories: Category[] = [];
    @Output() reload = new EventEmitter();
    dateRanges: DashboardFilterOption[] = [];
    selDateRange: number;
    isSNFAdmissions = false;
    isSNFDischarges = false;
    isKeywordDischarge = false;
    isPDPMSummaryRollup = false;
    isUserLoginReport = false;
    isEnterpriseMedicalDirectorDashboard = false;
    isMultipleFacilitySelection = false;
    isKeywordReport = false;

    ngOnInit() {
        this.findDataOnState();
    }

    findDataOnState() {
        this.facilityWiseUnitAndPayerSelected();
        const selectedReport = this.selectedChartStateService.getSelectedReport();
        const reportId = selectedReport ? selectedReport.reportId : null;
        this.filterService.isQMAverageDrillDown.set(false);
        if (this.stateService.params.category === 'reports-qm') {
            if (reportId === this.listStateService.getReportEnumByName('QMAverageCompare').Id) {
                this.isQM = true;
                this.filterService.isQMAverageDrillDown.set(true);
            } else if (reportId === this.listStateService.getReportEnumByName('QMCounts').Id
                || reportId ===
                this.listStateService.getReportEnumByName('QMSummaryDetail').Id) {
                this.isQMCount = true;
                this.filterService.isQMAverageDrillDown.set(false);
            } else if (reportId === this.listStateService
                .getReportEnumByName('QMSummary').Id ||
                reportId === this.listStateService.getReportEnumByName('QMDetail').Id) {
                this.isQMSummary = true;
                if (reportId === this.listStateService.getReportEnumByName('QMSummary').Id) {
                    this.filterService.isQMAverageDrillDown.set(false);
                }
            } else {
                this.isQM = false;
                this.isQMSummary = false;
                this.isQMCount = false;
                this.filterService.isQMAverageDrillDown.set(false);
            }
        } else if (this.stateService.params.category === 'reports-rehosp') {
            if (reportId === this.listStateService.getReportEnumByName('ResidentList').Id) {
                this.isResidentList = true;
                this.OrgnizationsAndStatusSelected();
            }
        } else if (this.stateService.params.category === 'reports-infection-control') {
            if (reportId === this.listStateService.getReportEnumByName('InfectionSurveillance').Id) {
                this.isInfectionSurveillance = true;
                this.OrgnizationsAndStatusSelected();
            } else if (reportId === this.listStateService.getReportEnumByName('Immunizations').Id) {
                this.isImmunizationReport = true;
                }
              else if (reportId === this.listStateService.getReportEnumByName('ActiveCOVID19Diagnosis').Id) {
                this.isCovidReport = true;
              }
        } else if (this.stateService.params.category === 'reports-financial') {
            if (reportId === this.listStateService.getReportEnumByName('PPS').Id) {
                this.isPPSReport = true;
                this.OrgnizationsAndStatusSelected();
            }
        } else if (this.stateService.params.category === 'reports-clinical') {
            this.filterService.isQMAverageDrillDown.set(false);
            if (reportId
                === this.listStateService.getReportEnumByName('KeywordSearch').Id
                || reportId
                === this.listStateService.getReportEnumByName('KeywordCMISearch').Id) {
                this.isKeywordSearch = true;
            } else if (reportId
                === this.listStateService.getReportEnumByName('KeywordDischarge').Id) {
                this.isKeywordDischarge = true;
                this.isKeywordSearch = true;
            } else if (reportId
                === this.listStateService.getReportEnumByName('AdvanceCarePlanning').Id) {
                this.isAdvanceCarePlanningReport = true;
                this.OrgnizationsAndStatusSelected();
            } else if (reportId
                === this.listStateService.getReportEnumByName('KeywordHSSearch').Id
                || reportId
                === this.listStateService.getReportEnumByName('KeywordRiskManagement').Id
                || reportId
                === this.listStateService.getReportEnumByName('KeywordInfectionControl').Id) {
                this.isKeywordReport = true;
            } else {
                this.isKeywordSearch = false;
            }
        } else if (this.stateService.current.name === 'clinical' || this.stateService.current.name === 'dashboard') {
            this.isClinicalDashboard = true;
            this.filterService.isQMAverageDrillDown.set(false);
        } else if (this.stateService.current.name === 'home.utilizationScoreCard') {
            this.isUtilizationScore = true;
        } else if (this.stateService.current.name === 'home.enterpriseProactDashboard') {
            this.isEnterpriseProactDashboard = true;
        } else if (this.stateService.current.name === 'home.residentDashboard') {
            this.isResidentDashboard = true;
        } else if (this.stateService.current.name === 'home.enterpriseFinancialDashboard') {
            this.isEnterpriseFinancialDashboard = true;
        } else if (this.stateService.current.name === 'home.enterpriseClinicalDashboard') {
            this.isEnterpriseClinicalDashboard = true;
        } else if (this.stateService.current.name === 'home.enterprisePDPMDashboard') {
            this.isEnterprisePDPMDashboard = true;
        } else if (this.stateService.current.name === 'home.enterpriseDiseaseWatchDashboard') {
            this.isEnterpriseDiseaseWatchDashboard = true;
            this.setFilterOptions(this.stateService.params.dashboardId)
        } else if (this.stateService.current.name === 'home.enterpriseInfectionControlDashboard') {
            this.isEnterpriseInfectionControlDashboard = true;
        } else if (this.stateService.params.category === 'reports-pdpm') {
            if (reportId === this.listStateService.getReportEnumByName('PDPMSummaryRollup').Id) {
                this.isPDPMSummaryRollup = true;
            }
        } else if (this.stateService.params.category === 'home-health-proact-hs') {
            this.isHomeHealthProactHSDashboard = true;
        } else if (this.stateService.params.category === 'home-health-proact') {
            this.isHomeHealthProactDashboard = true;
        } else if (this.stateService.current.name === 'home.infectionControl') {
            this.isInfectionControlDashboard = true;
        } else if (this.stateService.current.name === 'home.userLoginReport') {
            this.isUserLoginReport = true;
        } else if (this.stateService.current.name === 'home.reportDashboard') {
          const selectedReport = this.selectedChartStateService.getSelectedReport();
          const reportId = selectedReport ? selectedReport.reportId : null;
          if (reportId !== null && (reportId === this.listsStateService.getReportEnumByName('ActiveCOVID19Diagnosis').Id)
          ) {
              return true;
            }
        } else if (this.stateService.current.name === 'home.enterpriseMedicalDirectorDashboard') {
            this.isEnterpriseMedicalDirectorDashboard = true;
        } else {
            this.filterService.isQMAverageDrillDown.set(false);
        }
        this.setCategoriesData();
        if (this.isKeywordSearch) {
            this.setTypesData();
            this.setCheckOnlyDischarge();
        }

        if (this.isImmunizationReport) {
            this.setCheckIncludeDischarge();
        }

        if (this.isQM || this.isQMCount || this.isQMSummary) {
            this.setQMTypeData();
        }
        this.setUnitPayers();
        this.getInitialDates(this.isQM, this.isQMCount, this.isQMSummary);
    }

    applyFilter() {
        const selectedReport = this.selectedChartStateService.getSelectedReport();
        const reportId = selectedReport ? selectedReport.reportId : this.filterStateService.getFilter().ReportId;
        this.filterSettings = {
            StartDate: this.useSingleDate() ? this.filterDate : this.startDate,
            EndDate: this.useSingleDate() ? this.filterDate : this.endDate
        };

        this.lookDataService.validateDate(this.useSingleDate(), this.filterSettings.StartDate,
            this.filterSettings.EndDate, reportId).subscribe((response) => {
                if (response.Message !== '') {
                    this.toasterService.error('Invalid date in filter', response.Message);
                    return;
                } else {
                    this.filterSettings.UserTimeZoneId = this.userStateService.getLoggedInUser().TimeZoneId;
                    this.filterSettings.Units = this.getUnitIdCSV(this.selUnit);
                    this.filterSettings.Payers = this.getPayerIdCSV(this.selPayer);
                    this.filterSettings.QMTypeIDs = this.getQmTypeIdCSV(this.selQMType);
                    this.filterSettings.Type = this.getTypeIdCSV(this.selType);
                    this.filterSettings.Categories = this.getCategoryIdCSV(this.selCategories);
                    this.filterSettings.MedClasses = this.getMedClasses(this.selMedClasses);
                    this.filterSettings.DiagnosisTypes = this.getDiagnosisTypes(this.selDiagnosisTypes);
                    this.filterSettings.VaccineTypes = this.getVaccineTypes(this.selVaccineTypes);
                    this.filterService.isDefault.set(false);
                    this.filterSettings.OrganizationId = this.selectedOrganization.OrganizationId;
                    this.previousOrg = this.getSelectedOrganizationForFilter();
                    this.filterService.isFilterApplied.set(true);

                    if (this.isMultipleFacilitySelection) {
                        if (this.selMultiFacs.length === 0) {
                            this.filterService.organizations.set(this.listStateService.getUserFacilities(true)).subscribe(orgs => {
                                this.applyFilterOnData();
                            });
                        } else {
                            this.filterService.organizations.set(this.selMultiFacs).subscribe(orgs => {
                                this.applyFilterOnData();
                            });
                        }
                    } else if (this.isEnterpriseDashboard()) {
                        this.filterService.selectedEnterpriseOrganization.set(this.selectedOrganization).subscribe(orgs => {
                            if (this.checkRequiredFeaturesAccess(this.filterService.selectedEnterpriseOrganization.get())) {
                                this.applyFilterOnData();
                            }
                        });
                        if (this.isEnterpriseDiseaseWatchDashboard) {
                            this.filterSettings.DateRange = this.selDateRange;
                        }
                        else if (this.isEnterpriseInfectionControlDashboard) {
                            this.filterSettings.DateRange = this.selDateRange;
                        }
                    } else if (this.isHHProActDashboard()) {
                        this.filterService.selectedHhFacility.set(this.selectedOrganization).subscribe(orgs => {
                            if (this.checkRequiredFeaturesAccess(this.filterService.selectedHhFacility.get())) {
                                this.applyFilterOnData();
                            }
                        });
                    } else {
                        this.filterService.organizations.set([this.selectedOrganization]).subscribe(orgs => {
                            if (this.checkRequiredFeaturesAccess(this.filterService.organizations.getFirstOrDefault())) {
                                this.applyFilterOnData();
                            }
                        });
                    }
                    this.filterStateService.setFilter(this.filterSettings);

                }
            });
    }

    private checkRequiredFeaturesAccess(currentOrganization: OrganizationModel): boolean {
        if (this.previousOrg) {
            if (this.stateService.current.data.requiresFeatures && this.stateService.current.data.requiresFeatures.length > 0) {
                if (!this.featureService.isRequiredFeaturesEnabledForFacility([currentOrganization], this.stateService.current.data.requiresFeatures)) {
                    // get out of here!!!
                    this.navigationService.navigateToDashboard(currentOrganization);
                    return false;
                }
            } else {
                if (this.previousOrg.LandingPageRoute !== null && currentOrganization.LandingPageRoute !== this.previousOrg.LandingPageRoute) {
                    // get out of here!!!
                    this.navigationService.navigateToDashboard(currentOrganization);
                    return false;
                }
            }
            return true;
        }
        return false;
    }
    private applyFilterOnData() {
        if (this.isQM) {
            this.filterSettings.DayRange = 7;
            this.filterSettings.QMTypeIDs = this.getQmTypeIdCSV(this.selQMType);
            this.filterSettings.ShortStay =
                this.selStay !== null && this.selStay.id === 1 ? true : false;

            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();

        } else if (this.isQMCount || this.isQMSummary) {
            this.filterSettings.DayRange = 7;
            this.filterSettings.QMTypeIDs =
                ((this.selQMType && this.selQMType.length > 0)
                    ? this.getQmTypeIdCSV(this.selQMType)
                    : (this.filterStateService.getFilterSettings().QMTypeIDs
                        ? this.filterStateService.getFilterSettings().QMTypeIDs
                        : null));

            if (this.filterService.isQMAverageDrillDown.get()) {
                this.filterSettings.ShortStay =
                    this.selStay !== null && this.selStay.id === 1 ? true : false;
            }

            if (this.isQMSummary) {
                this.filterStateService.setFilter(this.filterSettings, true);
            } else {
                this.filterStateService.setFilter(this.filterSettings, true);
            }
            this.reload.emit();


        } else if (this.isTwentyFourHour) {
            this.filterSettings.Categories = this.getCategoryIdCSV(this.selCategories);
            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();

        } else if (this.isKeywordSearch) {
            this.filterSettings.Type = this.getTypeIdCSV(this.selType);
            this.filterSettings.OnlyDischargedResidents = this.chkOnlyDischarged;
            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();

        } else if (this.isImmunizationReport) {
            this.filterSettings.IncludeDischargedResidents = this.chkIncludeDischarged;
            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();

        } else if (this.isMultipleFacilitySelection) {
            this.filterSettings.UserStatuses = this.selUserStatus;
            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();
        } else if (this.isUserLoginReport) {
            this.filterSettings.UserStatuses = this.selUserStatus;
            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();
        } else {

            if (!this.enableTime) {
                this.filterSettings.StartDate =
                    moment(this.filterSettings.StartDate).startOf('day').toDate();
                this.filterSettings.EndDate =
                    moment(this.filterSettings.EndDate).startOf('day').toDate();
            }

            this.filterStateService.setFilter(this.filterSettings, true);
            this.reload.emit();
        }
        const loadFilterSettings = this.filterStateService
            .getFilterSettings(this.isQM, this.isResidentDashboard);
        this.utilizationMetricsService.recordFilter();
    }
    subscribeFilterState() {

        this.subscriptions.push(this.uiNotifierService.getUIEvents().
            pipe(filter(notificationEvent =>
                notificationEvent && notificationEvent.type === UIEventTypes.FilterUpdate))
            .subscribe(event => {
                if (event) {
                    this.startDate = new Date(this.filterStateService
                        .getFilterSettings(this.isQM, this.isResidentDashboard).StartDate);
                    this.endDate = new Date(this.filterStateService.getFilterSettings(this.isQM, this.isResidentDashboard).EndDate);
                    this.filterDate = new Date(this.filterStateService.
                        getFilterSettings(this.isQM, this.isResidentDashboard).EndDate);
                    this.setUnitPayers();
                }

            }));
        this.subscriptions.push(this.uiNotifierService.getUIEvents().
            pipe(filter(notificationEvent =>
                notificationEvent && notificationEvent.type === UIEventTypes.ClearFilters))
            .subscribe((event: PortalUIEvent) => {
                if (event) {
                    switch (event.value.type) {
                        case Filters.Units:
                            this.selUnit = [];
                            break;
                        case Filters.Payers:
                            this.selPayer = [];
                            break;
                        case Filters.Categories:
                            this.selCategories = [];
                            break;
                        case Filters.Types:
                            this.selType = [];
                            break;
                        case Filters.QmTypes:
                            this.selQMType = [];
                            break;
                        case Filters.OnlyDischargedResidents:
                            this.chkOnlyDischarged = false;
                            break;
                        case Filters.IncludeDischargedResidents:
                            this.chkIncludeDischarged = false;
                            break;
                        case Filters.DateRange:
                            this.selDateRange = undefined;
                            break;
                        case Filters.MedClasses:
                            this.selMedClasses = [];
                        case Filters.DiagnosisTypes:
                          this.selDiagnosisTypes = [];
                        case Filters.VaccineTypes:
                          this.selVaccineTypes = [];
                      }
                    this.logRemoveFilterSettings();
                }
            }));

        this.subscriptions.push(this.uiNotifierService.getUIEvents().
            pipe(filter(notificationEvent =>
                notificationEvent && notificationEvent.type === UIEventTypes.ChangeFacility))
            .subscribe(event => {
                if (event) {
                    this.facilityChangeEvent(event.value);
                }
            })
        );

        this.subscriptions.push(this.listsStateService.getUseOrganizationsStream()
            .subscribe(organizations => {
                this.facility = this.getFacilitiesForDropdown(this.stateService.current.name)
            }));
    }

    changeFacility() {
        const filterSettings = this.filterStateService.getFilter();
        filterSettings.Units = null;
        filterSettings.Payers = null;
        filterSettings.MedClasses = null;
        filterSettings.DiagnosisTypes = null;
        filterSettings.VaccineTypes = null;
        this.filterStateService.setFilter(filterSettings);

        this.selUnit = [];
        this.selPayer = [];
        this.selMedClasses = [];
        this.selDiagnosisTypes = [];
        this.selVaccineTypes = [];

        this.facilityWiseUnitAndPayerSelected();
    }

    facilityWiseUnitAndPayerSelected() {
        if (this.isMultipleFacilitySelection) {
            if (this.selMultiFacs.length > 0) {
                this.setPayerData(this.selMultiFacs);
                this.setUnitDataForMultiFacilties(this.selMultiFacs);
            } else {
                this.setPayerData(this.listsStateService.getUserFacilities(true));
                this.setUnitDataForMultiFacilties(this.listStateService.getUserFacilities(true));
            }
        } else {
            this.setUnitDataForMultiFacilties([this.selectedOrganization]);
            this.setPayerData([this.selectedOrganization]);
            this.setCategoriesData();
            this.setMedClasses();
            this.setDiagnosisTypes();
            this.setVaccineTypes();
        }
    }

    logRemoveFilterSettings() {
        const filterSettings = this.filterStateService.getFilterSettings(this.isQM);
        this.utilizationMetricsService.recordFilter();
    }

    facilityChangeEvent(org: OrganizationModel) {

        this.selectedOrganization = org;
        if (!this.isHealthSystem && !this.isUtilizationScore && !this.isUserLoginReport) {
            this.changeFacility();
        }
        this.applyFilter();
    }

    setUnitPayers() {

        if (this.isQM) {
            this.qmTypes = this.listStateService.getQMs();
            this.selStay = this.selStay ? this.selStay : this.listStateService.getStay()[0];
        }

        if (this.isQMCount || this.isQMSummary) {
            this.qmTypes = this.listStateService.getQMs();
        }

        if (this.filterService.isQMAverageDrillDown.get()) {
            const selectedStay = this.selStay !== null && this.selStay.id === 1 ? true : false;
            this.selStay = selectedStay ? this.listStateService.getStay()[0] : this.listStateService.getStay()[1];
        }
        this.qmTypes = this.listStateService.getQMs();
    }

    getFacilitiesForDropdown(route) {
        switch (route) {
            case 'home.hs': {
                return this.getUserFacilitiesWithFeatureEnabled('HealthSystem');
            }

            case 'home.homeHealthProactDashboard': {
                return this.listStateService.getUserHHFacility();
            }

            case 'home.utilizationScoreCard':
            case 'home.userLoginReport':
            {
                return this.listStateService.getUserOrganizations();
            }
            default:
                return this.listStateService.getUserFacilities();
        }
    }

    getInitialDates(isQM, isQMCount, isQMSummary) {

        const initFilterSettings =
            this.filterStateService.getFilterSettings(isQM, this.isResidentDashboard);
        this.filterDate = new Date(moment(initFilterSettings.EndDate).toDate());
        this.endDate = new Date(moment(initFilterSettings.EndDate).toDate());
        this.startDate = new Date(moment(initFilterSettings.StartDate).toDate());

        if (!this.enableTime) {
            this.startDate = moment(this.startDate).startOf('day').toDate();
            this.endDate = moment(this.endDate).startOf('day').toDate();
        }
    }

    isHealthSystemFun() {
        return (this.stateService.current.name === 'home.hs' || this.stateService.params.category === 'hs');
    }

    isEnterpriseDashboard() {
        return this.stateService.params.category && this.stateService.params.category.includes('enterprise');
    }

    isHHProActDashboard() {
        return (this.stateService.current.name === 'home.homeHealthProactDashboard');
    }

    setUnitDataForMultiFacilties(organizations: OrganizationModel[]) {
        this.isMultiFacilityUnit = this.isEnterpriseDashboard() || this.isMultipleFacilitySelection;
        this.lookDataService.getUnitsByOrganizations(organizations).subscribe((response) => {
            this.units = response;
            this.listStateService.setUnits(this.units);
            this.setSelectedUnitsForDashboard(this.units);
        });
    }

    setMedClasses(){
        this.lookDataService.getMedClasses().subscribe((response)=>{
            this.medClasses = response?.map(val => { return { Key: "MedClass", Value: val}; } );
            this.listStateService.setMedClasses(this.medClasses);
            this.setSelectedMedClassesForDashboard(this.medClasses);
        });
    }
    setSelectedMedClassesForDashboard(medClasses: MedClass[]) {
        let filteredMedClasses = this.filterStateService.getFilter().MedClasses;
        if(filteredMedClasses) {
            filteredMedClasses = list(filteredMedClasses);
            this.selMedClasses = list(medClasses).Where(s => filteredMedClasses.Any(t => t == s.Value)).ToArray()
        } else {
            this.selMedClasses = [];
        }
    }

    setVaccineTypes() {
        this.lookDataService.getVaccineTypes().subscribe((response)=>{
          this.vaccineTypes = response?.map(val => { return { Description: val.Description, Value: val.Value } }); 
          this.listStateService.setVaccineTypes(this.vaccineTypes);
          this.setVaccineTypesForDashboards(this.vaccineTypes);
        });
    }

    setVaccineTypesForDashboards(vaccineTypes: VaccineType[]){
      let filteredVaccineTypes = this.filterStateService.getFilter().VaccineTypes;
      if (filteredVaccineTypes) {
        filteredVaccineTypes = list(filteredVaccineTypes);
        this.selVaccineTypes = list(vaccineTypes).Where(s => filteredVaccineTypes.some(t => t == s.Value)).ToArray();
      } else {
        this.selVaccineTypes = [];
      }
    }

    groupingHelper(item) {
      return "1";
    }

    setDiagnosisTypes(){
      this.lookDataService.getDiagnosisTypes().subscribe((response)=>{
          this.diagnosisTypes = response?.map(val => { return { Key: "DiagnosisType", Value: val}; } );
          this.listStateService.setDiagnosisTypes(this.diagnosisTypes);
          this.setSelectedDiagnosisTypesForDashboard(this.diagnosisTypes);
      });
  }
  setSelectedDiagnosisTypesForDashboard(diagnosisTypes: DiagnosisType[]) {
      let filteredDiagnosisTypes = this.filterStateService.getFilter().DiagnosisTypes;
      if(filteredDiagnosisTypes) {
        filteredDiagnosisTypes = list(filteredDiagnosisTypes);
          this.selDiagnosisTypes = list(diagnosisTypes).Where(s => filteredDiagnosisTypes.Any(t => t == s.Value)).ToArray()
      } else {
          this.selDiagnosisTypes = [];
      }
  }

  setSelectedUnitsForDashboard(units: FacilityUnit[]) {
        let filteredUnits = this.filterStateService.getFilter().Units;
        if (filteredUnits) {
            filteredUnits = list(filteredUnits.split(',').map(Number));
            this.selUnit = list(units).Where(a => filteredUnits.Any(t => t === a.FacilityUnitId)).ToArray();
        } else {
            this.selUnit = [];
        }
    }

    setPayerData(organizations: OrganizationModel[]) {

        this.lookDataService.getPayers(organizations).subscribe((response) => {
            this.payers = response;
            this.listStateService.setPayers(this.payers);
            this.setSelectedPayersForDashboard(this.payers);
            this.payers.map(function (x) {
                x.FacIdAsStringForFiltering = x.FacId.toString();
                return x;
            });

        });
    }

    setSelectedPayersForDashboard(payers: Payer[]) {
        let filteredPayers = this.filterStateService.getFilter().Payers;

        if (filteredPayers && filteredPayers !== '') {
            filteredPayers = list(filteredPayers.split(',').map(Number));
            this.selPayer = list(payers).Where(a => filteredPayers.Any(t => t === a.OrigPayerId)).ToArray();
        } else {
            if (this.selectedChartStateService.getSelectedReport() && this.selectedChartStateService.getSelectedReport().reportId == this.listsStateService.getReportEnumByName('PDPMWorksheet').Id) {
            this.selPayer = payers.filter(p => p.PayerTypeId == 4 || p.PayerTypeId == 5);
            this.filterSettings = {
              StartDate: this.useSingleDate() ? this.filterDate : this.startDate,
              EndDate: this.useSingleDate() ? this.filterDate : this.endDate,
              Payers: this.getPayerIdCSV(this.selPayer)
            };
            this.filterService.isDefault.set(false);
            this.filterService.isFilterApplied.set(true);
            this.applyFilterOnData();
          }
          else {
            this.selPayer = []
          }
        }
    }

    setQMTypeData() {
        this.qmReportService.getQMTypes().subscribe((response) => {
            const groupId = 1;
            response.forEach(element => {
                element['groupId'] = groupId;
            });
            this.qmTypes = response;
            this.listStateService.setQMs(this.qmTypes);
            this.setQMType(this.qmTypes);
        });
    }

    setQMType(qmType: QMType[]) {
        let filteredQMType = this.filterStateService.getFilter().QMTypeIDs;
        if (filteredQMType) {
            filteredQMType = list(filteredQMType.split(',').map(Number));
            this.selQMType = list(qmType).Where(a => filteredQMType.Any(t => t === a.QMTypeId)).ToArray();
        } else {
            this.selQMType = [];
        }
    }

    setCategoriesData() {
        if (this.isTwentyFourHour) {
            this.lookDataService.getCategories().subscribe((response) => {
                this.categories = response;
                this.listStateService.setCategories(this.categories);
                this.setCategoryDataForFilter(this.categories);
            });
        }
    }

    setCategoryDataForFilter(category: Category[]) {
        let filteredCategories = this.filterStateService.getFilter().Categories;
        if (filteredCategories) {
            filteredCategories = list(filteredCategories.split(','));
            this.selCategories = list(category).Where(a => filteredCategories.Any(t => t === a.TagId)).ToArray();
        } else {
            this.selCategories = [];
        }
    }

    setSelectedOrganization() {
        this.selectedOrganization = this.getSelectedOrganizationForFilter();
    }

    getSelectedOrganizationForFilter(): OrganizationModel {
        if (this.isEnterpriseDashboard()) {
            return this.filterStateService.getEnterpriseFromFilterOrDefault();
        } else if (this.isHHProActDashboard()) {
            return this.filterStateService.getHHFacilityFromFilterOrDefault();
        } else {
            return this.filterService.organizations.getFirstOrDefault();
        }
    }

    getUnitIdCSV(units: FacilityUnit[]): string {
        let unitIdCSV = null;
        if (units && units.length > 0) {
            unitIdCSV = list(units).Select(a => a.FacilityUnitId).ToArray().join(',');
        }
        return unitIdCSV;
    }

    getMedClasses(medClasses: MedClass[]): string[] {
        let medClassesList = null;
        if(medClasses && medClasses.length>0)
        {
            medClassesList = list(medClasses).ToArray().map(_ => _.Value);
        }
        return medClassesList;
    }

    getDiagnosisTypes(diagnosisTypes: DiagnosisType[]): string[] {
      let diagnosisTypesList = null;
      if(diagnosisTypes && diagnosisTypes.length>0)
      {
        diagnosisTypesList = list(diagnosisTypes).ToArray().map(_ => _.Value);
      }
      return diagnosisTypesList;
  }

    getVaccineTypes(vaccineTypes: VaccineType[]): string[] {
      let vaccineTypesList = null;
      if (vaccineTypes && vaccineTypes.length > 0)
      {
        vaccineTypesList = list(vaccineTypes).ToArray().map(_ => _.Value);
      }
      return vaccineTypesList;
    }

  getPayerIdCSV(payers: Payer[]): string {
        let payerIdCSV = null;
        if (payers && payers.length > 0) {
            payerIdCSV = list(payers).Select(a => a.OrigPayerId).ToArray().join(',');
        }
        return payerIdCSV;
    }
    getQmTypeIdCSV(qmTypes: QMType[]): string {
        let qmTypeIdCSV = null;
        if (qmTypes && qmTypes.length > 0) {
            qmTypeIdCSV = list(qmTypes).Select(a => a.QMTypeId).ToArray().join(',');
        }
        return qmTypeIdCSV;
    }
    getCategoryIdCSV(categories: Category[]): string {
        let CategoriesIdCSV = null;
        if (categories && categories.length > 0) {
            CategoriesIdCSV = list(categories).Select(a => a.TagId).ToArray().join(',');
        }
        return CategoriesIdCSV;
    }
    setTypesData() {
        let filteredTypes = this.filterStateService.getFilter().Type;
        if (filteredTypes) {
            filteredTypes = list(filteredTypes.split(','));
            this.selType = list(this.type).Where(a => filteredTypes.Any(t => t === a.id)).ToArray();
        } else {
            this.selType = [];
        }
    }
    getTypeIdCSV(types: SelectOption[]): string {
        let typeIdCSV = null;
        if (types && types.length > 0) {
            typeIdCSV = list(types).Select(a => a.id).ToArray().join(',');
        }
        return typeIdCSV;
    }
    setCheckOnlyDischarge(): void {
        const filteredTypesCheckOnlyDischarge = this.filterStateService.getFilter().OnlyDischargedResidents;
        if (filteredTypesCheckOnlyDischarge) {
            this.chkOnlyDischarged = filteredTypesCheckOnlyDischarge;
        }
    }
    setCheckIncludeDischarge(): void {
         const filteredTypesCheckIncludeDischarge = this.filterStateService.getFilter().IncludeDischargedResidents;
         if (filteredTypesCheckIncludeDischarge) {
             this.chkIncludeDischarged = filteredTypesCheckIncludeDischarge;
         }
    }

    OrgnizationsAndStatusSelected() {
        if (this.filterService.isFilterApplied.get()) {
            if (this.filterStateService.getFilter()) {
                const filterSettingsValue = this.filterStateService.getFilter();

                if (filterSettingsValue.Organizations) {
                    const orgLists = filterSettingsValue.Organizations as OrganizationModel[];
                    this.selMultiFacs = list(this.listStateService.getUserFacilities())
                        .Where(a => list(orgLists)
                            .Any(f => f.OrganizationId === a.OrganizationId)).ToArray();
                    this.filterService.isFilterApplied.set(true);

                    if (filterSettingsValue.UserStatuses) {
                        this.selUserStatus = list(this.userStatus)
                            .Where(w => list(filterSettingsValue.UserStatuses).Any(a => a === w.id)).ToArray();
                        filterSettingsValue.UserStatuses = this.selUserStatus;
                    }
                }
                this.filterStateService.setFilter(filterSettingsValue);
            }
        }

    }
    setFilterOptions(dashboardId: number) {
        if (this.listStateService.getFilterOptions()) {
            this.setDateRangesData();
        } else {
            this.lookDataService.getFilterOptions(dashboardId).subscribe((response) => {
                const filterOptions: FilterOptions = {
                    DateRanges: this.getFilterOptionsByFilterType(response, this.rtmsConstantService.dashboardFilterTypes.DateRange)
                } as FilterOptions;
                this.listStateService.setFilterOptions(filterOptions);
                this.setDateRangesData();
            });
        }
    }

    setDateRangesData() {
        this.dateRanges = this.listStateService.getFilterOptions().DateRanges;
        this.selDateRange = this.filterStateService.getFilter().DateRange ?
            this.filterStateService.getFilter().DateRange : this.dateRanges[0].FilterOptionId;
        this.filterStateService.getFilter().DateRange = this.selDateRange;
    }

    getFilterOptionsByFilterType(response: DashboardFilterOption[], filterType: string): DashboardFilterOption[] {
        return _.filter(response, (option) => {
            return option.FilterType === filterType;
        });
    }

    setReportFlags() {
        let dashboardId = this.listsStateService.getDashboardIdByNameAndCategory(this.stateService.params.dashboardName, this.stateService.params.category);
        const dashboardConfigProps= this.listsStateService.getDashboardById(dashboardId).DashboardConfigProps
        this.isMultipleFacilitySelection = dashboardConfigProps && dashboardConfigProps.MaxFacilityLimit ? true : false;
        const selectedReport = this.selectedChartStateService.getSelectedReport();
        const reportId = selectedReport ? selectedReport.reportId : null;
        this.isSNFAdmissions = this.stateService.params.category === 'reports-rehosp' && reportId === this.listStateService.getReportEnumByName('SNFAdmissions').Id;
        this.isSNFDischarges = this.stateService.params.category === 'reports-rehosp' && reportId === this.listStateService.getReportEnumByName('SNFDischarges').Id;
        this.isInfectionSurveillance = this.stateService.params.category === 'reports-infection-control' && reportId === this.listStateService.getReportEnumByName('InfectionSurveillance').Id;
        this.isResidentList = this.stateService.params.category === 'reports-rehosp' && reportId === this.listStateService.getReportEnumByName('ResidentList').Id;
        this.isPPSReport = this.stateService.params.category === 'reports-financial' && reportId === this.listStateService.getReportEnumByName('PPS').Id;
        this.isAdvanceCarePlanningReport = this.stateService.params.category === 'reports-clinical' && reportId === this.listStateService.getReportEnumByName('AdvanceCarePlanning').Id;
        this.isPortalUsage = this.stateService.current.name === 'home.portalUsageReport';
        this.isPDPMSummaryRollup = this.stateService.params.category === 'reports-pdpm' && reportId === this.listStateService.getReportEnumByName('PDPMSummaryRollup').Id;
        this.isTwentyFourHour = this.stateService.params.category === 'reports-clinical' && reportId === this.listStateService.getReportEnumByName('TwentyFourHourReport').Id;
        this.OrgnizationsAndStatusSelected();
        if (this.isMultipleFacilitySelection) {
            this.filterService.organizations.set(this.listStateService.getUserFacilities(true));
        }
        if(this.isPortalUsage){
            this.userStatus = this.userStatus.filter(item => item.label !== "Disabled" );
        }
    }

    useSingleDate(): boolean {
        const location = this.stateService.current.name;
        if (location === 'home.financial') {
            return true;
        } else if (location === 'home.clinical') {
            return true;
        } else if (location === 'home.utilizationScoreCard' || location === 'home.userLoginReport') {
            return false;
        } else if (location === 'home.reportDashboard') {
            const selectedReport = this.selectedChartStateService.getSelectedReport();
            const reportId = selectedReport ? selectedReport.reportId : null;
            if (reportId !== null && (reportId === this.listsStateService.getReportEnumByName('CARDDetail').Id ||
                reportId === this.listsStateService.getReportEnumByName('PPS').Id ||
                reportId === this.listsStateService.getReportEnumByName('PDPMWorksheet').Id ||
                reportId === this.listsStateService.getReportEnumByName('ResidentList').Id ||
                reportId === this.listsStateService.getReportEnumByName('ActiveCOVID19Diagnosis').Id ||
                reportId === this.listsStateService.getReportEnumByName('VaccineSurveillance').Id)

            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    public getUserFacilitiesWithFeatureEnabled(feature: string): Array<OrganizationModel> {
        const filteredFacilities = [];

        this.listsStateService.getUserFacilities().forEach(facility => {
            if (this.featureService.isFeatureEnabledForFacility(facility, feature)) {
                filteredFacilities.push(facility);
            }
        });
        return filteredFacilities;
    }

    canFilterAsOfDate() : boolean {
        return this.useSingleDate() 
            && !this.isEnterprisePDPMDashboard 
            && !this.isHealthSystem 
            && !this.isUtilizationScore 
            && !this.isEnterpriseProactDashboard
            && !this.isPortalUsage 
            && !this.isResidentDashboard 
            && !this.isEnterpriseDiseaseWatchDashboard 
            && !this.isEnterpriseInfectionControlDashboard
            && !this.isAdvanceCarePlanningReport 
            && !this.isHomeHealthProactDashboard 
            && !this.isHomeHealthProactHSDashboard 
            && !this.isUserLoginReport 
            && !this.isEnterpriseMedicalDirectorDashboard;
    }

    canFilterByStartEndDate(): boolean {
        return !this.useSingleDate() && !this.isAdvanceCarePlanningReport;
    }

    canFilterByMultipleFacilities(): boolean {
        return this.isMultipleFacilitySelection;
    }

    canFilterByDateRange(): boolean {
        return this.isEnterpriseDiseaseWatchDashboard;
    }

    canFilterByStatus(): boolean {
        return this.isPortalUsage || this.isUserLoginReport
    }

    canFilterByFacility(): boolean {
        return !this.isHealthSystem 
            && !this.isPortalUsage 
            && !this.isUtilizationScore
            && !this.isEnterpriseProactDashboard 
            && !this.isEnterpriseFinancialDashboard 
            && !this.isEnterpriseClinicalDashboard
            && !this.isEnterprisePDPMDashboard 
            && !this.isEnterpriseDiseaseWatchDashboard 
            && !this.isEnterpriseInfectionControlDashboard 
            && !this.isHomeHealthProactDashboard 
            && !this.isHomeHealthProactHSDashboard 
            && !this.isUserLoginReport 
            && !this.isEnterpriseMedicalDirectorDashboard 
            && !this.isMultipleFacilitySelection
    }

    canFilterByOrganization(): boolean {
        return this.isUtilizationScore || this.isUserLoginReport;
    }

    canFilterByUnit() : boolean {
        return !this.isHealthSystem 
            && !this.isUtilizationScore 
            && !this.isEnterpriseProactDashboard 
            && !this.isPortalUsage 
            && !this.isInfectionSurveillance 
            && !this.isEnterpriseDiseaseWatchDashboard 
            && !this.isSNFAdmissions 
            && !this.isSNFDischarges 
            && !this.isPDPMSummaryRollup 
            && !this.isEnterpriseInfectionControlDashboard 
            && !this.isHomeHealthProactDashboard 
            && !this.isUserLoginReport 
            && !this.isHomeHealthProactHSDashboard 
            && !this.isEnterpriseMedicalDirectorDashboard
    }

    canFilterByPayer() : boolean {
        return !this.isUtilizationScore 
            && !this.isPortalUsage 
            && !this.isInfectionSurveillance 
            && !this.isEnterpriseDiseaseWatchDashboard 
            && !this.isUserLoginReport;
    }

    canFilterByQMType(): boolean {
        return this.isQM 
            || this.isQMCount 
            || this.isQMSummary;
    }

    canFilterByStayLength(): boolean {
        return (this.isQM && !this.isQMCount && !this.isQMSummary) 
            || (this.filterService.isQMAverageDrillDown.get())
    }

    canFilterByCategory(): boolean {
        return this.isTwentyFourHour;
    }

    canFilterByType(): boolean {
        return this.isKeywordSearch;
    }

    canFilterByDischargedResidents(): boolean {
        return this.isKeywordSearch  
            && !this.isKeywordDischarge;
    }

    canIncludeDischargedResidents(): boolean {
        return this.isImmunizationReport;
    }

    canFilterByMedicationType(): boolean {
        return this.isEnterpriseInfectionControlDashboard 
            || this.isInfectionControlDashboard;
    }

    canFilterByDiagnosisType(): boolean {
        return this.isCovidReport;
    }

    canFilterByVaccineType(): boolean {
        return this.isImmunizationReport;
    }
}

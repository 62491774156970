import { Pipe, PipeTransform } from '@angular/core';
import { filter } from 'lodash';
import { UserMessageModel } from '../models/user-message.model';

@Pipe({
    name: 'sentMessageFilter',
    pure: false
})
export class SentMessageFilterPipe implements PipeTransform {

    transform(array: UserMessageModel[], data: string): any {

        if (data) {
            return filter( array, row => {
                return row.Subject.toLowerCase().includes(data.toLowerCase())
                || row.ToUserNamesDisplay.toLowerCase().includes(data.toLowerCase())
                || row.Message.toLowerCase().includes(data.toLowerCase())
              });
        }
        return array;
    }
}

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FilterSettings } from '../../models/filter-settings';
import { DashboardDataService } from '../../services/portal/dashboardData.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'rtms-hipps-popup',
  templateUrl: './hipps-popup.component.html',
  styleUrls: ['./hipps-popup.component.scss']
})
export class HippsPopupComponent implements OnInit {

  public _hideIndicator: boolean;
  public showSpinner = false;
  public title: string;
  public hippsData: object[] = [{}];

  @ViewChild('template') public template: TemplateRef<any>;

  constructor(
      private dashboardDataService: DashboardDataService,
      private modalService: NgbModal,
    ) {
  }

  ngOnInit() {
  }

  public showHippsData(hippsCode: string): void {
    this.title = hippsCode
    this.showSpinner = true;

    this.dashboardDataService.getHippsCodeData().subscribe(hippsCodeReferenceData => {
      this.showSpinner = false;
      let ref = JSON.parse(hippsCodeReferenceData);
      let headings = ['PT_OT_Group', 'SLP_Group', 'Nursing', 'NTA_Group', 'Assessment'];
      let chars = hippsCode.split('');
      let i = 0;
      this.hippsData.length = 0;
      for (const heading of headings) {
        var refLookup = ref[heading][chars[i]];
        let hipps = { "hipps": chars[i], "descr": refLookup};
        this.hippsData.push(hipps);
        i++;
      };

      this.modalService.dismissAll();
      this.modalService.open(this.template, {'windowClass': 'hipps-modal'});
    });

  }

  public closeModal() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

}

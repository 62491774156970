<li class="dropdown" *ngIf="queuedReports.length">
    <a href="#" class="dropdown-toggle" data-bs-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
        <i class="fa fa-spinner fa-spin"></i><strong>{{queuedReports.length}} Export<span *ngIf="queuedReports.length > 1">s</span> Queued</strong>&nbsp;<span class="caret"></span>
    </a>
    <ul class="dropdown-menu" style="margin-top: 15px; width: max-content;">
        <li *ngFor="let queuedReport of queuedReports" class="dropdown-header" style="margin-bottom:20px;">
            <div class="pull-left" style="margin-right:5px;"><strong>{{queuedReport.ReportName}}</strong>&nbsp;<small>({{queuedReport.FileType.toUpperCase()}})</small></div>
            <div class="pull-right label" [ngClass]="getStatusClass(queuedReport.Status)"><i *ngIf="queuedReport.Status === statusTypes.Processing" class="fa fa-spinner fa-spin" style="font-size:0.9em;"></i>{{queuedReport.Status}}</div>
        </li>
    </ul>
</li>

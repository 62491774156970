export const loaderWhitelistUrls = [
    'reports/getreports',
    'reports/getreportexecutions',
    'user/admin/users',
    'user/admin/user',
    'user/admin/timezones',
    'user/admin/stafftypes',
    'user/admin/dashboardtypes',
    'userinbox',
    'exportfinancialreport',
    'exportclinicalreport',
    'exportqmreport',
    'exportadminreport',
    'exportrehospreport',
    'exporthealthsystemreport',
    'exportscheduledreport',
    'export-resident',
    'export-pdpm',
    'resident/resident-dashboard-get-all'
];

import { Injectable } from "@angular/core";
import { StateService } from "@uirouter/core";
import { SelectedChartStateService } from "../selected-chart-state.service";

@Injectable()
export class DashboardHelperService {

  constructor(
    private stateService: StateService,
    private selectedChartStateService: SelectedChartStateService
  ) {}

  isEnterpriseDashboard(): boolean {
    let dashboardCategory = this.getDashboardCategory();
    return dashboardCategory && dashboardCategory.includes('enterprise');
  }

  isHSDashboard(): boolean {
    let dashboardCategory = this.getDashboardCategory();
    return dashboardCategory === 'hs' || dashboardCategory === 'reports-hs';
  }

  isHHDashboard(): boolean {
    let dashboardCategory = this.getDashboardCategory();
    return (dashboardCategory === 'hh' 
      || dashboardCategory === 'reports-hh'
      || dashboardCategory === 'home-health-proact-hs');
  }

  isHHProactDashboard(): boolean {
    let dashboardCategory = this.getDashboardCategory();
    return dashboardCategory == "home-health-proact";
  }

  getDashboardCategory(): string {
    if (this.stateService.params.isDetailDashboard) {
      const parentReportForCurrentDashboard = this.selectedChartStateService.getSelectedChartDetails();
      return parentReportForCurrentDashboard.dashboardCategory;
    }
    return this.stateService.params.category;
  }
}
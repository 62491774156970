<div id="wrapper">
    <!--BEGIN PAGE WRAPPER-->
    <div id="page-wrapper" class="page-wrapper">
        <!--BEGIN TITLE & BREADCRUMB PAGE-->
        <div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
            <div class="page-header pull-left">
                <div class="page-title">My Settings</div>
            </div>
            <div class="clearfix"></div>
        </div>
        <!--END TITLE & BREADCRUMB PAGE-->
        <!--BEGIN CONTENT-->
        <div class="page-content alt-background-color">
            <div class="row">
                <div class="col-lg-3">&nbsp;</div>
                <div class="col-lg-6">
                    <form>
                        <h3><b>My Profile</b></h3>
                        <table>
                            <tr>
                                <td class="input-label">
                                    <label class="mrm"><b>First Name</b> :</label>
                                </td>
                                <td>
                                    {{user.FirstName}}
                                </td>
                            </tr>
                            <tr>
                                <td class="input-label">
                                    <label class="mrm"><b>Last Name</b> :</label>
                                </td>
                                <td>
                                    {{user.LastName}}
                                </td>
                            </tr>
                            <tr>
                                <td class="input-label">
                                    <label class="mrm"><b>Email Address</b> :</label>
                                </td>
                                <td>
                                    {{user.EmailId}}
                                </td>
                            </tr>
                            <tr [hidden]="!user.IsRealTimeUser" >
                                <td class="input-label">
                                    <label class="mrm"><b>User Organization</b> :</label>
                                </td>
                                <td>
                                    <ng-select [items]="organizations | async" bindLabel="OrganizationName"
                                        [trackByFn]="trackByFn" [minTermLength]="autoCompleteMinChars"
                                        [loading]="organizationLoading" class="custom"
                                        typeToSearchText="Please enter 3 or more characters"
                                        [typeahead]="organizationInput" [clearable]="false"
                                        name="organization" [(ngModel)]="selectedOrganization"
                                        (change)="selectedOrganization && updateUserOrganization(selectedOrganization.OrganizationId)">
                                        <ng-template ng-label-tmp let-item="item">
                                            <span title="{{item.OrganizationName}} - {{item.OrganizationTypeName}}">
                                                {{item.OrganizationName}} - {{item.OrganizationTypeName}}
                                            </span>
                                          </ng-template>
                                          <ng-template ng-option-tmp let-item="item">
                                            <span title="{{item.OrganizationName}} - {{item.OrganizationTypeName}}">
                                                {{item.OrganizationName}} - {{item.OrganizationTypeName}}
                                            </span>
                                          </ng-template>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr>
                                <td class="input-label">
                                    <label class="mrm"><b>Default Facility</b> :</label>
                                </td>
                                <td>
                                    <ng-select [items]="facility" class="custom" name="facility" [clearable]="false"
                                        bindLabel="OrganizationName" placeholder="[Select Default Facility]"
                                        [(ngModel)]="selectedFacility" (change)="saveFacility()">
                                        <ng-template ng-label-tmp let-item="item">
                                            {{item.OrganizationName}}
                                          </ng-template>
                                          <ng-template ng-option-tmp let-item="item">
                                            {{item.OrganizationName}}
                                          </ng-template>
                                    </ng-select>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"><hr /></td>
                            </tr>
                            <tr *ngIf="hasMessageFeatureAccess && user.EmailId.length">
                                <td class="input-label">
                                    <label class="mrm" title="If enabled, you will receive email notifications when another user sends a message to your Real Time inbox."><b>Inbox Email Notifications</b> :</label>
                                </td>
                                <td>
                                    <ng-toggle name="receiveInboxNotifications" [(ngModel)]="receiveInboxNotifications" (change)="saveInboxNotifications()"></ng-toggle>
                                </td>
                            </tr>
                            <tr *ngIf="has30DayAdmitFeatureAccess">
                                <td class="input-label">
                                    <label class="mrm" title="If enabled, you will only see Residents admitted within the last 30 days."><b>Only show Residents admitted in the last 30 days</b> :</label>
                                </td>
                                <td>
                                    <ng-toggle name="only30DayAdmits" [(ngModel)]="only30DayAdmits" (change)="saveOnly30DayAdmits()"></ng-toggle>
                                </td>
                            </tr>
                        </table>
                        <div [hidden]="user.IsSSO">
                            <hr size="30">
                            <h4>
                                Change Password
                            </h4>
                            <rtms-password-change [passwordChangeIsValid]="passwordChangeIsValid" (keydown.enter)="canHitEnter(passwordChangeIsValid)"
                                [requireCurrentPassword]="requireCurrentPassword" (changed)="passwordValueChanged($event)" #passwordChangeComponent></rtms-password-change>
                            <div class="mb-3 text-center">
                                <br />
                                <button type="submit" class="btn btn-info" (click)="submit()"
                                    [disabled]="isChangingPassword || !passwordChangeIsValid">Change Password &nbsp;<i
                                        class="fa fa-chevron-circle-right"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <!--END CONTENT-->
        <rtms-feedback></rtms-feedback>
        <!--END PAGE WRAPPER-->
    </div>
</div>

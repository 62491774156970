import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/shared/components';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import { User } from 'src/app/shared/models/user';
import { UserMessageModel } from 'src/app/shared/models/user-message.model';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { UserInterfaceStateService } from 'src/app/userInterface/store/services/userInterface-state.service';
import { ComposeMessageComponent } from '../compose-message/compose-message.component';
import { InboxService } from '../inbox.service';
import { MessageDetailComponent } from '../message-detail/message-detail.component';
import { MessageService } from '../message.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { UINotifierService } from 'src/app/shared/services/services-index';
import { UIEventTypes } from 'src/app/shared/enums/ui-event-types';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'rtms-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent extends BaseComponent {

  constructor(
    private _inboxService: InboxService,
    private _userStateService: UserStateService,
    private _toastrService: ToastrService,
    private _userInterfaceStateService: UserInterfaceStateService,
    private _messageService: MessageService,
    private _filtersService: FiltersService,
    private _featureService: FeatureService,
    public uiNotifierService: UINotifierService,
  ) {
    super();
    this.getMessages();
    setInterval(() => {
      if (this._userInterfaceStateService.getToState().name === 'home.inbox') {
        this.getMessages();
      }
    }, this.syncMessageDataIntervalInMilliSecond);

    this.subscriptions.push(this._filtersService.organizations.getOrganizationFeatureStream()
        .subscribe(organizations => {
            if (organizations && organizations.length > 0) {
                const facility = organizations[0];
                this.hasMessageFeatureAccess = this._featureService.isFeatureEnabledForFacility(facility, "Messages");
            }
        })
    );
    this.subscriptions.push(this.uiNotifierService.getUIEvents().
        pipe(filter(notificationEvent =>
            notificationEvent && notificationEvent.type === UIEventTypes.ChangeFacility))
        .subscribe(event => {
            if (event) {
              this.getMessages();
              this.getSentMessages();
            }
        })
    );
  }
  hasMessageFeatureAccess = false;
  showSpinner = false;
  showSentTabSpinner = false;
  messages: Array<UserMessageModel> = [];
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
  @ViewChild('toggleSelection') toggleSelection: ElementRef;
  @ViewChild('messageDetailComponent') messageDetailComponent: MessageDetailComponent;
  @ViewChild('composeMessageComponent') composeMessageComponent: ComposeMessageComponent;
  @ViewChild('confirmDelete') private confirmDelete: ConfirmComponent;
  @ViewChild('confirmDeleteSent') private confirmDeleteSent: ConfirmComponent;
  allSelected = false;
  selectedUserMessages: Array<UserMessageModel> = [];
  selectedUserSentMessages: Array<UserMessageModel> = [];
  userMessages: Array<UserMessageModel> = [];
  sentMessages: Array<UserMessageModel> = [];
  users: Array<User> = [];
  selectAll = false;
  confirmDeleteBody = '';
  userId = this._userStateService.getLoggedInUser().userId;
  @Input() searchText: string = '';
  @Output() searchTextChange = new EventEmitter<string>();
  count = 0;
  messageRecipientIds = [];
  sentMessageQueueIds = [];
  deleteUserMessages: Array<UserMessageModel> = [];
  deleteUserSentMessages: Array<UserMessageModel> = [];
  sortDirection: string = 'desc';
  sortDirectionSent: string = 'desc';
  syncMessageDataIntervalInMilliSecond = 60000;

  

  public ngOnInit() {
    this.getSentMessages();
  }

  public getMessages(): void {
    this.showSpinner = true
    this.messages = [];
    this._inboxService.getMessages().then(
      res => {
        this.showSpinner = false;
        this.messages = res;
        this._userInterfaceStateService.setMessageNotification(this.messages.filter(x => x.DateRead == null).length);
        if (this.sortDirection === 'asc') {
          this.messages.reverse();
        }
      }
    );
  }
  getSentMessages() {
    this.showSentTabSpinner = true
    this.sentMessages = [];
    this._inboxService.getSentMessages().then(
      res => {
        this.showSentTabSpinner = false;
        this.sentMessages = res;
        if (this.sortDirectionSent === 'asc') {
          this.sentMessages.reverse();
        }
        this.sentMessages.forEach(sentMessage => {
          sentMessage.ToUserNamesDisplay = this.getRecipientNames(sentMessage);
        })
      }
    );
  }

  getRecipientNames(userMessage: UserMessageModel): string {
    let recipientNames = [];
    userMessage.MessageRecipients.forEach(r => {
      recipientNames.push(r.Name);
    });
    return recipientNames.join("; ");
  }

  getReadStatusClass(userMessage: UserMessageModel): string {
    let className = "fa-envelope";
    userMessage.MessageRecipients.forEach(r => {
      if (r.DateRead) className = "fa-envelope-open";
    });
    return className;
  }

  getReadByNames(userMessage: UserMessageModel): string {
    let readByNames = [];
    userMessage.MessageRecipients.forEach(r => {
      if (r.DateRead) readByNames.push(r.Name);
    });
    if (readByNames.length)
      return "Read by " + readByNames.join("; ");
    else
      return "Unread";
  }

  onSearchTextChanged() {
    this.searchTextChange.emit(this.searchText);
  }

  onMessageSelectionChange(userMessage: UserMessageModel, isChecked: boolean) {
    if (isChecked) {
      if (!(this.selectedUserMessages.some(x => x.MessageQueueId == userMessage.MessageQueueId))) {
        this.selectedUserMessages.push(userMessage);
      }
    } else {
      let lstIndex = this.selectedUserMessages.findIndex(x => x.MessageQueueId == userMessage.MessageQueueId);

      this.checkboxes.forEach((element, index) => {
        if (index == lstIndex) {
          this.selectedUserMessages.splice(lstIndex, 1);
        }
      });
    }
  }

  onSentMessageSelectionChange(userSentMessage: UserMessageModel, isChecked: boolean) {
    if (isChecked) {
      if (!(this.selectedUserMessages.some(x => x.MessageQueueId == userSentMessage.MessageQueueId))) {
        this.selectedUserSentMessages.push(userSentMessage);
      }
    } else {
      let lstIndex = this.selectedUserSentMessages.findIndex(x => x.MessageQueueId == userSentMessage.MessageQueueId);
      
      this.checkboxes.forEach((element, index) => {
        if (index == lstIndex) {
          this.selectedUserSentMessages.splice(lstIndex, 1);
        }
      });
    }
  }

  deleteMessages(): any {
    this.deleteUserMessages = [];
    if (this.selectedUserMessages.length > 0) {
      this.messageRecipientIds = [];
      this.selectedUserMessages.forEach(x => {
        this.messageRecipientIds.push(x.MessageRecipientId);
      });
      this.showConfirmation();
    }
  }

  deleteSentMessages(): any {
    this.deleteUserSentMessages = [];
    if (this.selectedUserSentMessages.length > 0) {
      this.sentMessageQueueIds = [];
      this.selectedUserSentMessages.forEach(x => {
        this.sentMessageQueueIds.push(x.MessageQueueId);
      });
      this.showConfirmation(true);
    }
  }

  delete(): any {
    this.deleteUserMessages = this.deleteUserMessages.length > 0 ? this.deleteUserMessages : this.selectedUserMessages;
    return this._inboxService.deleteMessages(this.messageRecipientIds).then((res) => {
      this._toastrService.success('Message deleted successfully');
      this.hideRow(this.deleteUserMessages);
      this.setUnreadMesssageCount();
      this.messageRecipientIds = [];
      this.deleteUserMessages = []
      this.selectedUserMessages = [];
    });
  }

  deleteSent(): any {
    this.deleteUserSentMessages = this.deleteUserSentMessages.length > 0 ? this.deleteUserSentMessages : this.selectedUserSentMessages;
    return this._inboxService.deleteSentMessages(this.sentMessageQueueIds).then((res) => {
      this._toastrService.success('Message deleted successfully');
      this.hideRowSent(this.deleteUserSentMessages);
      this.sentMessageQueueIds = [];
      this.deleteUserSentMessages = []
      this.selectedUserSentMessages = [];
    });
  }

  openMessage(messageQueueId: number, messageRecipientId: number, isInboxTab = true) {
    if (!this.hasMessageFeatureAccess) return;

    this.userMessages = null;
    this._inboxService.getUserMessage(messageRecipientId).then(
      res => {
        this.userMessages = res;
        this.messageDetailComponent.showModal(this.userMessages, messageRecipientId, messageQueueId, isInboxTab);
      }
    );
    this.showNotification(messageQueueId);
  }
  openComposeMessage() {
    this.composeMessageComponent.showModal("Compose Message");
    ({ backdrop: 'static', keyboard: false })
  }
  replyMessage(messageQueueId: number) {
    this.users = [];
    this.userMessages = [];
    this.userMessages = this.messages.filter(x => x.MessageQueueId == messageQueueId);
    this.users.push(this.userMessages[0].FromUser);
    this.openReplyMessage(this.users);
    this.showNotification(messageQueueId)
  }
  openReplyMessage(user: User[]) {
    this.composeMessageComponent.showReplyMessage(this.userMessages, user);
  }
  private showConfirmation(isSent: boolean = false): void {
    this.confirmDeleteBody = 'Are you sure you want to delete the selected message?';
    if (isSent) {
      this.confirmDeleteSent.Show();
    } else {
      this.confirmDelete.Show();
    }
  }

  public onConfirmDelete(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.delete();
    }
  }

  public onConfirmDeleteSent(isConfirmed: boolean): void {
    if (isConfirmed) {
      this.deleteSent();
    }
  }

  public onMessageSent(userMessage: UserMessageModel): void {
    if (userMessage) {
      this.getSentMessages()
    }
  }

  openReplyToAllMessage(messageQueueId: number) {
    this.userMessages = [];
    this.users = [];
    this.userMessages = this.messages.filter(x => x.MessageQueueId == messageQueueId);
    this.users.push(this.userMessages[0].FromUser);
    this.userMessages[0].MessageRecipients.forEach(x => this.users.push(x.ToUser));
    this.users = this.users.filter(x => x.UserId != this.userId);
    this.openReplyMessage(this.users);
    this.showNotification(messageQueueId)
  }
  showNotification(messageQueueId: number) {
    let message = this.messages.find(x => x.MessageQueueId == messageQueueId);

    if (!message) return;

    if (message.DateRead == null) {
      this._inboxService.readUserMessage(messageQueueId).then(res => {
        message.DateRead = new Date();
        this.setUnreadMesssageCount();
      });
    }
  }

  deleteMessageByMessageRecipientId(message: UserMessageModel) {
    this.deleteUserMessages = [];
    this.deleteUserMessages.push(message);
    this.messageRecipientIds = [];
    this.messageRecipientIds.push(message.MessageRecipientId);
    this.showConfirmation();
  }

  deleteSentMessageByMessageQueueId(message: UserMessageModel) {
    this.deleteUserSentMessages = [];
    this.deleteUserSentMessages.push(message);
    this.sentMessageQueueIds = [];
    this.sentMessageQueueIds.push(message.MessageQueueId);
    this.showConfirmation(true);
  }

  hideRow(deleteUserMessages: UserMessageModel[]) {
    this.setUnreadDeleteMesssageAsRead();
    deleteUserMessages.forEach(x => {
      const index: number = this.messages.findIndex(y => y.MessageQueueId == x.MessageQueueId);
      if (index !== -1) {
        this.messages.splice(index, 1);
      }
    })
    this.deleteUserMessages = [];
  }

  hideRowSent(deleteUserSentMessages: UserMessageModel[]) {
    deleteUserSentMessages.forEach(x => {
      const index: number = this.sentMessages.findIndex(y => y.MessageQueueId == x.MessageQueueId);
      if (index !== -1) {
        this.sentMessages.splice(index, 1);
      }
    })
    this.deleteUserSentMessages = [];
  }

  setUnreadMesssageCount() {
    this._userInterfaceStateService.setMessageNotification(this.messages.filter(x => x.DateRead == null).length);
  }
  setUnreadDeleteMesssageAsRead() {
    this.messageRecipientIds.forEach(x =>
      this.messages.find(m => m.MessageRecipientId == x).DateRead = new Date()
    );
  }
  isChecked(messageQueueId: number) {
    if (this.selectedUserMessages.length > 0) {
      return this.selectedUserMessages.some(x => x.MessageQueueId == messageQueueId);
    }
    return false;
  }
  isCheckedSent(messageQueueId: number) {
    if (this.selectedUserSentMessages.length > 0) {
      return this.selectedUserSentMessages.some(x => x.MessageQueueId == messageQueueId);
    }
    return false;
  }

  hasReportDownload(userMessage: UserMessageModel): boolean {
    return this._messageService.hasReportDownload(userMessage);
  }

  getReport(userMessage: UserMessageModel, $event): void {
    $event.stopPropagation();
    this._inboxService.readUserMessage(userMessage.MessageQueueId).then(res => {
      this.messages.find(x => x.MessageQueueId == userMessage.MessageQueueId).DateRead = new Date();
      this.setUnreadMesssageCount();
    })

    this._messageService.downloadReport(userMessage);
    
  }

  onSortInbox() {
    this.messages.reverse();
    this.sortDirection = this.sortDirection == 'desc' ? 'asc' : 'desc';
  }

  onSortSent() {
    this.sentMessages.reverse();
    this.sortDirectionSent = this.sortDirectionSent == 'desc' ? 'asc' : 'desc';
  }
}

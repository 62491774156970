import { MessageRecipient } from "./message-recipient";
import { User } from "./user";

export class UserMessageModel {
    MessageQueueId: number;
    Subject: string;
    Message: string;
    ParentMessageQueueId?: number;
    FromUserId: number;
    FromUserNameFirst: string;
    FromUserNameLast: string;
    MessageRecipientId: number;
    ToUserId: number;
    DateDeleted?: Date;
    DateRead?: Date;
    ToUserNameFirst: string;
    ToUserNameLast: string;
    DateCreated: Date;
    EntityId?: number;
    EntityType: string;
    FromUser:  User;
    ToUserIds: Array<number>;
    ToUserNames: Array<string>;
    ToUserNamesDisplay: string;
    MessageRecipients: Array<MessageRecipient>;
    IsReplyEnabled: boolean;
    DateCreatedFormatted: string;
    DateReadFormatted: string;
    FullUserName: string;
    MessageDate: string;
    IsScheduleReport: boolean;  
    OrganizationId: number;
    OrganizationName: string;
    ReportId: number;
}
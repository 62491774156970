import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import {of, Subscription} from 'rxjs';
import { LoaderService } from '../services/loader.service';
import { BaseComponent } from '../../components';
import { map, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'rtms-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent extends BaseComponent implements OnInit {
  show = of(true);

  constructor(private loaderService: LoaderService) {
    super();
  }

  ngOnInit() {
    this.show = this.loaderService.loaderState
      .pipe(
        map(Boolean),
        distinctUntilChanged()
      );
  }

}

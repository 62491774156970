import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OrganizationModel, ReportModel } from 'src/app/shared/models/models-index';
import { FileFormatEnum } from 'src/app/shared/enums/enums-index';
import { FiltersService } from '../../../filter/store/services/filters.service';
import { FileSaverService } from 'ngx-filesaver';
import { ListsStateService } from '../../../lists/store/services/lists-state.service';
import { ReportGroups } from 'src/app/shared/enums/report-group';
import { EnvService } from '../../environment/env.service';
import { ReportService } from 'src/app/shared/services/portal/report.service';
import { FilterStateService } from '../filter-state.service';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { RtmsConstantService } from '../rtms-constant.service';
import { ToastrService } from 'ngx-toastr';
import { QueuedReportModel } from '../../models/queued-report.model';
import { Observable, of, Subject } from 'rxjs';

@Injectable()
export class ExportService {
    constructor(
        private _http: HttpClient,
        private _filtersService: FiltersService,
        private _fileSaver: FileSaverService,
        private _listsStateService: ListsStateService,
        private envService: EnvService,
        private filterStateService: FilterStateService,
        private reportService: ReportService,
        private utilizationMetricsService: UtilizationMetricsService,
        private rtmsConstantService: RtmsConstantService,
        private toastrService: ToastrService,
    ) { }

    private exportData = [
        { 'type': 'PDF', 'header': 'application/pdf', 'extension': 'pdf', 'APIEnumValue': '0' },
        { 'type': 'EXCEL', 'header': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'extension': 'xlsx', 'APIEnumValue': '3' },
        { 'type': 'WORD', 'header': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'extension': 'docx', 'APIEnumValue': '4' },
        { 'type': 'CSV', 'header': 'application/csv', 'extension': 'csv', 'APIEnumValue': '1' },
        { 'type': 'DETAILPDF', 'header': 'application/pdf', 'extension': 'pdf', 'APIEnumValue': '5' },
        { 'type': 'DETAILWITHOUTINTERVENTIONSPDF', 'header': 'application/pdf', 'extension': 'pdf', 'APIEnumValue': '6' }
    ];

    queuedReportSubject = new Subject<QueuedReportModel>();
    queuedReport$ = this.queuedReportSubject.asObservable();

    downloadScheduledReport = async (report: ReportModel, filter: any, org: OrganizationModel) => {

        return this.downloadReport(report, 'PDF', null, filter, org, 'exports/exportscheduledreport');
    }

    exportReport(reportId, chartName, type, filteredJson, exportFilter: any): void {
        this.reportService.getReportById(reportId).then(response => {
            this.utilizationMetricsService.recordExports(reportId, chartName, type, exportFilter);

            if (this._filtersService.isEnterpriseDashboard.get() === true) {
                this.exportEnterpriseReport(response, type, filteredJson, exportFilter);
            } else if (this._filtersService.isHSDashboard.get() === true) {
                this.downloadReport(response, type, filteredJson, exportFilter, this.getOrgFromFilters(this._filtersService.selectedHsOrganization.get(), exportFilter));
            } else if (this._filtersService.isHHDashboard.get() === true || this._filtersService.isHHProactDashboard.get() === true) {
                var org = this.getOrgFromFilters(org, exportFilter);
                this.downloadReport(response, type, filteredJson, exportFilter, org);
            } else {
                this.downloadReport(response, type, filteredJson, exportFilter, this._filtersService.organizations.getFirstOrDefault());
            }
            return;
        });
    }

    getOrgFromFilters(org: OrganizationModel, exportFilter: any ): OrganizationModel {
        // This could be called from exportEnterpriseReport as well (but don't refactor out the method since it's called from elsewhere)
        // See also filter-state.service.ts: getHHFromFilterOrDefault()
        // Or even filter-state.ts: getSelectedOrganizationId() ??
        let workOrg = null;
        if (exportFilter) {
            const facilityFilter = exportFilter.DataFilters?.find(f => f.FilterType === this.rtmsConstantService.filterTypes.Facility);
            if (facilityFilter) {
                workOrg = this._listsStateService.getUserOrganizations().find(o => o.OrganizationName === facilityFilter.FilterValue);
            } else {
                workOrg = this._listsStateService.getUserOrganizations().find(o => o.OrganizationId === exportFilter.OrganizationId);
            }
        }

        return workOrg ? workOrg : org;
    }

    exportEnterpriseReport(report: ReportModel, type: string, filteredJson: any, exportFilter: any) {
        let org = this.getOrgFromFilters(this._filtersService.selectedEnterpriseOrganization.get(), exportFilter);
        this.downloadReport(report, type, filteredJson, exportFilter, org);
    }

    downloadReport = async (report: ReportModel, fileFormat: string, filteredJson: any, filter: any, org: OrganizationModel, apiRoute: string = '') => {

        const format = this.exportData.filter(e => e.type.includes(fileFormat.toUpperCase()))[0].APIEnumValue;

        const reportExportRequest = {
            Format: format,
            ReportType: report.ReportId,
            OrganizationId: org.OrganizationId,
            OrganizationName: org.OrganizationName,
            RequestData: filter,
            JsonData: filteredJson,
            EndDate: (filter === undefined || filter.EndDate === undefined) ? null : filter.EndDate
        };

        if (!apiRoute || apiRoute === '') {
            apiRoute = this.getExportRoute(report.ReportGroup, report.ReportId);
        }

        const response = await this._http.post(this.envService.api + apiRoute, reportExportRequest, { responseType: 'arraybuffer' })
            .toPromise();

        if (response.byteLength > 16)
        {
            this.toastrService.success("Your " + report.ReportName + " export has been generated and is being downloaded.", "Export Ready!");
            this.saveFile(response, report.ReportName, fileFormat);
        }
        else
        {
            this.toastrService.warning("Your " + report.ReportName + " export has been queued. You will be notified when it is ready.", "Export Queued...");
            const reportQueueId = parseInt(String.fromCharCode.apply(null, new Uint8Array(response)));
            const queuedReport: QueuedReportModel = {
                ReportQueueId: reportQueueId,
                ReportName: report.ReportName,
                FileType: fileFormat.toUpperCase(),
                Status: 'Queued'
            }
            this.queuedReportSubject.next(queuedReport);
        }
    }

    saveFile = function (data, name, type) {
        const fileData = this.getExportFileData(type);
        const blob = new Blob([data], { type: fileData.header });

        const fileName = (name === undefined ? 'my-file' : this.getFileName(name)) + '.' + fileData.extension;
        this._fileSaver.save(blob, fileName);
    };

    getFileName = function (filename: string) {
        return filename + '-' + this.getTodayMMDDYYYY();
    };

    getTodayMMDDYYYY = function () {
        const today = new Date();
        let dd = today.getDate().toString();
        let mm = (today.getMonth() + 1).toString(); // January is 0!
        const yyyy = today.getFullYear().toString();
        const h = today.getHours().toString();
        const m = today.getMinutes().toString();
        const ss = today.getSeconds().toString();

        if (+dd < 10) {
            dd = '0' + dd;
        }
        if (+mm < 10) {
            mm = '0' + mm;
        }
        return mm + dd + yyyy + h + m + ss;
    };

    getExportFileData = function (exportType: string) {
        for (let i = 0; i < this.exportData.length; i++) {
            const object = this.exportData[i];

            if (exportType.toUpperCase() == object.type || exportType.toLowerCase() == object.header) {
                return object;
            }
        }
    };

    getExportRoute = (group: number, reportId: any): string => {
        let apiRoute = '';
        switch (group) {
            case this._listsStateService.getReportGroupByName(ReportGroups.FinancialReports).Id:
                apiRoute = 'exports/exportfinancialreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.ClinicalReports).Id:
                apiRoute = 'exports/exportclinicalreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.QMReports).Id:
                apiRoute = 'exports/exportqmreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.RTAReports).Id:
                apiRoute = 'exports/exportrehospreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.AdminReports).Id:
                apiRoute = 'exports/exportadminreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.HealthSystem).Id:
                apiRoute = 'exports/exporthealthsystemreport';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.ResidentDashboard).Id:
                apiRoute = 'exports/export-resident';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.PDPMDashboard).Id:
            case this._listsStateService.getReportGroupByName(ReportGroups.PDPMReports).Id:
                apiRoute = 'exports/export-pdpm';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.InfectionControlReports).Id:
                apiRoute = 'exports/export-infectioncontrol';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.DiseaseWatchReports).Id:
                apiRoute = 'exports/export';
                break;
            case this._listsStateService.getReportGroupByName(ReportGroups.HomeHealth).Id:
                apiRoute = 'exports/export-hh';
                break;
            default:
                apiRoute = this.getCustomExportRoute(reportId);
        }

        if (!apiRoute.length) {
            throw new Error('Invalid ReportGroup constant was supplied.');
        }

        return apiRoute;
    };

    getCustomExportRoute = (reportId: any): string => {
        let apiRoute = '';
        switch(reportId) {
            case 9112: // Hospital Transfer Reasons
                apiRoute = 'exports/export-hosp-transfer-reasons';
                break;
            case 9127:
                apiRoute = 'exports/export-enterprise-high-risk-meds';
                break;
            case 9133:
                apiRoute = 'exports/export-hs-high-risk-meds';
                break;
            case 9183:
                apiRoute = 'exports/export-admissions-1000-members';
                break;
            default:
                throw new Error('Custom export route could not be found for report id ' + reportId + '.');
        }

        return apiRoute;
    }
}

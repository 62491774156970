import { Action } from '@ngrx/store';
import { SetValueAction } from '../../../store/actions/SetValueAction';
import {
    OrganizationModel, EnumValue, QMType,
    FacilityUnit, Payer, Category
} from '../../../shared/models/models-index';
import { Dashboard } from 'src/app/shared/models/dashboard';
import { FilterOptions } from 'src/app/shared/models/filter-options';
import { MedClass } from 'src/app/shared/models/med-class';
import { DiagnosisType } from 'src/app/shared/models/diagnosis-type';
import { VaccineType } from 'src/app/shared/models/vaccine-type';
import { setValue } from '../../../store/services/storeServiceHelper';

export const REPORT_GROUPS = 'REPORT_GROUPS';
export const QM_TYPES = 'QM_TYPES';
export const USER_HS_ORG = 'USER_HS_ORG';
export const USER_HH_ORG = 'USER_HH_ORG';
export const USER_HH_FACILITIES = 'USER_HH_FACILITIES';
export const USER_FACILITIES = 'USER_FACILITIES';
export const REPORT_ENUMS = 'REPORT_ENUMS';
export const USER_ORGANIZATIONS = 'USER_ORGANIZATIONS';
export const ORGANIZATION_TYPES = 'ORGANIZATION_TYPES';
export const UNITS = 'UNITS';
export const PAYERS = 'PAYERS';
export const CATEGORIES = 'CATEGORIES';
export const DASHBOARDS = 'DASHBOARDS';
export const FILTER_OPTIONS = 'FILTER_OPTIONS';
export const MED_CLASSES = 'MED_CLASSES';
export const DIAGNOSIS_TYPES = 'DIAGNOSIS_TYPES';
export const VACCINE_TYPES = 'VACCINE_TYPES';



export const SetReportGroupsAction = (value: EnumValue[]) => new SetValueAction(REPORT_GROUPS, value);
export const SetQmTypesAction = (value: QMType[]) => new SetValueAction(QM_TYPES, value);
export const SetUserHSOrganizationAction = (value: OrganizationModel[]) => new SetValueAction(USER_HS_ORG, value);
export const SetUserHHOrganizationAction = (value: OrganizationModel[]) => new SetValueAction(USER_HH_ORG, value);
export const SetUserHHFacilitiesAction = (value: OrganizationModel[]) => new SetValueAction(USER_HH_FACILITIES, value);
export const SetUserFacilitiesAction = (value: OrganizationModel[]) => new SetValueAction(USER_FACILITIES, value);
export const SetReportEnumsAction = (value: EnumValue[]) => new SetValueAction(REPORT_ENUMS, value);
export const SetUserOrganizationsAction = (value: OrganizationModel[]) => new SetValueAction(USER_ORGANIZATIONS, value);
export const SetOrganizationTypesAction = (value: EnumValue[]) => new SetValueAction(ORGANIZATION_TYPES, value);
export const SetUnits = (value: FacilityUnit[]) => new SetValueAction(UNITS, value);
export const SetPayers = (value: Payer[]) => new SetValueAction(PAYERS, value);
export const SetCategories = (value: Category[]) => new SetValueAction(CATEGORIES, value);
export const SetDashboards = (value: Dashboard[]) => new SetValueAction(DASHBOARDS, value);
export const SetFilterOptions = (value: FilterOptions) => new SetValueAction(FILTER_OPTIONS, value);
export const SetMedClasses = (value: MedClass[]) => new SetValueAction(MED_CLASSES, value);
export const SetDiagnosisTypes = (value: DiagnosisType[]) => new SetValueAction(DIAGNOSIS_TYPES, value);
export const SetVaccineTypes = (value: VaccineType[]) => new SetValueAction(VACCINE_TYPES, value);


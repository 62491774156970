import { Injectable } from '@angular/core';
import { UIRouter } from '@uirouter/angular';
import { HttpClient } from '@angular/common/http';
import { EnvService, FilterStateService } from '../../shared/services/services-index';
import { UserInterfaceStateService } from 'src/app/userInterface/store/services/userInterface-state.service';

@Injectable()
export class UserActivityService {
  constructor(
    private router: UIRouter,
    private http: HttpClient,
    private filterStateService: FilterStateService,
    private envService: EnvService,
    private userInterfaceStateService: UserInterfaceStateService
  ) {
  }

  private readonly excludedRoutes = [
    '/errortest',
    '/requestPasswordReset',
    '/login',
    'logout',
    '/help',
    '/errorTest',
    '/passwordreset',
    '/authcallback'
  ];

  startTracking(): void {
    this.router.transitionService.onSuccess(
      {},
      transition => {
        const url = transition.$to().url.format(transition.params());
        if (!this.isExcludedRoute(url)){
          this.pageTrack(url);
        }
      },
      { priority: -10000 }
    );
  }

  pageTrack(path: string) {
    this.sendLogActivity(this.getClientPortalActivity(path, 'PageView', ''));
  }

  eventTrack(action: string, properties: any) {
    this.sendLogActivity(this.getClientPortalActivity(location.pathname, action, properties));
  }

  getClientPortalActivity(path, action, properties) {
    return {
      ActivityDate: new Date(),
      Route: path === '' ? null : path,
      Action: action,
      ActionReportId: properties === '' ? null : properties.category,
      ActionDetails: properties === '' ? null : properties.label || JSON.stringify(properties),
      OrganizationId: this.filterStateService.getSelectedOrganizationId()
    };
  }

  sendLogActivity(clientPortalActivity) {
    this.http.post(this.envService.api + 'application-info/add-client-portal-activity', clientPortalActivity)
      .subscribe(() => {});
  }

  isExcludedRoute(url: string): boolean {
    return this.excludedRoutes.some(excludedRoute => url.includes(excludedRoute));
  }
}

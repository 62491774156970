<div class="dual-list">
	<div class="listbox">
		<div class="record-picker">
			<ul [ngStyle]="{'max-height': height, 'min-height': height}"
				[ngClass]="{over:available.dragOver, 'cursor-disabled': disabled}" (drop)="drop($event, confirmed)"
				(dragover)="allowDrop($event, available)" (dragleave)="dragLeave()">
				<li *ngFor="let item of available.sift; let idx=index;"
					(click)="selectItem(available.pick, item); shiftClick($event, idx, available, item)"
					[ngClass]="{selected: isItemSelected(available.pick, item), disabled: disabled, 'item-disabled': disabled}"
					[draggable]="!disabled" (dragstart)="drag($event, item, available)" (dragend)="dragEnd(available)"
					(dblclick)="selectItem(available.pick, item); moveItem(available, confirmed)"
					title={{getTooltip(item)}}><label>
						<span>
							<rtms-resident-card-score-indicator [organizationId]="getValue(item, 'OrganizationId')"
								[riskLevel]="getValue(item, 'RiskLevel')" [resMrn]="getValue(item, 'ResMRN')"
								[requiresACP]="getValue(item, 'RequiresACP')" [opioidScore]="getValue(item, 'OpioidScore')"
								[opioidMessage]="getValue(item, 'OpioidMessage')">
							</rtms-resident-card-score-indicator>
						</span>
						{{item._name}}
						<rtms-acp-status [acpStatus] = "getValue(item, 'RequiresACP')"></rtms-acp-status>&nbsp;<rtms-opioid-score [opioidScore] = "getValue(item, 'OpioidScore')" [resident]="getValue(item, 'Resident')" [opioidMessage] = "getValue(item, 'OpioidMessage')"></rtms-opioid-score>
					</label>
				</li>
				<li *ngIf="available.sift.length == 0" class="no-data">
					<label *ngIf="keyword && !sourceLoading">No {{keyword}}s in this list</label>
					<div *ngIf="sourceLoading" class="loader">
						<span>
							<i class="fa fa-circle-notch fa-spin spinner"></i>
						</span>
					</div>
				</li>
			</ul>
		</div>
	</div>

	<div class="buttonbox">
		<button type="button" class="btn btn-info btn-block" (click)="moveItem(available, confirmed)"
			[disabled]="disabled || available.pick.length === 0" title="Select">
			<i class="fa fa-arrow-right"></i>
		</button>
		<button type="button" class="btn btn-info btn-block" (click)="moveAll()"
			[disabled]="disabled || isAllSelected(available)" title="Select All">
			<i class="fa fa-arrow-right"></i>
			<i class="fa fa-arrow-right"></i>
		</button>
		<button type="button" class="btn btn-info btn-block" (click)="moveItem(confirmed, available)"
			[disabled]="disabled || confirmed.pick.length === 0" title="Remove">
			<i class="fa fa-arrow-left"></i>
		</button>
		<button type="button" class="btn btn-info btn-block" (click)="removeAll()"
			[disabled]="disabled || isAllSelected(confirmed)" title="Remove All">
			<i class="fa fa-arrow-left"></i>
			<i class="fa fa-arrow-left"></i>
		</button>
	</div>

	<div class="listbox">
		<div class="record-picker">
			<ul [ngStyle]="{'max-height': height, 'min-height': height}"
				[ngClass]="{over:confirmed.dragOver, 'cursor-disabled': disabled}" (drop)="drop($event, available)"
				(dragover)="allowDrop($event, confirmed)" (dragleave)="dragLeave()">
				<li *ngFor="let item of confirmed.sift; let idx=index;"
					(click)="selectItem(confirmed.pick, item); shiftClick($event, idx, confirmed, item)"
					[ngClass]="{selected: isItemSelected(confirmed.pick, item),  disabled: disabled, 'item-disabled': disabled}"
					[draggable]="!disabled" (dragstart)="drag($event, item, confirmed)" (dragend)="dragEnd(confirmed)"
					(dblclick)="selectItem(confirmed.pick, item); moveItem(confirmed, available)"
					title={{getTooltip(item)}}><label>
						<span>
							<rtms-resident-card-score-indicator [organizationId]="getValue(item, 'OrganizationId')"
								[riskLevel]="getValue(item, 'RiskLevel')" [resMrn]="getValue(item, 'ResMRN')"
								[requiresACP]="getValue(item, 'RequiresACP')" [opioidScore]="getValue(item, 'OpioidScore')"
								[opioidMessage]="getValue(item, 'OpioidMessage')">
							</rtms-resident-card-score-indicator>
						</span>
						{{item._name}}
						<rtms-acp-status [acpStatus] = "getValue(item, 'RequiresACP')"></rtms-acp-status>&nbsp;<rtms-opioid-score [opioidScore] = "getValue(item, 'OpioidScore')" [resident]="getValue(item, 'Resident')" [opioidMessage] = "getValue(item, 'OpioidMessage')"></rtms-opioid-score>
					</label></li>
				<li *ngIf="confirmed.sift.length == 0" class="no-data">
					<label *ngIf="keyword && !destinationLoading">No Tagged {{keyword}}(s) found</label>
					<div *ngIf="destinationLoading" class="loader">
						<span>
							<i class="fa fa-circle-notch fa-spin spinner"></i>
						</span>
					</div>
				</li>
			</ul>
		</div>
	</div>
</div>

<form class="form">
  <div class="form-body">
    <div class="header-content"><img src="../../../../src/assets/images/lock.png" alt="" class="img-responsive img-circle"
        style="margin:0 auto"></div>
    <div class="mb-3 text-center">
      <h1>{{user}}</h1>
    </div>

    <div class="mb-3 text-center">
      <div class="email">{{emailAddress}}</div>
    </div>

    <div class="mb-3 text-center">
      <button type="submit" class="btn btn-info" (click)="closeLockScreen();">Close</button>
    </div>
    <div class="clearfix"></div>
    <br />
  </div>
</form>

<div *ngIf="this._showTopBar$ | async">
  <!--BEGIN BACK TO TOP-->
  <a id="totop" href="#"><i class="fa fa-angle-up"></i></a>
  <!--END BACK TO TOP-->
  <!--BEGIN TOPBAR-->
  <div id="header-topbar-option-demo" class="page-header-topbar">
      <nav id="topbar" role="navigation" style="margin-bottom: 0;" class="navbar navbar-default navbar-static-top">
          <div class="navbar-header d-none d-md-flex align-items-center">
              <span id="logo" (click)="this._navigationService.navigateSelectedOrganizationDashboard()" class="navbar-brand" style="cursor: pointer;">
                <span class="logo-text d-block">
                    <img class="logotype" src="/src/assets/images/logo-rtms.png" alt="Logo">
                </span>
            </span>
        </div>
        <div class="topbar-main">
          <div>
            <rtms-notification *ngIf="_isUserLoggedIn$ | async"></rtms-notification>
            <button type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#sidebar-collapse"
                    class="navbar-toggle menu-toggle d-block d-md-none"
                    *ngIf="_isUserLoggedIn$ | async">
              <span class="sr-only">Toggle navigation</span>
              <i class="fa fa-bars"></i>
            </button>
          </div>
          <div style="display: flex">
            <div class="navbar navbar-top-links"
                 *ngIf="_isUserLoggedIn$ | async;"
                 data-bs-toggle="collapse"
                 data-bs-target=".dropdown-user"
                 aria-expanded="false"
            >
                <rtms-report-queue-status></rtms-report-queue-status>
                <li class="dropdown topbar-user user-parent">
                    <a class="dropdown-toggle">
                      <span class="fa fa-user"></span>
                      &nbsp;
                      <span class="d-none d-md-inline" *ngFor="let loggedInUser of [_loggedInUser$ | async]">
                        {{loggedInUser.FirstName + ' ' + loggedInUser.LastName + ' - ' + loggedInUser.OrganizationNameAbbrev}}
                      </span>
                      &nbsp;
                      <span class="caret"></span>
                    </a>
                    <ul class="dropdown-menu dropdown-user user-child collapse">
                        <li><a class="sidebar-item nav-link" (click)="this._stateService.transitionTo('home.userSettings', { reload: true })"><i class="fa fa-cog"></i>My Settings</a></li>
                        <li *ngIf="(_userAdminEnabled$ | async)"><a class="sidebar-item nav-link" (click)="this._stateService.transitionTo('home.userManagement', { reload: true })"><i class="fa fa-user-plus"></i>User Management</a></li>
                        <li><a class="sidebar-item nav-link" (click)="this._stateService.transitionTo('home.lockScreen', { reload: true })" ><i class="fa fa-lock"></i>Lock Screen</a></li>
                        <li><a class="nav-link" (click)="this._stateService.transitionTo('home.logout', { reload: true })"><i class="fa fa-sign-out"></i>Log Out</a></li>
                    </ul>
                </li>
            </div>
            <div class="help-button d-none d-md-flex">
              <span data-hover="tooltip"
                    title="Help"
                    (click)="openHelp()">
                <i class="fa fa-question-circle"></i>
              </span>
            </div>
          </div>
        </div>
      </nav>
  </div>
  <!--END TOPBAR-->
</div>

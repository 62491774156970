import { Component, OnInit, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { RtmsConstantService } from '../../services/rtms-constant.service';
import { UserStateService } from 'src/app/user/store/services/user-state.service';
import { UserSettingService } from '../../services/portal/userSetting.service';
import { ExportService, ReportService } from '../../services/services-index';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { FilterStateService } from '../../services/filter-state.service';
import { ReportModel } from '../../models/report.model';
import { UtilizationMetricsService } from '../../analytics/utilization-metrics.service';
import { OrganizationModel } from '../../models/models-index';
import { DrillDownStateService } from 'src/app/drill-down/store/services/drill-down-state.service';
import { list } from '../../utility/list';
import { BaseComponent } from '../base.component';
import { ChartDetail } from '../../models/chart-details';
import * as _ from 'lodash';
import { SelectedChartStateService } from '../../services/selected-chart-state.service';
import { DashboardHelperService } from '../../services/portal/dashboardHelper.service';
import { UserInterfaceStateService } from 'src/app/userInterface/store/services/userInterface-state.service';

@Component({
  selector: 'rtms-reports-header',
  templateUrl: './reports-header.component.html',
  styleUrls: ['./reports-header.component.scss']
})
export class ReportsHeaderComponent extends BaseComponent implements OnInit {

  @Input() reportTitle: string;
  @Input() reportsVm: any;
  @Input() showPayers: boolean;
  @Input() showQms: boolean;
  @Input() showCategories: boolean;
  @Input() showTypes: boolean;
  @Input() showUnits: boolean;
  @Input() currentDisplayReport: string;
  @Input() showBackToButton: boolean;
  @Input() backButtonText: string;
  @Input() backButtonShortText: string;
  @Input() backButtonHref: string;
  @Input() showFilter: boolean;
  @Input() hideFacilityName: boolean;
  @Input() allowChangeOrg: boolean;
  @Input() reportId: number;
  @Input() showDischargeOnly: boolean;
  @Input() displayMode: string;
  @Input() show: boolean;
  @Input() showMenu: boolean;
  @Input() dashboardId: number;
  @Input() showMedClasses: boolean;

  public drillDownHistory: ChartDetail[] = [];
  public isResidentDashboard: boolean;
  public isProactHsDashboard: boolean;
  public userSettingConstants = this.rtmsConstantService.settings;
  public backButtonIconClass: string;
  public userMultipleOrganizations: OrganizationModel[];
  public selectedEnterpriseOrg: OrganizationModel;
  public isChangingOrg: false;
  public isEnterprise: boolean;

  constructor(private stateService: StateService,
    private rtmsConstantService: RtmsConstantService,
    private userStateService: UserStateService,
    private userSettingService: UserSettingService,
    private exportService: ExportService,
    private listsStateService: ListsStateService,
    private reportService: ReportService,
    private filtersService: FiltersService,
    private filterStateService: FilterStateService,
    private utilizationMetricsService: UtilizationMetricsService,
    private drillDownStateService: DrillDownStateService,
    private dashboardHelperService: DashboardHelperService,
    private userInterfaceStateService: UserInterfaceStateService) {
    super();
    if (this.stateService.params.isReportDashboard) {
      this.subscriptions.push(this.drillDownStateService.getDrillDownHistoryStream().subscribe(data => {
        this.drillDownHistory = list(data).ToArray();
      }));
    }

    this.subscriptions.push(this.listsStateService.getUseOrganizationsStream()
      .subscribe(organizations => {
        this.initializeOrganizationDdl();
      }));
  }

  ngOnInit() {
    this.isEnterprise = this.dashboardHelperService.isEnterpriseDashboard();
    this.displayMode = this.dashboardHelperService.isHSDashboard() ? 'hs' : this.displayMode;
    this.displayMode = this.dashboardHelperService.isHHDashboard() ? 'hh' : this.displayMode;
    this.displayMode = this.isEnterprise ? 'enterprise' : this.displayMode;
    this.displayMode = this.dashboardHelperService.isHHProactDashboard() ? 'hhfacility' : this.displayMode;
    this.allowChangeOrg = (this.allowChangeOrg === undefined) ? true : this.allowChangeOrg;
    this.isResidentDashboard = this.stateService.current.name === 'home.residentDashboard';
    this.isProactHsDashboard = this.stateService.current.name === 'home.hs';
    this.initializeOrganizationDdl();
    this.selectedEnterpriseOrg = this.filterStateService.getEnterpriseFromFilterOrDefault();

    // If filtersettings is true, trigger "Filter" button click to open filter by default
    if (!this.reportsVm.showMenu && this.showFilter &&
      this.userStateService.getSettings()[this.userSettingConstants.FilterOpened].value === true) {
      // To avoid Error: ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked.
      setTimeout(() => {
        this.reportsVm.showFilter(true);
      });
    }

    this.backButtonIconClass = this.backButtonText === 'Back to Dashboard' ? 'fa-pie-chart' : 'fa-files-o';
  }

  initializeOrganizationDdl() {
    this.userMultipleOrganizations = this.listsStateService.getOrganizationsForDropDown();
  }  

  goBack(location: string): void {
    if (location === this.stateService.current.name) {
      this.stateService.transitionTo('home.reportsLanding');
    } else {
      if (location === 'home.financial') {
        if (this.userStateService.getLoggedInUser().IsCorporateUser) {
          this.stateService.transitionTo('corporate');
        } else {
          this.stateService.transitionTo('home.financial');
        }
      } else if (location === 'home.clinical') {
        this.stateService.transitionTo('home.clinical');
      } else if (location.indexOf('genericDashboard') >= 0) {
        const db = location.split('/');
        this.userInterfaceStateService.setCurrentMainDashboardId(+db[1]);
        if (db && db[1] === '7') {
          this.stateService.transitionTo('home.hospitalDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1') {
          this.stateService.transitionTo('home.financial', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '2') {
          this.stateService.transitionTo('home.clinical', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '3') {
          this.stateService.transitionTo('home.rehospitalization', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '4') {
          this.stateService.transitionTo('home.hs', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '9') {
          this.stateService.transitionTo('home.pdpm', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '600') {
          this.stateService.transitionTo('home.enterpriseProactDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '700') {
          this.stateService.transitionTo('home.enterpriseFinancialDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '900') {
          this.stateService.transitionTo('home.enterpriseClinicalDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1000') {
          this.stateService.transitionTo('home.enterprisePDPMDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1100') {
          this.stateService.transitionTo('home.infectionControl', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1200') {
          this.stateService.transitionTo('home.enterpriseDiseaseWatchDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '2012') {
          this.stateService.transitionTo('home.enterpriseInfectionControlDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1400') {
          this.stateService.transitionTo('home.homeHealthProactHSDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '1404') {
          this.stateService.transitionTo('home.homeHealthProactDashboard', { 'dashboardId': Number(db[1]) });
        }  else if (db && db[1] === '2023') {
          this.stateService.transitionTo('home.medicalDirectorDashboard', { 'dashboardId': Number(db[1]) });
        } else if (db && db[1] === '2024') {
          this.stateService.transitionTo('home.enterpriseMedicalDirectorDashboard', { 'dashboardId': Number(db[1]) });
        } else {
          this.stateService.transitionTo('home.genericDashboard', { 'dashboardId': Number(db[1]) });
        }
      } else {
        this.stateService.transitionTo(location);
      }
    }
  }

  hasValue(val): boolean {
    return val !== null && val !== '' && val !== undefined;
  }

  showFilterClicked(value: boolean): void {
    this.reportsVm.showFilter(value);
    const filterSetting = this.userStateService.getSettings()[this.userSettingConstants.FilterOpened].value;
    // Set filter to false, only if value=true, which means, allow hiding filter
    const isFilterShown = !filterSetting || !value;

    this.setFilterSetting(isFilterShown);
  }

  setFilterSetting(filter: boolean): void {
    this.userSettingService.setUserSetting(this.userSettingConstants.FilterOpened, filter);
  }

  exportAll(): void {
    this.reportId = this.listsStateService.getReportEnumByName('ResidentDashboardExport').Id;
    
    this.reportService.getReportById(this.reportId).then((response) => {
      this.export(response);
    });
  }

  export(report: ReportModel): void {
    const type = 'pdf';
    const filter = this.filterStateService.getFilter();
    const cardFilter = _.flatMap(this.filtersService.cardFilters.get(), function (filter) {
      return filter.cardFilter;
    });
    filter.SelectedCardFilters = cardFilter;
    this.utilizationMetricsService.recordExports(report.ReportId, report.ReportName, type, filter);
    const org = this.filtersService.organizations.getFirstOrDefault();

    this.exportService.downloadReport(report, type, null, filter, org);
  }

  getSelectedOrgName(): String {
    let displayedOrgName = '';
    if (this.dashboardHelperService.isHSDashboard()) {
      displayedOrgName = this.filterStateService.getHSFromFilterOrDefault()?.OrganizationName;
    } else if (this.dashboardHelperService.isHHProactDashboard()) {
      displayedOrgName = this.filterStateService.getHHFacilityFromFilterOrDefault()?.OrganizationName;
    } else if (this.dashboardHelperService.isHHDashboard()) {
      displayedOrgName = this.filterStateService.getHHFromFilterOrDefault()?.OrganizationName;
    } else if (this.isEnterprise) {
      displayedOrgName = this.filterStateService.getEnterpriseFromFilterOrDefault()?.OrganizationName;
    } else {
      displayedOrgName = this.filterStateService.getSelectedOrganization()?.OrganizationName;
    }

    return displayedOrgName;
  }

  public changeSelectedEnterpriseOrg(): void {
    this.filtersService.selectedEnterpriseOrganization.set(this.selectedEnterpriseOrg);
  }
}

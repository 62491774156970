<div id="title-breadcrumb-option-demo" class="page-title-breadcrumb">
  <div class="page-header">
    <div class="d-flex justify-content-between">
      <div class="page-title d-flex">
        <div class="page-title-text rp_title" [ngClass]="!reportsVm.showMenu && showBackToButton ? 'limited' : ''">
          <div *ngIf="!isResidentDashboard"
               class="no-wrap"
               [ngClass]="isProactHsDashboard || isEnterprise ? 'd-none d-lg-inline-block' : ''"
          >
            {{reportTitle}}
          </div>
          <div *ngIf="isResidentDashboard" class="d-none d-lg-inline-block"><i class="fa fa-laptop-medical"></i></div>
        </div>

        <div class="page-title-group">
          <div class="page-title-group-non-filter">
          <span [hidden]="reportsVm.selectedOrganization == '' || hideFacilityName" [ngClass]="isProactHsDashboard || isEnterprise ? 'facility-view' : 'facility-view d-none d-md-flex align-items-center'">
            <img [hidden]="isResidentDashboard || isProactHsDashboard" class="header-facilityicon header-facilityicon-margin" src="src/assets/images/facility.png">
            <span class="d-none d-md-inline-block" title="{{getSelectedOrgName()}}"
                  [hidden]="!(!allowChangeOrg || userMultipleOrganizations.length == 1)">
                {{ getSelectedOrgName() | slice:0:50 }}
              {{ getSelectedOrgName().length > 50 ? '...' : ''}}
            </span>
            <span [hidden]="!(allowChangeOrg && userMultipleOrganizations.length > 1)" class="multiselect-wrapper change-facility ngSelect">
              <span>
                <rtms-facility-selector [displayMode]="displayMode"></rtms-facility-selector>
              </span>
            </span>
          </span>

            <span [hidden]="!(!reportsVm.showMenu && showFilter)" class="toggle-filter">
            <button class="btn btn-info btn-sm btn-add-filter dashboard-filter-margin filter-button-margin btn-filtr btn-small btn-filter-only" data-hover="Show/Hide Filter"
                    title="Filter" (click)="showFilterClicked(true)">
              <i class="fa fa-filter"></i><span class="d-none d-lg-inline-block">&nbsp;Filter</span><!--JSR-FILTER-->
            </button>
          </span>

            <span class="dashboard-filter-margin d-flex align-items-center" *ngIf="isResidentDashboard">
            <rtms-resident-pager [showRoomBedSort]="false"></rtms-resident-pager>
            <button class="btn btn-info btn-sm btn-add-filter dashboard-filter-margin btn-export btn-small" data-hover="Export Data"
                    title="Export" (click)="exportAll()">
              <i class="fa fa-download"></i> <span class="d-none d-lg-inline-block">&nbsp;Export</span>
            </button>
          </span>
            <span [hidden]="!hideFacilityName" class="blank_space">
            &nbsp;
              <!-- whitespace for Title to remain aligned -->
          </span>

            <div class="facility-filters">
              <span [hidden]="!(!reportsVm.showMenu && showUnits && hasValue(reportsVm.filterUnits))" class="facility-view">
                <label class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small"
                       title="{{reportsVm.filterUnits}}">
                  <i class="fa fa-filter" title="Units Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterUnits | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove Units" (click)="reportsVm.removeUnitsFilter()"></i>
                </label>
              </span>

              <span [hidden]="!(!reportsVm.showMenu && showQms && reportsVm.filterQMTypes)" class="facility-view">
                <label
                  class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small" title="{{reportsVm.filterQMTypes}}">
                  <i class="fa fa-filter" title="QM Types Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterQMTypes | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove QM Types" (click)="reportsVm.removeQMTypesFilter()"></i>
                </label>
              </span>
              <span [hidden]="!(!reportsVm.showMenu && showPayers && hasValue(reportsVm.filterPayers))" class="facility-view">
                <label class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small"
                       title="{{reportsVm.filterPayers}}">
                  <i class="fa fa-filter" title="Payers Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterPayers | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove Payers" (click)="reportsVm.removePayersFilter()"></i>
                </label>
              </span>
              <span [hidden]="!(!reportsVm.showMenu && showCategories && reportsVm.filterCategories)" class="facility-view">
                <label
                  class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small" title="{{reportsVm.filterCategories}}">
                  <i class="fa fa-filter" title="Categories Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterCategories | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove Categories" (click)="reportsVm.removeCategoriesFilter()"></i>
                </label>
              </span>
              <span [hidden]="!(!reportsVm.showMenu && showTypes && reportsVm.filterType)" class="facility-view">
                <label
                  class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small" title="{{reportsVm.filterType}}">
                  <i class="fa fa-filter" title="Show Type Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterType | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove Types" (click)="reportsVm.removeTypeFilter()"></i>
                </label>
              </span>
              <span [hidden]="!(!reportsVm.showMenu && showDischargeOnly && reportsVm.dischargeOnlyFilter)" class="facility-view">
                <label
                  class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small" title="Show Only Discharged Residents">
                  <i class="fa fa-filter" title="Show Discharge Only Filter"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;Discharged Only&nbsp;<i class="fa fa-times"
                                                                                             title="Remove Discharge Only" (click)="reportsVm.removeDischargedOnlyFilter()"></i>
                </label>
              </span>
              <span [hidden]="!(!reportsVm.showMenu && showMedClasses && hasValue(reportsVm.filterMedClasses))" class="facility-view">
                <label class="btn btn-outline-secondary dashboard-breadcrumb-margins btn-small"
                       title="{{reportsVm.filterMedClasses}}">
                  <i class="fa fa-filter" title="Medication Types"
                     (click)="reportsVm.showFilter(false)"></i>&nbsp;{{reportsVm.filterMedClasses | slice:0:10}}&nbsp;&nbsp;&nbsp;<i
                  class="fa fa-times" title="Remove Medication Types" (click)="reportsVm.removeMedClassesFilter()"></i>
                </label>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center">
        <span [hidden]="!(!reportsVm.showMenu && showBackToButton)" class="facility-view">
          <button type="button" class="btn btn-default btn-small no-wrap" style="color: black" (click)="goBack(backButtonHref)">
            <i class="fa fa-fw" [ngClass]="backButtonIconClass"></i>
            <span class="d-none d-sm-inline">&nbsp;{{backButtonText}}</span>
          </button>
        </span>
        <span [hidden]="!(!reportsVm.showMenu && drillDownHistory && drillDownHistory.length > 1)"
              class="facility-view"
              *ngFor="let r of drillDownHistory | slice:0:drillDownHistory.length-1;let idx = index">
          <button
              [hidden]="r.chartName === '' || r.chartName === currentDisplayReport"
              class="btn btn-outline-secondary btn-small no-wrap"
              (click)="reportsVm.retrieveParent($event,r,idx)" title="{{r.chartName}}">
            <span>{{ r.chartName | slice:0:10 }} {{r.chartName.length > 10 ? '...' : ''}}</span>
          </button>
        </span>
      </div>
    </div>
  </div>
  <rtms-tour [dashboardId]="dashboardId" [showIntro]="true"></rtms-tour>
</div>

import { Component, ViewChild, TemplateRef, Output, EventEmitter } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganizationModel, UserModel } from 'src/app/shared/models/models-index';
import { UserOrganizationService } from 'src/app/shared/services/user/user-organization/user-organization.service';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { finalize, map, throwIfEmpty } from 'rxjs/operators';
import { LoginService } from 'src/app/pages/login/login.service';
import { StateService } from '@uirouter/core';
import { ListsStateService } from 'src/app/lists/store/services/lists-state.service';
import { UINotifierService } from 'src/app/shared/services/services-index';
import { FiltersService } from 'src/app/filter/store/services/filters.service';
import { UIEventTypes } from 'src/app/shared/enums/ui-event-types';
import { PortalUIEvent } from 'src/app/shared/models/portal-ui-event';
import { NavigationService } from 'src/app/shared/services/portal/navigation.service';


@Component({
    selector: 'user-organization-edit',
    templateUrl: 'user-organization-edit.component.html'
})
export class UserOrganizationEditComponent {

    closeResult = '';

    @ViewChild('content')
    private content: TemplateRef<any>;

    private user: UserModel;
    private userId: number;
    private organizations: OrganizationModel[];

    form: UntypedFormGroup;
    formSubmitted: boolean = false;
    showLoader: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private modalService: NgbModal,
        private userOrgSvc: UserOrganizationService,
        private _loginService: LoginService,
        private stateService: StateService,
        private listsStateService: ListsStateService,
        private _uiNotifierService: UINotifierService,
        private filterService: FiltersService,
        private navigationService: NavigationService
    ) {
        this.form = this.fb.group({
            'organizationId': ['', Validators.required]
        });
    }

    open(user: UserModel) {
        this.user = user;
        this.userOrgSvc.getAllByUserId(user.userId)
            .subscribe((response) => {
                this.organizations = response;
                this.form.get('organizationId').setValue(user.OrganizationId);
                this.modalService.open(this.content, {
                    ariaLabelledBy: 'modal-basic-title',
                    backdrop: 'static'
                });
            })
    }

    save() {
        if (!this.form.valid) {
            this.formSubmitted = true;
            return;
        }

        if (this.user.OrganizationId === this.form.value.organizationId) {
            this.close();
            return;
        }

        this.showLoader = true;
        this.userOrgSvc.saveUserOrganization(this.user.userId, this.form.value.organizationId)
            .subscribe({
                next: (res) => this.handleUserOrgEditFinished(this.form.value.organizationId),
                error: (error) => this.showLoader = false
            });
    }

    handleUserOrgEditFinished(organizationId: number) {
        // need to get the user's info again, since their organization has changed
        this._loginService.getUserInformation()
            .pipe(
                finalize(() => {
                    this.showLoader = false;
                    this.close();
                })
            )
            .subscribe((userInfo: any) => {
                this._loginService.setUserSession(userInfo).subscribe(() => {
                    this._loginService.clearOrganizationSelection();
                    const orgs = this.listsStateService.getOrganizationsForDropDown();
                    if (orgs && orgs.length) {
                      this.filterService.organizations.set([orgs[0]]).subscribe((orgs) => {});
                      const uiEvents = {
                        type: UIEventTypes.ChangeFacility,
                        value: orgs[0],
                      } as PortalUIEvent;
                      this._uiNotifierService.publishEvents(uiEvents);
                    } else {
                        const orgs = this.listsStateService.getUserFacilities();
                        this.filterService.organizations.set([orgs[0]]).subscribe((orgs) => {
                            this.navigationService.navigateSelectedOrganizationDashboard();
                        });
                    }
                });
            });
      }

    close() {
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
    }
}

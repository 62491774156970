<div id="wrapper">
    <div id="page-wrapper" class="page-wrapper no-breadcrumb">
        <div class="page-content portalusage-noPadding">
            <div class="row">
                <div class="col-md-12 reportSmallFont">
                    <div class="panel user-panel autoHeight" style="overflow: auto;">
                        <div class="row">
                            <div class="col-sm-12 col-md-8 toggle_btn mbm">
                                <label class="bold mrm mtm align-top">Resident Tagging</label>
                                <mat-slide-toggle [(ngModel)]="isTaggingResidentEnabled" [color]="'blue'"
                                    (change)="toggleResidentTagging()">
                                </mat-slide-toggle>
                            </div>
                        </div>
                        <div class="row" [hidden]="!isTaggingResidentEnabled">
                            <div class="col-md-12">
                                <tabset #residentTaggingTabs>
                                    <tab (selectTab)="selectTab(TagResidentsTabIndex)">
                                        <ng-template tabHeading>
                                            <i class="fas fa-tag" aria-hidden="true"></i> Tag Residents
                                        </ng-template>
                                        <div class="row full-width" *ngIf="selectedTabIndex === TagResidentsTabIndex">
                                            <div class="col-md-12 prn">
                                                <div class="row">
                                                    <div class="search-box">
                                                        <div class="form-control">
                                                            <input
                                                                class="col-lg-11 col-md-11 col-sm-10 col-xs-8 search-tag-resident pln"
                                                                type="text" [(ngModel)]="residentSearchInput"
                                                                (ngModelChange)="filterResident(residentSearchInput)"
                                                                placeholder="Search Residents (Name, MRN, Facility, Diagnosis, ICD or Physician)" />
                                                            <span [hidden]="!isSearchResidentLoading" class="pull-right">
                                                                <i class="fa fa-circle-notch fa-spin spinner"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div class="row">
                                                    <div class="col-md-12 prn">
                                                        <rtms-dual-list [source]="source"
                                                            [(destination)]="selectedResidents" height="calc(100vh - 400px)"
                                                            (destinationChange)="destinationChange()"
                                                            [key]="'Key'" [display]="'Resident'" [filter]="true"
                                                            [sort]="true" [keyword]="'Resident'"
                                                            [sourceLoading]="isSearchResidentLoading" [destinationLoading] = "isTaggedResidentLoading"></rtms-dual-list>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                    <tab (selectTab)="selectTab(OverseeUserResidentsTabIndex)">
                                        <ng-template tabHeading>
                                            <i class="fas fa-tasks" aria-hidden="true"></i> Oversee User
                                            Residents
                                        </ng-template>
                                        <div class="row full-width" *ngIf="selectedTabIndex === OverseeUserResidentsTabIndex">
                                            <div class="col-md-12 prn">
                                                <div class="row">
                                                    <div class="col-md-12 prn">
                                                        <rtms-dual-list [source]="users" [(destination)]="selectedUsers"
                                                            height="calc(100vh - 400px)" [key]="'UserId'" [display]="'FullUserName'"
                                                            [filter]="true" [sort]="true" [keyword]="'User'"
                                                            [sourceLoading]="isUserLoading" [destinationLoading]="isTaggedUserLoading">
                                                        </rtms-dual-list>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                    <tab *ngIf="residentAdmittedLast72HoursEnabled"
                                        (selectTab)="selectTab(ResidentsAdmittedLast72HoursTabIndex)">
                                        <ng-template tabHeading>
                                            <i class="fas fa-procedures" aria-hidden="true"></i> Residents Admitted Last 72 Hours
                                        </ng-template>
                                        <div class="row full-width" *ngIf="selectedTabIndex === ResidentsAdmittedLast72HoursTabIndex" [ngClass]="{'loader-parent' : cellClickComponent.updateTagResidentLoader}">
                                            <div class="col-md-12 fixed-height-report fh-double-tables fh-short-title">
                                                <div class="panel full-width">
                                                    <div class="text-center" *ngIf="isAdmittedResidentLoading || cellClickComponent.updateTagResidentLoader" [ngClass]="{'loader-child' : cellClickComponent.updateTagResidentLoader}">
                                                        <i class="fa fa-circle-notch fa-spin resident-spinner" [ngClass]="{'loader-color' : cellClickComponent.updateTagResidentLoader}"></i>
                                                    </div>
                                                    <div class="col-md-12 panel-content pln prn" *ngIf="!isAdmittedResidentLoading && isAdmittedResidentsAvailable">
                                                        <rtms-grid 
                                                            class="search-input report-height"
                                                            [options]="admittedResidents.Item"
                                                            [rowData]="admittedResidents.Item.data"
                                                            [reportId]="admittedResidents.Item.ReportId"
                                                            (gridCellDrillDown)="cellClickHandler($event)"
                                                            [hideFooter]="true"></rtms-grid>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </tab>
                                </tabset>
                            </div>
                        </div>
                        <div class="row" [hidden]="!isTaggingResidentEnabled || selectedTabIndex === ResidentsAdmittedLast72HoursTabIndex">
                            <div class="col-md-12">
                                <button class="btn btn-info pull-right" (click)="saveResidents()">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <rtms-feedback></rtms-feedback>
    </div>
</div>
<confirm (onConfirmAcknowledge)="navigate($event)" [title]="undefined" [body]="''" #confirmResident>
</confirm>
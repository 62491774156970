import { Component, OnInit } from '@angular/core';
import { QueuedReportModel } from '../../models/queued-report.model';
import { ExportService } from '../../services/services-index';
import { EnvService } from '../../environment/env.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { forEach, find, remove } from 'lodash';
import { Observable, Subscription, timer } from 'rxjs';
import { UserStateService } from 'src/app/user/store/services/user-state.service';

@Component({
  selector: 'rtms-report-queue-status',
  templateUrl: './report-queue-status.component.html',
  styleUrls: ['./report-queue-status.component.scss']
})
export class ReportQueueStatusComponent implements OnInit {

  constructor(
    private _exportService: ExportService,
    private _http: HttpClient,
    private _envService: EnvService,
    private _toastrService: ToastrService,
    private _userStateService: UserStateService
  ) { }

  statusTypes = {
    Queued: 'Queued',
    Processing: 'Processing',
    Error: 'Error',
    Ready: 'Ready'
  }

  pollIntervalMS: number = 5000;
  queuedReports: QueuedReportModel[] = [];
  getQueuedReportsUrl: string;
  queuedReportsSubscription: Subscription = Subscription.EMPTY;

  ngOnInit(): void {
      this.getQueuedReportsUrl = this._envService.api + "reports/get-queued-reports";
      this.getQueuedReports();

      this.queuedReportsSubscription = this._exportService.queuedReport$.subscribe((queuedReport: QueuedReportModel) => {
        this.queuedReports.push(queuedReport);
        this.beginPollTimer();
      })
  }

  ngOnDestroy(): void {
    this.queuedReportsSubscription.unsubscribe();
  }

  getQueuedReports(): void {
    if (!this._userStateService.getLoggedInUser()) return;
    this._http.get<QueuedReportModel[]>(this.getQueuedReportsUrl).subscribe(responseData => {
      this.downloadReadyExports(responseData);
      this.fillQueuedReports(responseData);

      if (this.queuedReports.length) {
        this.beginPollTimer();
      }
    });
  }

  beginPollTimer(): void {
    timer(this.pollIntervalMS).subscribe(() => { this.getQueuedReports(); });
  }

  downloadReadyExports(responseData: QueuedReportModel[]): void {
    forEach(this.queuedReports, (queuedReport) => {
      const foundInQueue = find(responseData, { 'ReportQueueId': queuedReport.ReportQueueId });
      
      if (foundInQueue && foundInQueue.Status === this.statusTypes.Error) {
        queuedReport.Status = foundInQueue.Status;
        return;
      }
      
      const isReady = !foundInQueue && queuedReport.Status !== this.statusTypes.Error;
      queuedReport.Status = isReady ? this.statusTypes.Ready : queuedReport.Status;

      if (isReady) {
        this._toastrService.success("Your " + queuedReport.ReportName + " export has been generated and is being downloaded.", 'Export Ready!');
        const downloadUrl = this._envService.api + 'reports/getqueuedreportdownload/' + queuedReport.ReportQueueId;
        this.downloadReport(downloadUrl).subscribe(r => {
          this._exportService.saveFile(r.body, queuedReport.ReportName, r.body.type);
          remove(this.queuedReports, (n) => { return n.ReportQueueId === queuedReport.ReportQueueId });
        });
      }
    });
  }

  fillQueuedReports(responseData: QueuedReportModel[]): void {
    forEach(responseData, (r) => {
      const queuedReport = find(this.queuedReports, { 'ReportQueueId': r.ReportQueueId });
      if (!queuedReport) {
        this.queuedReports.push(r);
      }
    });
  }

  downloadReport(downloadUrl): Observable<HttpResponse<Blob>> {
    return this._http.get(downloadUrl, {observe: 'response', responseType: 'blob'}).pipe();
  }

  getStatusClass(status: string): string {
    return (status  === this.statusTypes.Processing || status === this.statusTypes.Ready)
      ? 'label-success' 
      : (status === this.statusTypes.Error)
        ? 'label-danger' 
        : 'label-warning';
  }
}

<div class="residentscore">
  <a class="cursor" [hidden]="this._hideIndicator || this._showSpinner" title="Click to show CARD score detail"
    (click)="this.showDetail(p1)" [ngbPopover]="scorePopOver" id="ngbPopover" #p1="ngbPopover" container="body"
    placement="right" triggers="outsideClick" popoverClass="residentdot popover-layout">
    <i class="fa fa-circle residentIndicator" id="indicator" [ngStyle]="{'color': this._indicatorColor}"></i>
</a>
<span [hidden]="!this._showSpinner"
      title="Retrieving score details..."
      [ngStyle]="{'color': this._indicatorColor}">
    <i class="fa fa-refresh fa-spin"></i>
</span>

<rtms-resident-card-score-detail #residentCardScoreDetailComponent [opioidMessage]="opioidMessage"></rtms-resident-card-score-detail>

<ng-template #scorePopOver>
    <div [hidden]="_residentScoreDetail == null">
        <div class="popover-title">
            <div>
                <span *ngIf="!_showLinkToResidentDashboard">{{_residentScoreDetail.Resident}}<span *ngIf="this.requiresACP==true"
                        class="mrs" style="margin-left: 3px;"><rtms-acp-status
                            [acpStatus]="this.requiresACP==true"></rtms-acp-status></span></span>
                <span *ngIf="_showLinkToResidentDashboard" id="residentCardScoreIndicatorViewResidentDashboardLink"
                    class="cursor mrs detail-link" (click)="viewResidentDashboard()" title="Click to view Resident Dashboard"><i
                        class="fas fa-laptop-medical"></i>&nbsp;{{_residentScoreDetail.Resident}}</span><span
                    *ngIf="this.requiresACP==true" class="mrn" style="margin-left:3px;"><rtms-acp-status
                        [acpStatus]="this.requiresACP==true"></rtms-acp-status></span>
                <span *ngIf="setOpioidVisibility(this.opioidScore)" class="mrs" style="margin-left:3px;"><rtms-opioid-score [opioidScore]="this.opioidScore"
                            [isClickable]=false></rtms-opioid-score> </span>
            </div>
            <button class="mrs card-detail detail-link btn btn-small btn-default" (click)="viewDetails(p1)"
                title="Click to view CARD details"><i class="fas fa-laptop-medical"></i>&nbsp;CARD Details</button>
            <button id="ngbPopoverClose" class="btn btn-close" (click)="closePopover(p1)"></button>
        </div>
        <div>
            <div class="body">
                <div class="body-score" [ngStyle]="{'color':_indicatorColor}">
                  <span class="score resident-score-value">
                    {{_residentScoreDetail.TotalScore}}
                  </span>
                  <span>CARD score</span>
                </div>
                <div class="body-data no-wrap">
                  <div>Clinical Alerts:</div>
                  <div>{{_residentScoreDetail.AlertScore}}</div>
                  <div>Admission Recency:</div>
                  <div>{{_residentScoreDetail.LengthOfStayScore}}</div>
                  <div>Readmitted:</div>
                  <div>{{_residentScoreDetail.AcuityScore}}</div>
                  <div>Diagnoses:</div>
                  <div>{{_residentScoreDetail.DiagnosisScore}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #residentDashboard>
    <rtms-generic-dashboard DashboardName="resident-dashboard" category="resident-dashboard"></rtms-generic-dashboard>
</ng-template>
</div>

import { ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { createSetValueReducer } from '../../../store/services/createSetValueReducer';
import { ListsState } from '../../store/states/lists-state';
// eslint-disable-next-line max-len
import { REPORT_GROUPS, QM_TYPES, USER_HS_ORG, USER_HH_ORG, USER_HH_FACILITIES, USER_FACILITIES, REPORT_ENUMS,
  USER_ORGANIZATIONS, ORGANIZATION_TYPES, UNITS, PAYERS, CATEGORIES, DASHBOARDS,
  FILTER_OPTIONS,
  MED_CLASSES, DIAGNOSIS_TYPES, VACCINE_TYPES} from '../../store/actions';
import {
  OrganizationModel, QMType, EnumValue,
  FacilityUnit, Payer, Category
} from '../../../shared/models/models-index';
import { Dashboard } from 'src/app/shared/models/dashboard';
import { FilterOptions } from 'src/app/shared/models/filter-options';
import { MedClass } from 'src/app/shared/models/med-class';
import { DiagnosisType } from 'src/app/shared/models/diagnosis-type';
import { VaccineType } from 'src/app/shared/models/vaccine-type';


export const stateKey = 'lists';

export const reducers: ActionReducerMap<ListsState> = {
  reportGroups: createSetValueReducer<EnumValue[]>([], REPORT_GROUPS),
  qms: createSetValueReducer<QMType[]>([], QM_TYPES),
  userHSOrgs: createSetValueReducer<OrganizationModel[]>([], USER_HS_ORG),
  userHHOrgs: createSetValueReducer<OrganizationModel[]>([], USER_HH_ORG),
  userHHFacilities: createSetValueReducer<OrganizationModel[]>([], USER_HH_FACILITIES),
  userFacilities: createSetValueReducer<OrganizationModel[]>([], USER_FACILITIES),
  reportEnums: createSetValueReducer<EnumValue[]>([], REPORT_ENUMS),
  userOrganizations: createSetValueReducer<OrganizationModel[]>([], USER_ORGANIZATIONS),
  organizationTypes: createSetValueReducer<EnumValue[]>([], ORGANIZATION_TYPES),
  units: createSetValueReducer<FacilityUnit[]>([], UNITS),
  payers: createSetValueReducer<Payer[]>([], PAYERS),
  categories: createSetValueReducer<Category[]>([], CATEGORIES),
  dashboards: createSetValueReducer<Dashboard[]>([], DASHBOARDS),
  filterOptions: createSetValueReducer<FilterOptions>(null, FILTER_OPTIONS),
  medClasses: createSetValueReducer<MedClass[]>([], MED_CLASSES),
  diagnosisTypes: createSetValueReducer<DiagnosisType[]>([], DIAGNOSIS_TYPES),
  vaccineTypes: createSetValueReducer<VaccineType[]>([], VACCINE_TYPES)
};

export const reducerToken = new InjectionToken<ActionReducerMap<ListsState>>('RegisteredReducers');

export const reducerProvider = [
  {
    provide: reducerToken, useValue: reducers
  }
];

import { Component } from '@angular/core';

@Component({
  selector: 'rtms-no-access-feature',
  templateUrl: './no-access-feature.component.html'
})

export class NoAccessFeatureComponent {

  constructor() {
  }

}

import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'rtms-no-data-found',
  template: `
    <div class="text-center" style="font-size: 13px; font-weight: bold">
      {{ message }}
    </div>
  `
})
export class NoDataFoundComponent implements OnChanges {

  @Input() noDataMessage: string;
  message = '';

  ngOnChanges(changes: SimpleChanges) {
    if (changes['noDataMessage']) {
      this.message = this.noDataMessage;
      if (!this.noDataMessage) {
        this.message = 'No data found.';
      }
    }
  }
}

import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

export enum OpioidScore {
  HighRisk = 0,
  ModerateRisk = 1,
  LowRisk = 2,
  NotApplicable = -1
}

@Component({
  selector: 'rtms-opioid-score',
  templateUrl: './opioid-score.component.html',
  styleUrls: ['./opioid-score.component.scss']
})
export class OpioidScoreComponent implements OnInit {

  @Input() opioidScore: OpioidScore;
  @Input() resident: string;
  @Input() opioidMessage: string;
  @Input() isClickable: boolean = true;
  @ViewChild('scorePopOver') public scorePopOver: NgbPopover;
  
  private popoverTitle: string;
  popoverClass: string = 'residentdot popover-layout ';

  OpioidScoreEnum = OpioidScore;
  
  ngOnInit(): void {
    if (this.opioidScore == OpioidScore.HighRisk) {
      this.popoverTitle = "High Risk";
      this.popoverClass += "opioid-bg-danger";
    }
    else if (this.opioidScore == OpioidScore.ModerateRisk) {
      this.popoverTitle = "Moderate Risk";
      this.popoverClass += "opioid-bg-warning";
    }
    else if (this.opioidScore == OpioidScore.LowRisk) {
      this.popoverTitle = "Low Risk";
      this.popoverClass += "opioid-bg-success";
    }

  }

  public showDetail(opioidPopover) {
    if(this.isClickable)
    {
      opioidPopover.open();
    }
  }
  
  protected closePopover(opioidPopover) {
    if (opioidPopover.isOpen()) {
      opioidPopover.close();
    }
  }
}